import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ResponseStream, SystemManagerClient } from '../../../grpc/systemManager_pb_service';
import {
  SystemManagerRequest,
  SystemManagerResponse,
  RefreshServicesRequest,
  RefreshServicesResponse,
  ServiceActionRequest,
} from '../../../grpc/systemManager_pb';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { LazyLoadEvent, MenuItem, MessageService } from 'primeng-lts/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiagnosticMessageDetailComponent } from '../../diagnostic-message/diagnostic-message-detail/diagnostic-message-detail.component';
import { LoggerService } from '../../../services/logger.service';
import { LogSourceType, LogSourceTypeMap } from '../../../grpc/logger_pb';
import { grpc } from '@improbable-eng/grpc-web';
import { environment, AuthService, HttpClientService, Service } from '../../../../../projects/shared/src/public-api';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.css']
})

export class ServiceListComponent implements OnInit, OnDestroy {
  @Output() openSidebarEvent = new EventEmitter<object>();

  private smartComGrpcClient: SystemManagerClient;
  private unsubscribeRefresh: ResponseStream<RefreshServicesResponse>;

  items: MenuItem[];

  services: Service[];
  selectedServices: any[];
  selectedService: Service;
  serviceManagerIsRunning: boolean = false;

  totalRecords: number = 0;
  loading: boolean = false;
  expandedRows: any[] = [];
  changeServiceEnableLoading: boolean;
  grpcMetadata: grpc.Metadata;
  canRetry: boolean;

  constructor(private httpClientService: HttpClientService, private messageService: MessageService, private changeDetectorRef: ChangeDetectorRef, private modalService: NgbModal, private loggerService: LoggerService, private authService: AuthService) {
    this.smartComGrpcClient = new SystemManagerClient(environment.config.api_hostname);

    this.grpcMetadata = new grpc.Metadata();
  }

  async ngOnInit() {
    this.loading = true;
    this.canRetry = true;

    this.items = [
      { label: 'Ver log', icon: 'pi pi-fw pi pi-list', styleClass: '', command: () => this.showLog(this.selectedService, LogSourceType.SOURCETYPESERVICE) },
      { label: 'Copiar', icon: 'pi pi-fw pi pi-copy', styleClass: '', command: () => this.copyService(this.selectedService) },
      { label: 'Editar', icon: 'pi pi-fw pi pi-pencil', styleClass: '', command: () => this.editService(this.selectedService) },
      { label: 'Excluir', icon: 'pi pi-fw pi-trash', styleClass: '', command: () => this.deleteService(this.selectedService) }
    ];
  }

  ngOnDestroy(): void {
    this.canRetry = false;

    if (this.unsubscribeRefresh) {
      this.unsubscribeRefresh.cancel();
    }
  }

  //#region Members 'GRPC' :: Start/Stop/etc

  public start() {

    this.grpcMetadata.set('Authorization', this.authService.getAuthorizationHeader());

    let systemManagerRequest = new SystemManagerRequest();
    var self = this;
    this.smartComGrpcClient.start(systemManagerRequest, this.grpcMetadata, function (error, responseMessage) {
      self.serviceManagerIsRunning = responseMessage != null;
    });
  }

  public stop() {

    this.grpcMetadata.set('Authorization', this.authService.getAuthorizationHeader());

    let serviceManagerStopRequest = new SystemManagerRequest();
    var self = this;
    this.smartComGrpcClient.stop(serviceManagerStopRequest, this.grpcMetadata, function (error, responseMessage) {
      self.serviceManagerIsRunning = responseMessage != null;
    });
  }

  // public startService(id: string, state: string) {
  //   var self = this;

  //   let serviceActionRequest = new ServiceActionRequest();
  //   serviceActionRequest.setId(id);

  //   if (state == 'Stopped') {
  //     this.smartComGrpcClient.serviceStart(serviceActionRequest, this.grpcMetadata, function (error, responseMessage) {

  //     });
  //   } else {
  //     this.smartComGrpcClient.serviceContinue(serviceActionRequest, this.grpcMetadata, function (error, responseMessage) {

  //     });
  //   }
  // }

  // public stopService(id: string) {

  //   let serviceStopRequest = new ServiceActionRequest();
  //   serviceStopRequest.setId(id);

  //   var self = this;
  //   this.smartComGrpcClient.serviceStop(serviceStopRequest, this.grpcMetadata, function (error, responseMessage) {

  //   });
  // }

  // public pauseService(id: string) {

  //   let serviceActionRequest = new ServiceActionRequest();
  //   serviceActionRequest.setId(id);

  //   var self = this;
  //   this.smartComGrpcClient.servicePause(serviceActionRequest, this.grpcMetadata, function (error, responseMessage) {

  //   });
  // }

  // public continueService(id: string) {

  //   let serviceActionRequest = new ServiceActionRequest();
  //   serviceActionRequest.setId(id);

  //   var self = this;
  //   this.smartComGrpcClient.serviceContinue(serviceActionRequest, this.grpcMetadata, function (error, responseMessage) {

  //   });
  // }

  public subscribeToUpdates() {

    this.grpcMetadata.set('Authorization', this.authService.getAuthorizationHeader());

    let request = new RefreshServicesRequest();
    let self = this;

    if (!this.unsubscribeRefresh) {

      this.unsubscribeRefresh = this.smartComGrpcClient.refreshServices(request, this.grpcMetadata).on('data', (response: RefreshServicesResponse) => {

        let responseObject = response.toObject();

        responseObject.servicesList.forEach(grpcService => {
          let service = this.services.find(d => d.Id == grpcService.id);

          if (service && grpcService) {
            service['State'] = grpcService.state;
            service['DiagnosticMessage'] = grpcService.diagnosticmessage;
            service['RealScanRate'] = grpcService.realscanrate;
            service['ExecutionTime'] = grpcService.executiontime;
            service['MinExecutionTime'] = grpcService.minexecutiontime;
            service['MaxExecutionTime'] = grpcService.maxexecutiontime;
            service.Enabled = grpcService.enabled;
          }
        });

        this.serviceManagerIsRunning = response.getRunning();
        this.changeDetectorRef.detectChanges();
      }).on('status', (error) => {
        this.unsubscribeRefresh = null;

        if (this.canRetry) {
          setTimeout(function () {
            self.subscribeToUpdates();
          }, 5000);
        }
      });
    }
  }

  //#endregion

  //#region Members 'API' :: loadServices(), changeServiceEnable()

  public loadServices(event: LazyLoadEvent) {
    this.loading = true;
    let self = this;

    let pageNumber = event != null ? event.first / event.rows : 1;
    let pageSize = event != null ? event.rows : 10;
    let orderByAscending = event != null ? event.sortOrder == 1 : false;
    let orderByColumn = event != null && event.sortField ? event.sortField : 'Id';
    let searchTerms = [];
    let searchColumns = [];

    let params = `pageNumber=${pageNumber}&pageSize=${pageSize}&orderByAscending=${orderByAscending}&orderByColumn=${orderByColumn}`;

    this.httpClientService.RequestFromAPI('Service', 'List', params).subscribe(result => {
      this.subscribeToUpdates();
      this.services = result.Entities;
      this.totalRecords = result.TotalCount;
      this.loading = false;
    }, error => {
      setTimeout(function () {
        self.loadServices(null);
      }, 5000);

      this.loading = false;
    });
  }

  public changeServiceEnable(service: Service) {

    this.changeServiceEnableLoading = true;

    let params = 'id=' + service.Id;
    this.httpClientService.RequestFromAPI('Service', 'ChangeEnable', params).subscribe((response) => {
      this.changeServiceEnableLoading = false;
    });
  }

  //#endregion

  //#region Members 'Crud' :: editService(), addService(), copyService(), sidebarClosed()

  public editService(service: Service, isClone: boolean = false) {
    this.openSidebarEvent.emit({ selectedView: 'service', selectedId: service.Id, isNew: isClone });
  }

  public addService() {
    this.openSidebarEvent.emit({ selectedView: 'service', selectedId: null, isNew: true });
  }

  public copyService(service: Service) {
    this.editService(service, true);
  }

  public sidebarClosed() {
    this.loadServices(null);
  }

  //#endregion

  //#region Members 'Delete' :: deleteSelected(), deleteService(), confirmDelete()

  public async deleteSelected() {

    var self = this;

    Swal.fire({
      title: 'Confirma a exclusão do serviço?',
      text: 'Todas as configurações serão perdidas e não poderão ser recuperadas!',
      icon: 'error',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#00acac',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#ff5b57',
      focusCancel: true
    }).then(function (result: SweetAlertResult) {
      if (result.isConfirmed) {
        self.confirmDelete();
      }
    });
  }

  public deleteService(service: Service) {
    this.selectedServices = [service];
    this.deleteSelected();
  }

  private confirmDelete() {
    let params = '';
    this.selectedServices.forEach((entity) => {
      if (params != '') { params += '&' };
      params += 'ids[]=' + entity.Id;
    });

    this.httpClientService.RequestFromAPI('Service', 'Delete', params).subscribe(result => {
      if (result) {
        this.loadServices(null);
        this.messageService.add({ key: 'delete_success', severity: 'success', summary: 'Registro excluído', detail: 'Registro excluído com sucesso!', life: 1500 });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erro ao excluir', detail: 'Não foi possível excluir o registro!' });
      }

    }, error => console.error(error));
  }

  //#endregion

  //#region Members 'Log' :: showLog()

  public showLog(selectedService: Service, logSourceType: LogSourceTypeMap[keyof LogSourceTypeMap]): void {
    this.loggerService.subscribeToLog(selectedService.Id, selectedService.Description, logSourceType);
  }

  //#endregion

  //#region Members 'StateDetails' :: showStateDetails()

  public showStateDetails(service: Service) {

    let params = 'id=' + service['DiagnosticMessage'].id;
    this.httpClientService.RequestFromAPI('DiagnosticMessage', '', params).subscribe((response) => {

      const activeModal = this.modalService.open(DiagnosticMessageDetailComponent, { size: 'xl' });
      activeModal.componentInstance['selectedMessage'] = response;
    });


  }

  //#endregion

  //#region Members 'Utils' :: getScanRateDescription(), getRealScanRateClass(), getStateClass()

  public getScanRateDescription(scanRate: number) {
    if (scanRate < 1000) {
      return scanRate + ' ms';
    }
    else if (scanRate < 60000) {
      return Math.round(scanRate / 1000) + ' s';
    }
    else if (scanRate < 3600000) {
      return Math.round((scanRate / 1000) / 60) + ' m';
    }
  }

  public getRealScanRateClass(scanRate: number, realScanRate: number) {
    if (realScanRate <= (scanRate * 1.2)) {
      return 'real-scan-rate-good';
    }
    else {
      return 'real-scan-rate-bad';
    }
  }

  public getStateClass(service: Service) {

    let state = service['State'];

    if (service.Enabled) {
      switch (state) {
        case 'Running':
          return 'fa-play';
        case 'StartPending':
        case 'ContinuePending':
        case 'RetryPending':
          return 'fa-clock';
        case 'StopPending':
          return 'fa-stop-circle';
        case 'Stopped':
          return 'fa-stop';
        case 'PausePending':
          return 'fa-pause-circle';
        case 'Paused':
          return 'fa-pause';
      }
    }
    else {
      return 'fa-minus-circle text-muted';
    }
  }

  //#endregion
}

