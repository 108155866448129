import { Component, Input, Output, EventEmitter, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { grpc } from '@improbable-eng/grpc-web';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../projects/shared/src/lib/services/auth.service';
import pageSettings from '../../config/page-settings';
import { SystemManagerClient } from '../../grpc/systemManager_pb_service';
import { ResourceInstallerComponent } from '../../pages/resource-installer/resource-installer.component';
import { SystemSettingsComponent } from '../../pages/system/system-settings/system-settings.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import {
	SystemManagerRequest,
	SystemRefreshResponse,
} from '../../grpc/systemManager_pb';
import { environment } from '../../../../projects/shared/src/public-api';

@Component({
	selector: 'header',
	templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
	@Input() pageSidebarTwo;
	@Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
	@Output() toggleMobileSidebar = new EventEmitter<boolean>();
	@Output() toggleMobileRightSidebar = new EventEmitter<boolean>();
	pageSettings = pageSettings;
	smartComGrpcClient: SystemManagerClient;
	currentClock: string = '';
	unsubscribeRefresh: any;
	notifications: any[] = [];
	tokenData: any;
	canRetry: boolean;

	constructor(private renderer: Renderer2, private modalService: NgbModal, private authService: AuthService, private router: Router) {
		this.smartComGrpcClient = new SystemManagerClient(environment.config.api_hostname);

		this.tokenData = this.authService.getUserData();
	}

	mobileSidebarToggle() {
		this.toggleMobileSidebar.emit(true);
	}
	mobileRightSidebarToggle() {
		this.toggleMobileRightSidebar.emit(true);
	}
	toggleSidebarRight() {
		this.toggleSidebarRightCollapsed.emit(true);
	}

	mobileTopMenuToggle() {
		this.pageSettings.pageMobileTopMenuToggled = !this.pageSettings.pageMobileTopMenuToggled;
	}

	mobileMegaMenuToggle() {
		this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings.pageMobileMegaMenuToggled;
	}

	ngOnInit(): void {
		this.canRetry = true;
		this.startRefreshing();
	}

	ngOnDestroy() {
		this.canRetry = false;
		if (this.unsubscribeRefresh) {
			this.unsubscribeRefresh.cancel();
		}

		this.pageSettings.pageMobileTopMenuToggled = false;
		this.pageSettings.pageMobileMegaMenuToggled = false;
	}

	private startRefreshing() {
		let self = this;

		if (!this.unsubscribeRefresh) {
			let grpcMetadata = new grpc.Metadata();
			grpcMetadata.set('Authorization', this.authService.getAuthorizationHeader());

			let request = new SystemManagerRequest();

			this.unsubscribeRefresh = this.smartComGrpcClient.refresh(request, grpcMetadata).on('data', (response: SystemRefreshResponse) => {

				let responseObject = response.toObject();
				this.currentClock = responseObject.clock;
				this.notifications = responseObject.notificationsList;

				if (!this.authService.hasValidToken()) {
					this.authService.emitAuthenticationExpiredEvent();
				}
			}).on('status', (error) => {
				this.unsubscribeRefresh = null;

				if (this.authService.hasValidToken() && this.canRetry) {
					setTimeout(function () {
						self.startRefreshing();
					}, 5000);
				}
			});
		}
	}

	public installResource() {
		this.modalService.open(ResourceInstallerComponent, { size: 'xl' });
	}

	public logout() {
		this.authService.logout();
		this.router.navigate(["login"]);
	}

	public settings() {
		this.modalService.open(SystemSettingsComponent, { size: 'xl' });
	}

	public profile() {
		this.modalService.open(UserProfileComponent, { size: 'xl' });
	}
}
