<div class="modal-header bg-blue-darker">
    <h4 class="modal-title text-white">Criação de variáveis em massa</h4>
    <button type="button" class="close" (click)="onCancel()">×</button>
</div>
<div class="modal-body" style="padding-top: 0;">
    <fieldset class="parent-select-box rounded">
        <legend>Dispositivo para comunicação</legend>
        <form [formGroup]="deviceForm">
            <div class="row">
                <div class="col-lg-12">
                    <label for="selectedChannel">Escolha um dispositivo</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><i class="pi pi-tags" style="line-height: 1.25;"></i></span>
                        <div class="col-5" style="padding-left:0; padding-right:0;">
                            <p-dropdown [options]="devicesNodes" (onChange)="changeSelectedDevice($event.value.entity);" formControlName="device" dataKey="Id" optionLabel="Description" [filter]="false" filterBy="Description" [showClear]="false" [style]="{'width':'100%'}" placeholder="Selecione um dispositivo"
                                [group]="true">
                                <ng-template let-group pTemplate="group">
                                    <div class="p-d-flex p-ai-center" style="background-color:#edf7fa;font-size: 10px; padding: 4px 2px;">
                                        <i class="fa-solid fa-fw fa-server"></i> &nbsp;
                                        <span>{{group.Description}}</span>
                                    </div>
                                </ng-template>
                                <ng-template let-device pTemplate="item">
                                    <div class="p-d-flex p-ai-center" style="margin-left:15px; font-size: 14px;">
                                        <i class="fa-solid fa-fw fa-plug"></i> &nbsp;
                                        <span>{{device.Description}}</span>
                                    </div>

                                </ng-template>
                            </p-dropdown>
                        </div>
                        <button type="button" pButton pRipple icon="pi pi-search" [disabled]="!selectedDevice" (click)="browseTags(null);" label="Procurar" class="p-button-success"></button>
                    </div>
                </div>
            </div>
        </form>
    </fieldset>
    <fieldset class="entity-form rounded">
        <legend>Variáveis</legend>

        <p-treeTable [value]="nodes" selectionMode="checkbox" [(selection)]="selectedNodes" [loading]="loading" (onNodeExpand)="onNodeExpand($event)" styleClass="p-datatable-xs" *ngIf="selectedDevice">
            <!-- <ng-template pTemplate="caption">
                <p-button icon="pi pi-search" label="Pesquisar" (click)="browseTags(null)"></p-button>
            </ng-template> -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:40%">
                        <p-treeTableHeaderCheckbox></p-treeTableHeaderCheckbox> Descrição
                    </th>
                    <th style="width:50%">Endereço</th>
                    <th>Tipo</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <tr [ngClass]="{'data-row': rowData.NodeClass == 2}">
                    <td>
                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                        <p-treeTableCheckbox [value]="rowNode" *ngIf="!rowData.CanExpand"></p-treeTableCheckbox>
                        <i class="fa-solid fa-fw fa-{{rowData.Icon}}"></i> &nbsp; {{rowData.Description}}
                    </td>
                    <td>{{rowData.Identifier}}</td>
                    <td>{{rowData.DataTypeDescription}}</td>
                </tr>
            </ng-template>
        </p-treeTable>

        <div class="alert alert-warning fade show" *ngIf="!selectedDevice">Selecione um dispositivo para poder pesquisar as variáveis</div>
    </fieldset>
</div>
<div class="modal-footer" *ngIf="moduleIsReady">
    <div class="row" style="width: 100%;">
        <div class="col-lg-2">
            <a href="#" (click)="onCancel()" class="footer-cancel-link modal-footer-cancel-link">
                <i class="fas fa-redo"></i>&nbsp; Cancelar e fechar
            </a>
        </div>
        <div class="col-lg-5"></div>
        <div class="col-lg-5">
            <button type="submit" (click)="onSubmit()" class="btn btn-success btn-block" [disabled]="!isValid()">Salvar</button>
        </div>
    </div>
</div>