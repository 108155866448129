// package: SmartCom
// file: systemManager.proto

var systemManager_pb = require("./systemManager_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var SystemManager = (function () {
  function SystemManager() {}
  SystemManager.serviceName = "SmartCom.SystemManager";
  return SystemManager;
}());

SystemManager.Start = {
  methodName: "Start",
  service: SystemManager,
  requestStream: false,
  responseStream: false,
  requestType: systemManager_pb.SystemManagerRequest,
  responseType: systemManager_pb.SystemManagerResponse
};

SystemManager.Stop = {
  methodName: "Stop",
  service: SystemManager,
  requestStream: false,
  responseStream: false,
  requestType: systemManager_pb.SystemManagerRequest,
  responseType: systemManager_pb.SystemManagerResponse
};

SystemManager.Refresh = {
  methodName: "Refresh",
  service: SystemManager,
  requestStream: false,
  responseStream: true,
  requestType: systemManager_pb.SystemManagerRequest,
  responseType: systemManager_pb.SystemRefreshResponse
};

SystemManager.RefreshServices = {
  methodName: "RefreshServices",
  service: SystemManager,
  requestStream: false,
  responseStream: true,
  requestType: systemManager_pb.RefreshServicesRequest,
  responseType: systemManager_pb.RefreshServicesResponse
};

SystemManager.RefreshChannels = {
  methodName: "RefreshChannels",
  service: SystemManager,
  requestStream: false,
  responseStream: true,
  requestType: systemManager_pb.RefreshChannelsRequest,
  responseType: systemManager_pb.RefreshChannelsResponse
};

SystemManager.RefreshDevices = {
  methodName: "RefreshDevices",
  service: SystemManager,
  requestStream: false,
  responseStream: true,
  requestType: systemManager_pb.RefreshDevicesRequest,
  responseType: systemManager_pb.RefreshDevicesResponse
};

SystemManager.RefreshTags = {
  methodName: "RefreshTags",
  service: SystemManager,
  requestStream: false,
  responseStream: true,
  requestType: systemManager_pb.RefreshTagsRequest,
  responseType: systemManager_pb.RefreshTagsResponse
};

exports.SystemManager = SystemManager;

function SystemManagerClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

SystemManagerClient.prototype.start = function start(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SystemManager.Start, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SystemManagerClient.prototype.stop = function stop(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SystemManager.Stop, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SystemManagerClient.prototype.refresh = function refresh(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(SystemManager.Refresh, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

SystemManagerClient.prototype.refreshServices = function refreshServices(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(SystemManager.RefreshServices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

SystemManagerClient.prototype.refreshChannels = function refreshChannels(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(SystemManager.RefreshChannels, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

SystemManagerClient.prototype.refreshDevices = function refreshDevices(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(SystemManager.RefreshDevices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

SystemManagerClient.prototype.refreshTags = function refreshTags(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(SystemManager.RefreshTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.SystemManagerClient = SystemManagerClient;

