<div class="login-cover">
    <div class="login-cover-image" [ngStyle]="{ 'background-image': 'url(' + this.bg + ')' }"></div>
    <div class="login-cover-bg"></div>
</div>

<!-- begin login -->
<div class="login login-v2" data-pageload-addclass="animated fadeIn">
    <!-- begin brand -->
    <div class="login-header">
        <div class="brand">
            <!-- <span class="logo"></span>  -->
            <img src="./assets/img/logo/logo.png" height="40" style="margin-right: 8px; margin-bottom:8px;" /> <b>WEIPA</b> SmartCom
            <small>intercomunicações inteligentes</small>
        </div>
        <div class="icon">
            <i class="fa fa-lock"></i>
        </div>
    </div>
    <!-- end brand -->
    <!-- begin login-content -->
    <div class="login-content">
        <form [formGroup]="loginForm" class="margin-bottom-0" (submit)="formSubmit()">
            <div class="form-group m-b-20">
                <input type="text" class="form-control form-control-lg" formControlName="username" placeholder="Usuário" />
            </div>
            <div class="form-group m-b-20">
                <input type="password" class="form-control form-control-lg" formControlName="password" placeholder="Senha" />
            </div>
            <!-- <div class="checkbox checkbox-css m-b-20">
                <input type="checkbox" id="remember_checkbox" formControlName="remember" />
                <label for="remember_checkbox">Lembre-me</label>
            </div> -->
            <div class="login-buttons">
                <button type="submit" class="btn btn-success btn-block btn-lg" [disabled]="!isValid()">Acessar</button>
            </div>
            <div class="m-t-20">
                Desenvolvido por <a href="https://weipa.com.br/" target="_blank">Weipa Automação e Sistemas</a>.
            </div>
        </form>
    </div>
    <!-- end login-content -->
</div>
<!-- end login -->

<!-- <ul class="login-bg-list clearfix">
    <li *ngFor="let list of bgList" [ngClass]="{ 'active': list.active }">
        <a href="javascript:;" (click)="changeBg(list)" [ngStyle]="{ 'background-image': 'url(' + list.bg + ')' }"></a>
    </li>
</ul> -->