import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng-lts/api';
import { FileUpload } from 'primeng-lts/fileupload';
import { HttpHeaders } from '@angular/common/http';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { AuthService, environment, HttpClientService } from '../../../../projects/shared/src/public-api';

@Component({
  selector: 'app-resource-installer',
  templateUrl: './resource-installer.component.html',
  styleUrls: ['./resource-installer.component.css']
})
export class ResourceInstallerComponent implements OnInit {

  uploadUrl: string;
  uploadedFiles: any[] = [];
  httpHeaders: HttpHeaders;
  checking: any;

  constructor(private activeModal: NgbActiveModal, private messageService: MessageService, private authService: AuthService, private httpClientService: HttpClientService) {
    this.uploadUrl = environment.config.api_hostname + '/Resource/Upload';

    this.httpHeaders = new HttpHeaders({ 'Authorization': this.authService.getAuthorizationHeader() })
  }

  ngOnInit(): void {
  }

  //#region Members :: onUpload(), onBeforeSend(), getFileSizeKbs(), removeFile(), onCancel()

  public onUpload(event) {

    let result = event.originalEvent.body;

    if (result.Success) {
      for (let file of event.files) {
        this.uploadedFiles.push(file);
      }

      this.messageService.add({ severity: 'info', summary: result.Message, detail: result.Details });
      this.activeModal.close({ Success: true, NeedWait: false });
    }
    else {

      if (result.NeedRestart) {
        var self = this;

        Swal.fire({
          title: result.Message,
          text: result.Details,
          icon: 'warning',
          confirmButtonText: 'Confirmar',
          confirmButtonColor: '#00acac',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#ff5b57',
          focusConfirm: true
        }).then(function (result: SweetAlertResult) {
          if (result.isConfirmed) {
            self.confirmRestart();
          }
        });
      }
      else {
        this.messageService.add({ severity: 'error', summary: result.Message, detail: result.Details });
      }
    }
  }

  public getFileSizeKbs(file: File) {
    return file ? file.size / 1024 : 0;
  }

  public removeFile(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    uploader.remove(null, index);
  }

  public onCancel() {
    this.activeModal.close(false);
  }

  public onError($event) {

    console.debug($event);

    this.messageService.add({ severity: 'error', summary: 'Erro no envio', detail: $event.error.error });
  }

  //#endregion

  //#region Members :: confirmRestart(), checkIfIsCompleted()

  private confirmRestart() {
    this.httpClientService.RequestFromAPI('Resource', 'Restart', null).subscribe(result => {
      Swal.fire({
        title: "Reiniciando...",
        text: "Por favor, aguarde enquanto a aplicação é reiniciada...",
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false
      });

      this.activeModal.close();

      setTimeout(() => {
        setInterval(() => {
          this.checkIfIsCompleted();
        }, 1000);
      }, 5000);

    }, error => {
      this.messageService.add({ severity: 'error', summary: error });
    });
  }

  private checkIfIsCompleted() {

    if (!this.checking) {
      this.checking = true;
      this.httpClientService.RequestFromAPI('Resource', 'IsCompleted', null).subscribe(result => {

        if (result) {
          Swal.close();
          window.location.reload();
        }

        this.checking = false;
      }, error => {
        this.checking = false;
      });
    }
  }

  //#endregion
}
