<p-toast></p-toast>

<div class="modal-header bg-blue-darker">
    <h4 class="modal-title text-white">Adicionar novo dispositivo</h4>
    <button type="button" class="close" (click)="onCancel()">×</button>
</div>
<div class="modal-body">

    <p-treeTable #tt [value]="channelTypes" styleClass="p-treetable-sm">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th colspan="2">
                    Descrição
                </th>
            </tr>
            <tr>
                <th colspan="2">
                    <input pInputText type="text" (input)="tt.filter($event.target.value, col.field, col.filterMatchMode)" style="width: 100%;">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
            <tr [ngClass]="{'channel-row': rowNode.node.children}">
                <td>
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    <i class="fa fa-{{rowData.IconKey}}"></i> {{rowData.Description}}
                </td>
                <td class="text-right" style="width:10%;">
                    <p-button icon="pi pi-pencil" styleClass="p-button-warning" class="p-button-sm" *ngIf="!rowNode.node.children" (click)="selectDeviceType(rowData.Id)" label="Selecionar"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-treeTable>

</div>
<div class="modal-footer">
    <div class="row" style="width: 100%;">
        <div class="col-lg-5">
            <a href="#" (click)="onCancel()" class="text-dark text-left">
                <i class="fas fa-redo"></i>&nbsp; Cancelar e fechar
            </a>
        </div>
        <div class="col-lg-7"> &nbsp;</div>
    </div>
</div>