//////////////////////////////
// Core Module
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as global from './config/globals';
import { CommonModule } from '@angular/common';

// Main Component
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarRightComponent } from './components/sidebar-right/sidebar-right.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { PanelComponent } from './components/panel/panel.component';
import { FloatSubMenuComponent } from './components/float-sub-menu/float-sub-menu.component';


// Component Module
//import { AgmCoreModule } from '@agm/core';
// import { FullCalendarModule }              from '@fullcalendar/angular';
// import dayGridPlugin                       from '@fullcalendar/daygrid';
// import timeGridPlugin                      from '@fullcalendar/timegrid';
// import interactionPlugin                   from "@fullcalendar/interaction";
// import listPlugin                          from '@fullcalendar/list';
// import bootstrapPlugin                     from '@fullcalendar/bootstrap';
//import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
//import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TrendModule } from 'ngx-trend';
//import { HighlightJsModule } from 'ngx-highlight-js';
//import { CountdownModule } from 'ngx-countdown';
//import { NgChartjsModule } from 'ng-chartjs';
import { TagInputModule } from 'ngx-chips';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
//import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import { NvD3Module } from 'ng2-nvd3';
//import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
// import 'd3';
// import 'nvd3';
// import { CalendarModule, DateAdapter }     from 'angular-calendar';
// import { adapterFactory }                  from 'angular-calendar/date-adapters/date-fns';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { JwtModule } from "@auth0/angular-jwt";

import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';

// SmartCom
import { ColorPickerModule } from 'ngx-color-picker';
import { HttpClientModule } from '@angular/common/http';

import { DashboardV1Page } from './pages/dashboard/v1/dashboard-v1';

// Custom Components
import { ServiceListComponent } from './pages/service/service-list/service-list.component';
import { ServiceEditComponent } from './pages/service/service-edit/service-edit.component';
import { ResourceInstallerComponent } from './pages/resource-installer/resource-installer.component';
import { SystemManagerComponent } from './pages/system/system-manager/system-manager.component';
import { DiagnosticMessageDetailComponent } from './pages/diagnostic-message/diagnostic-message-detail/diagnostic-message-detail.component';
import { ChannelEditComponent } from './pages/channel/channel-edit/channel-edit.component';

import { DeviceListComponent } from './pages/device/device-list/device-list.component';
import { DeviceNewComponent } from './pages/device/device-new/device-new.component';
import { DeviceEditComponent } from './pages/device/device-edit/device-edit.component';
import { TagsCopyComponent } from './pages/tag/tags-copy/tags-copy.component';

import { TagEditComponent } from './pages/tag/tag-edit/tag-edit.component';
import { TagFormComponent } from './pages/tag/tag-form/tag-form.component';
import { TagListComponent } from './pages/tag/tag-list/tag-list.component';
import { TagBatchCreationComponent } from './pages/tag/tag-batch-creation/tag-batch-creation.component';

import { LogConsoleComponent } from './pages/log-console/log-console.component';
import { LoginScreenComponent } from './pages/login-screen/login-screen';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { SystemSettingsComponent } from './pages/system/system-settings/system-settings.component';

// Custom Services
import { HttpClientService } from '../../projects/shared/src/public-api';
import { LoggerService } from './services/logger.service';
import { AuthGuard } from './guards/authGuard';

// PRIMENG
import { ToolbarModule } from 'primeng-lts/toolbar';
import { TableModule } from 'primeng-lts/table';
import { TreeTableModule } from 'primeng-lts/treetable';
import { ContextMenuModule } from 'primeng-lts/contextmenu';
import { DropdownModule } from 'primeng-lts/dropdown';
import { InputTextModule } from 'primeng-lts/inputtext';
import { InputMaskModule } from 'primeng-lts/inputmask';
import { MessagesModule } from 'primeng-lts/messages';
import { MessageModule } from 'primeng-lts/message';
import { ConfirmDialogModule } from 'primeng-lts/confirmdialog';
import { InputNumberModule } from 'primeng-lts/inputnumber';
import { PaginatorModule } from 'primeng-lts/paginator';
import { ButtonModule } from 'primeng-lts/button';
import { MessageService } from 'primeng-lts/api';
import { ToastModule } from 'primeng-lts/toast';
import { FileUploadModule } from 'primeng-lts/fileupload';
import { CheckboxModule } from 'primeng-lts/checkbox';
import { PanelModule } from 'primeng-lts/panel';
import { MenuModule } from 'primeng-lts/menu';
import { AccordionModule } from 'primeng-lts/accordion';
import { BadgeModule } from 'primeng-lts/badge';
import { TabViewModule } from 'primeng-lts/tabview';
import { SplitterModule } from 'primeng-lts/splitter';
import { DividerModule } from 'primeng-lts/divider';
import { ToggleButtonModule } from 'primeng-lts/togglebutton';
import { PickListModule } from 'primeng-lts/picklist';
import { MultiSelectModule } from 'primeng-lts/multiselect';

import { NgxIpModule } from 'ngx-ip';
import { AuthService } from '../../projects/shared/src/lib/services/auth.service';

// Prepare output for SystemJS
declare const window: any;

// FullCalendarModule.registerPlugins([
//   dayGridPlugin,
//   timeGridPlugin,
//   interactionPlugin,
//   listPlugin,
//   bootstrapPlugin
// ]);

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    PanelComponent,
    FloatSubMenuComponent,

    DashboardV1Page,

    LoginScreenComponent,

    // SmartCom
    ServiceListComponent,
    ServiceEditComponent,
    ChannelEditComponent,
    DeviceNewComponent,
    DeviceEditComponent,
    DeviceListComponent,
    TagListComponent,
    TagEditComponent,
    TagFormComponent,

    SystemManagerComponent,
    ResourceInstallerComponent,
    DiagnosticMessageDetailComponent,
    LogConsoleComponent,
    TagBatchCreationComponent,
    TagsCopyComponent,
    UserProfileComponent,
    SystemSettingsComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  entryComponents: [
    ServiceEditComponent,
    ResourceInstallerComponent,
    ChannelEditComponent,

    DeviceNewComponent,
    DeviceEditComponent,

    TagEditComponent
  ],
  imports: [
    AppRoutingModule,
    //AgmCoreModule.forRoot({ apiKey: 'AIzaSyC5gJ5x8Yw7qP_DqvNq3IdZi2WUSiDjskk' }),
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory
    // }),
    //CountdownModule,
    //NgChartjsModule,
    //FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    //HighlightJsModule,
    //LoadingBarRouterModule,
    NgbModule,

    TableModule,
    TreeTableModule,
    PaginatorModule,
    ToolbarModule,
    ContextMenuModule,
    PanelModule,
    MenuModule,
    AccordionModule,
    BadgeModule,

    DropdownModule,
    InputTextModule,
    InputNumberModule,
    InputMaskModule,
    ButtonModule,
    CheckboxModule,
    TabViewModule,
    DividerModule,
    SplitterModule,
    ToggleButtonModule,
    PickListModule,

    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    ToastModule,
    FileUploadModule,
    MultiSelectModule,

    // SliderModule,
    // DialogModule,
    // MultiSelectModule,

    // NvD3Module,
    // NgxChartsModule,
    // NgxDatatableModule,
    // NgxDaterangepickerMd.forRoot(),
    PerfectScrollbarModule,

    SweetAlert2Module.forRoot(),
    TagInputModule,
    TrendModule,

    NgbModalModule,
    ColorPickerModule,
    CommonModule,

    NgxIpModule,

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        //allowedDomains: ["*"],
        //disallowedRoutes: [],
      },
    }),

    MonacoEditorModule
  ],
  providers: [
    Title, {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    HttpClientService,
    AuthService,
    MessageService,
    LoggerService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private router: Router,
    private titleService: Title,
    private route: ActivatedRoute) {

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        var title = 'WEIPA SmartCom | ' + this.route.snapshot.firstChild.data['title'];
        this.titleService.setTitle(title);
      }
    });
  }
}

// FIX: error TS2314: Generic type 'ModuleWithProviders<T>' requires 1 type argument(s).
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
