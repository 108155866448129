import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import pageSettings from '../../config/page-settings';
import { AuthService } from '../../../../projects/shared/src/lib/services/auth.service';
import { MessageService } from 'primeng-lts/api';

@Component({
  selector: 'login-screen',
  templateUrl: './login-screen.html'
})

export class LoginScreenComponent implements OnInit, OnDestroy {
  bg;
  bgList;
  app;
  pageSettings = pageSettings;
  loginForm: FormGroup;
  bgNumber: number;

  constructor(private router: Router, private authService: AuthService, private formBuilder: FormBuilder, private messageService: MessageService) {
    this.pageSettings.pageEmpty = true;

    this.createForm();
    this.bgNumber = this.getRandomInt(1, 18);
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }

  ngOnInit() {
    this.bg = '/assets/img/login-bg/login-bg-' + this.bgNumber + '.jpg';
  }

  private getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  private createForm() {
    this.loginForm = this.formBuilder.group(
      {
        username: ['', Validators.required],
        password: ['', Validators.required],
        remember: [false, Validators.required],
      });
  }

  public formSubmit() {

    let formValue = this.loginForm.value;

    let username = formValue.username;
    let password = formValue.password;
    let remember = formValue.remember;

    this.authService.login(username, password, remember).then(response => {
      this.router.navigate(['system-manager']);
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Usuário logado com sucesso!', life: 1500 });
    }, response => {
      if (!response.error.Message) {
        this.messageService.add({ severity: 'error', summary: 'Erro interno!', detail: 'O servidor não respondeu corretamente. Tente novamente ou contate o suporte.' });
      }
      else {
        this.messageService.add({ severity: 'error', summary: response.error.Status, detail: response.error.Message });
      }
    });
  }

  public isValid() {
    return this.loginForm.valid;
  }
}
