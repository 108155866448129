import { Component, OnInit, Input, ViewContainerRef, forwardRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from "../../../config/globals";
import { SystemJsLoaderService } from '../../../core/system-js-loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClientService, FormBase, Device, FormButton, IFormBase, Tag, settings } from '../../../../../projects/shared/src/public-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-tag-edit',
  templateUrl: './tag-edit.component.html',
  styleUrls: ['./tag-edit.component.css'],
  providers: [{ provide: FormBase, useExisting: TagEditComponent }]
})

export class TagEditComponent extends FormBase implements OnInit, IFormBase {

  @Input() isNew: boolean;
  @Input() selectedId: string;

  @ViewChild('tagModuleView', { read: ViewContainerRef })
  private anchorViewContainer: ViewContainerRef;

  public showTagForm = false;

  public devices: Device[] = [];
  public selectedDevice: Device;

  public deviceForm: FormGroup;

  public selectedTag: Tag;
  selectedFormBase: IFormBase;
  selectedTagDescription: string;
  componentInstance: any;

  constructor(private readonly systemJsLoader: SystemJsLoaderService, private httpClientService: HttpClientService, private formBuilder: FormBuilder) {
    super();

    this.createForm();
  }

  ngOnInit() {

    if (!this.selectedId && this.isNew) {
      this.selectedTagDescription = 'Nova variável';
      this.loadDevices();
    }
    else if (this.selectedId) {
      this.callEdit();
    }
  }

  //#region Forms :: createForm(), updateForm()

  public createForm() {
    this.deviceForm = this.formBuilder.group({ device: [null, Validators.required] });
  }

  public updateForm() {
    this.deviceForm.patchValue({ device: this.selectedDevice });
  }

  //#endregion

  //#region IFormBase :: onSubmit(), onCancel(), resetForm(), isValid()

  public onSubmit(): Promise<any> {

    let promise = new Promise((resolve, reject) => {

      let entity = this.selectedFormBase.onSubmit();

      if (entity) {
        entity.Id = this.selectedTag.Id;
        entity.DeviceId = this.selectedTag.DeviceId;

        entity.Settings = JSON.stringify(entity);

        this.httpClientService.SendToAPI('Tag', 'Save', entity).subscribe(result => {
          if (result) {
            this.selectedFormBase.resetForm();

            resolve({
              title: 'Sucesso!',
              type: 'success',
              message: 'Registro salvo com sucesso!',
              closeAfter: 100
            });
          }
        }, error => {
          reject({
            title: 'Erro!',
            type: 'danger',
            message: 'Não foi possivel salvar o registro.',
          });
        });
      }
    });

    return promise;
  }

  public onCancel(): any {
    if (this.selectedFormBase) {
      let isPristine = this.selectedFormBase.onCancel();

      return isPristine && this.deviceForm.pristine;
    }
    return true;
  }

  public isValid(): boolean {
    return this.selectedFormBase?.isValid();
  }

  //#endregion

  //#region Members :: callEdit(), callNew(), loadView()

  private callEdit() {
    let params = 'id=' + this.selectedId;

    this.httpClientService.RequestFromAPI('Tag', '', params).subscribe(tag => {

      if (this.isNew) {
        this.selectedId = null;
        tag.Id = settings.GUID_EMPTY;
        tag.Description = '(Copy of) ' + tag.Description;
      }

      this.selectedTagDescription = tag.Description;
      this.selectedTag = tag;

      this.loadDevices().then(response => {

        console.debug(tag.DeviceId);

        this.selectedDevice = this.devices.find(device => device.Id === tag.DeviceId);
        this.updateForm();
      });

      this.showTagForm = false;

      if (tag.ModuleName) {
        this.loadView(tag);
      } else {
        this.showTagForm = true;
      }
    }, error => console.error(error));
  }

  private callNew(deviceId: string) {
    let params = 'deviceId=' + deviceId;

    this.httpClientService.RequestFromAPI('Tag', 'New', params).subscribe(tag => {
      this.selectedTag = tag;

      this.showTagForm = false;

      if (tag.ModuleName) {
        this.loadView(tag);
      } else {
        this.showTagForm = true;
      }
    }, error => console.error(error));
  }

  private loadView(tag: Tag) {

    this.selectedTag = tag;

    var moduleName = tag.ModuleName;
    var moduleNamespace = tag.ModuleNamespace;
    var moduleEntryName = tag.ModuleEntryName;
    var componentName = tag.ComponentName;

    this.moduleIsReady = false;

    if (this.componentInstance) {
      this.componentInstance.destroy();
      this.componentInstance = null;
    }

    this.systemJsLoader.load(moduleName, moduleNamespace, moduleEntryName, componentName).then(componentFactory => {
      this.componentInstance = this.anchorViewContainer.createComponent(componentFactory);
      this.selectedFormBase = this.componentInstance.instance as IFormBase;

      this.selectedFormBase['selectedTag'] = this.selectedTag;

      this.moduleIsReady = true;
    }).catch(e => console.error(e));
  }

  //#endregion

  //#region Members :: loadDevices(), changeSelectedDevice()

  public loadDevices(): Promise<any> {

    let promise = new Promise((resolve, reject) => {
      this.httpClientService.RequestFromAPI('Device', 'List', '').subscribe(result => {
        this.devices = result.Entities;
        resolve(true);
      }, error => {
        console.error(error);
        reject(false);
      });
    });

    return promise;
  }

  public changeSelectedDevice(selectedValue) {
    this.selectedDevice = selectedValue;

    let self = this;
    let isPristine = !this.selectedFormBase || this.selectedFormBase.onCancel();

    if (!isPristine) {
      Swal.fire({
        title: 'Confirma a mudança de dispositivo?',
        text: 'Todas as modificações não salvas serão perdidas!',
        icon: 'warning',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#00acac',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#ff5b57',
        focusCancel: true
      }).then(function (result: SweetAlertResult) {
        if (result.isConfirmed) {
          self.callNew(selectedValue.Id);
        }
      });
    }
    else {
      this.callNew(selectedValue.Id);
    }
  }

  //#endregion
}
