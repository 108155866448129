// import { group, animate, query, style, trigger, transition, state } from '@angular/animations';
import { Component, Output, EventEmitter, ElementRef, ContentChild, Renderer2, TemplateRef, ViewChild, ChangeDetectorRef, ContentChildren } from '@angular/core';
import * as global from '../../config/globals';
import pageSettings from '../../config/page-settings';
import { FormButton } from '../../../../projects/shared/src/lib/form-button';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { FormBase, IFormBase } from '../../../../projects/shared/src/public-api';
import { MessageService } from 'primeng-lts/api';

@Component({
  selector: 'sidebar-right',
  templateUrl: './sidebar-right.component.html',
  styleUrls: ['./sidebar-right.component.css'],
})

export class SidebarRightComponent {
  @ContentChild(FormBase) content: IFormBase;

  @Output() hideSidebarEvent = new EventEmitter<boolean>();
  @Output() refreshSidebarEvent = new EventEmitter<boolean>();

  public global = global;
  public pageSettings = pageSettings;

  public buttons: FormButton[];
  public alert: any;
  public closeAction: any;
  public alertUnsubscribe: any;
  isReady: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef, private messageService: MessageService) {
  }

  ngOnInit() {
  }

  ngAfterViewChecked() {

    let moduleIsReady = this.content?.moduleIsReady;

    if (moduleIsReady != this.isReady) { }
    {
      this.isReady = moduleIsReady;
      this.changeDetectorRef.detectChanges();
    }
  }

  submit() {
    let submitPromise = this.content.onSubmit();
    let self = this;

    submitPromise.then(result => {
      this.alert = result;

      this.messageService.add({ severity: 'success', summary: result.title, detail: result.message, life: 1500 });

      if (result.closeAfter > 0) {
        setTimeout(() => { self.hideSidebarEvent.emit(true); }, result.closeAfter);
      }

    }).catch(err => {
      this.alert = err;
    });
  }

  close() {

    let self = this;

    let isPristine = this.content.onCancel();

    if (!isPristine) {
      Swal.fire({
        title: 'Confirma o cancelamento?',
        text: 'Todas as modificações não salvas serão perdidas!',
        icon: 'warning',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#00acac',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#ff5b57',
        focusCancel: true
      }).then(function (result: SweetAlertResult) {
        if (result.isConfirmed) {
          self.hideSidebarEvent.emit(false);
        }
      });

    } else {
      this.hideSidebarEvent.emit(false);
    }
  }

  isValid(): boolean {
    return this.content?.isValid();
  }

  refresh() {
  }
}
