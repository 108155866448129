import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input, NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { normalizeLogMessage } from '../../services/helpers/log-message.helper';
import { LogMessage } from '../../services/models/log-message.model';

@Component({
  selector: 'log-console',
  templateUrl: './log-console.component.html',
  styleUrls: ['./log-console.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogConsoleComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() key;
  @Input() title;
  @Input() logStream: LogMessage;
  @Input() history: LogMessage[] = [];
  @Input() theme: 'dark' | 'light' = 'dark';
  @Input() icons = true;
  @Input() customClass = 'log-container';
  @Input() animated = true;
  @Input() maxEntries = 100;
  @ViewChild('container', { static: false }) container: ElementRef;
  private storage: Storage;

  constructor(private zone: NgZone, private changeDetectorRef: ChangeDetectorRef) {
    this.storage = window.localStorage;
  }

  ngOnInit(): void {
    this.initFromStorage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['logStream'] && changes['logStream'].currentValue) {

      this.zone.run(() => {
        const normalizedMsg = normalizeLogMessage(changes['logStream'].currentValue);

        if (this.history.length >= this.maxEntries) {
          this.history.splice(0, 1);
        }

        this.history.push(normalizedMsg);
        this.setToStorage();


      });
    }

    setTimeout(() => this.scrollToBottom());
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  //#region Members :: setToStorage(), initFromStorage(), scrollToBottom()

  private setToStorage() {
    this.storage.setItem('loggerHistory_' + this.key.replaceAll('-', '_'), JSON.stringify(this.history));
  }

  private initFromStorage() {
    let loggerHistoryValue = this.storage.getItem('loggerHistory_' + this.key.replaceAll('-', '_'));

    if (loggerHistoryValue) {
      let loggerHistory = JSON.parse(loggerHistoryValue);
      this.history = loggerHistory;
    }
  }

  public scrollToBottom() {
    this.container.nativeElement.scrollTop = this.container.nativeElement.scrollHeight;
  }

  //#endregion
}
