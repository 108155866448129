<div class="form-header bg-blue-darker rounded-top">
    <h4 class="text-white">
        {{selectedTagDescription}}
    </h4>
</div>
<fieldset class="parent-select-box rounded">
    <legend>Dispositivo para comunicação</legend>
    <form [formGroup]="deviceForm">
        <div class="row">
            <div class="col-lg-12">
                <label for="selectedChannel">Escolha um dispositivo</label>

                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="pi pi-tags" style="line-height: 1.25;"></i></span>
                    <div class="col-5" style="padding-left:0; padding-right:0;">
                        <p-dropdown [options]="devices" formControlName="device" dataKey="Id" [autoDisplayFirst]="false" [disabled]="!isNew" (onChange)="changeSelectedDevice($event.value);" optionLabel="Description" [style]="{'minWidth':'100%'}"></p-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </form>
</fieldset>

<fieldset class="entity-form rounded" [hidden]="!selectedDevice">
    <legend>Configuração da variável</legend>
    <div #tagModuleView *ngIf="!showTagForm"></div>

    <app-tag-form [selectedTag]="selectedTag" *ngIf="showTagForm"></app-tag-form>
</fieldset>