import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng-lts/api';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { AuthService } from '../../../../../projects/shared/src/lib/services/auth.service';
import { EnumUtils, HttpClientService, InterfaceType, IpAddressMode, IpAddressType, Role, settings, SystemInterface, SystemInterfaceNetwork, SystemInterfaceSetting, SystemSetting, User, WirelessOperationMode } from '../../../../../projects/shared/src/public-api';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.css']
})
export class SystemSettingsComponent implements OnInit {
  settingsForm: FormGroup;
  logLevels: any[];
  wirelessOperationModes: { Id: number; Description: string; }[];
  ipAddressModes: { Id: number; Description: string; }[];
  systemUsersForm: FormGroup;
  users: User[] = [];
  selectedUsers: User[] = [];
  selectedUser: User;

  totalRecords: any;
  loading: boolean;

  roles: Role[] = [];
  selectedRoles: Role[] = [];

  guidEmpty: string;
  tokenData: any = { unique_name: '' };
  systemSetting: SystemSetting;
  wifiNetworks: any[] = [];
  scanningWifi: boolean;
  wirelessBands: any[];

  constructor(private activeModal: NgbActiveModal, private formBuilder: FormBuilder, private httpClientService: HttpClientService, private authService: AuthService, private messageService: MessageService, private changeDetectorRef: ChangeDetectorRef) {
    this.logLevels = EnumUtils.GetLogLevels();

    this.wirelessOperationModes = EnumUtils.GetWirelessOperationModes();
    this.ipAddressModes = EnumUtils.GetIpAddressModes();
    this.wirelessBands = EnumUtils.GetWirelessBands();

    this.loadSystemSetting();
    this.loadRoles();
    this.createForm();

    this.guidEmpty = settings.GUID_EMPTY;
    this.tokenData = this.authService.getUserData();
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  private createForm() {
    this.settingsForm = this.formBuilder.group({
      ServiceManagerLogLevel: [0, Validators.required],
      ServiceManagerLogSaveEnabled: [false],
      ServiceManagerLogSavePath: [''],

      CommManagerLogLevel: [0, Validators.required],
      CommManagerLogSaveEnabled: [false],
      CommManagerLogSavePath: [''],

      Note: [''],
      ExternalApiAddress: [''],

      // Custom
      Wireless: this.formBuilder.group({
        OperationMode: [WirelessOperationMode.AccessPoint, Validators.required],
        NetworkName: ['', Validators.required],
        Band: [0],
        Channel: [1],
        Password: ['', Validators.required],

        IpAddressMode: [IpAddressMode.Dynamic, Validators.required],
        IpAddress: [''],
        SubnetMask: [''],
        DefaultGateway: [''],
        PrimaryDns: [''],
        SecondaryDns: ['']
      }),

      Ethernet: this.formBuilder.group({
        IpAddressMode: [IpAddressMode.Dynamic, Validators.required],
        IpAddress: [''],
        SubnetMask: [''],
        DefaultGateway: [''],
        PrimaryDns: [''],
        SecondaryDns: ['']
      })
    });

    this.createUserForm();
  }

  private updateForm() {

    this.settingsForm.patchValue({
      ServiceManagerLogLevel: this.systemSetting.ServiceManagerLogLevel,
      ServiceManagerLogSaveEnabled: this.systemSetting.ServiceManagerLogSaveEnabled,
      ServiceManagerLogSavePath: this.systemSetting.ServiceManagerLogSavePath,

      CommManagerLogLevel: this.systemSetting.CommManagerLogLevel,
      CommManagerLogSaveEnabled: this.systemSetting.CommManagerLogSaveEnabled,
      CommManagerLogSavePath: this.systemSetting.CommManagerLogSavePath,

      Note: this.systemSetting.Note,
      ExternalApiAddress: this.systemSetting.ExternalApiAddress
    });

    this.systemSetting.SystemInterfaces.forEach(systemInterface => {

      if (systemInterface.InterfaceType == InterfaceType.Ethernet) {

        this.settingsForm.patchValue({
          Ethernet: {
            IpAddressMode: systemInterface.Ipv4ConnectionType,
          }
        });

        let systemInterfaceSetting = systemInterface.SystemInterfaceSettings[0];

        if (systemInterfaceSetting) {
          this.settingsForm.patchValue({
            Ethernet: {
              IpAddress: systemInterfaceSetting.IpAddress,
              SubnetMask: systemInterfaceSetting.SubnetMask,
              DefaultGateway: systemInterfaceSetting.DefaultGateway,
              PrimaryDns: systemInterfaceSetting.PrimaryDns,
              SecondaryDns: systemInterfaceSetting.SecondaryDns
            }
          });
        }
      }
      else if (systemInterface.InterfaceType == InterfaceType.Wireless) {

        this.settingsForm.patchValue({
          Wireless: {
            IpAddressMode: systemInterface.Ipv4ConnectionType,
            OperationMode: systemInterface.OperationMode,
          }
        });

        let systemInterfaceNetwork = systemInterface.SystemInterfaceNetworks[0];

        if (systemInterfaceNetwork) {
          this.settingsForm.patchValue({
            Wireless: {
              NetworkName: systemInterfaceNetwork.NetworkName,
              Password: systemInterfaceNetwork.Password,
            }
          });
        }

        let systemInterfaceSetting = systemInterface.SystemInterfaceSettings[0];

        if (systemInterfaceSetting) {
          this.settingsForm.patchValue({
            Wireless: {
              IpAddress: systemInterfaceSetting.IpAddress,
              SubnetMask: systemInterfaceSetting.SubnetMask,
              DefaultGateway: systemInterfaceSetting.DefaultGateway,
              PrimaryDns: systemInterfaceSetting.PrimaryDns,
              SecondaryDns: systemInterfaceSetting.SecondaryDns
            }
          });
        }
      }
    });
  }

  //#region Members :: onSubmit(), onCancel(), resetForm(), isValid()

  public onSubmit() {
    let result = this.settingsForm.value;

    this.systemSetting.ServiceManagerLogLevel = result.ServiceManagerLogLevel;
    this.systemSetting.ServiceManagerLogSaveEnabled = result.ServiceManagerLogSaveEnabled;
    this.systemSetting.ServiceManagerLogSavePath = result.ServiceManagerLogSavePath;

    this.systemSetting.CommManagerLogLevel = result.CommManagerLogLevel;
    this.systemSetting.CommManagerLogSaveEnabled = result.CommManagerLogSaveEnabled;
    this.systemSetting.CommManagerLogSavePath = result.CommManagerLogSavePath;

    this.systemSetting.Note = result.Note;
    this.systemSetting.ExternalApiAddress = result.ExternalApiAddress;

    ///////////////////////////// WIRELESS ///////////////////////////////

    let wirelessInterface = this.systemSetting.SystemInterfaces.find(i => i.InterfaceType == InterfaceType.Wireless);

    if (!wirelessInterface) {
      wirelessInterface = new SystemInterface();
      wirelessInterface.SystemInterfaceNetworks = [];
      wirelessInterface.SystemInterfaceSettings = [];

      wirelessInterface.InterfaceType = InterfaceType.Wireless;
      wirelessInterface.InterfaceName = 'wlan0';

      this.systemSetting.SystemInterfaces.push(wirelessInterface);
    }

    wirelessInterface.OperationMode = result.Wireless.OperationMode;
    wirelessInterface.Ipv4ConnectionType = result.Wireless.IpAddressMode;
    wirelessInterface.Ipv6ConnectionType = IpAddressMode.Dynamic;

    let wirelessInterfaceNetwork;

    if (!wirelessInterface.SystemInterfaceNetworks || wirelessInterface.SystemInterfaceNetworks.length <= 0) {
      wirelessInterfaceNetwork = new SystemInterfaceNetwork();
      wirelessInterface.SystemInterfaceNetworks.push(wirelessInterfaceNetwork);
    }
    else {
      wirelessInterfaceNetwork = wirelessInterface.SystemInterfaceNetworks[0];
    }

    wirelessInterfaceNetwork.NetworkName = result.Wireless.NetworkName;
    wirelessInterfaceNetwork.Password = result.Wireless.Password;
    wirelessInterfaceNetwork.SecurityEncryption = 0;
    wirelessInterfaceNetwork.SecurityType = 0;
    wirelessInterfaceNetwork.SecurityVersion = 0;

    let wirelessInterfaceSetting;

    if (!wirelessInterface.SystemInterfaceSettings || wirelessInterface.SystemInterfaceSettings.length <= 0) {
      wirelessInterfaceSetting = new SystemInterfaceSetting();
      wirelessInterface.SystemInterfaceSettings.push(wirelessInterfaceSetting);
    }
    else {
      wirelessInterfaceSetting = wirelessInterface.SystemInterfaceSettings[0];
    }

    wirelessInterfaceSetting.AddressType = IpAddressType.IPv4;
    wirelessInterfaceSetting.IpAddress = result.Wireless.IpAddress;
    wirelessInterfaceSetting.SubnetMask = result.Wireless.SubnetMask;
    wirelessInterfaceSetting.DefaultGateway = result.Wireless.DefaultGateway;
    wirelessInterfaceSetting.PrimaryDns = result.Wireless.PrimaryDns;
    wirelessInterfaceSetting.SecondaryDns = result.Wireless.SecondaryDns;

    ///////////////////////////// ETHERNET ///////////////////////////////

    let ethernetInterface = this.systemSetting.SystemInterfaces.find(i => i.InterfaceType == InterfaceType.Ethernet);

    if (!ethernetInterface) {
      ethernetInterface = new SystemInterface();
      ethernetInterface.SystemInterfaceSettings = [];
      ethernetInterface.InterfaceType = InterfaceType.Ethernet;
      ethernetInterface.InterfaceName = 'eth0';

      this.systemSetting.SystemInterfaces.push(ethernetInterface);
    }

    ethernetInterface.OperationMode = result.Ethernet.OperationMode;
    ethernetInterface.Ipv4ConnectionType = result.Ethernet.IpAddressMode;
    ethernetInterface.Ipv6ConnectionType = IpAddressMode.Dynamic;

    let ethernetInterfaceSetting;

    if (!ethernetInterface.SystemInterfaceSettings || ethernetInterface.SystemInterfaceSettings.length <= 0) {
      ethernetInterfaceSetting = new SystemInterfaceSetting();
      ethernetInterface.SystemInterfaceSettings.push(ethernetInterfaceSetting);
    }
    else {
      ethernetInterfaceSetting = ethernetInterface.SystemInterfaceSettings[0];
    }

    ethernetInterfaceSetting.AddressType = IpAddressType.IPv4;
    ethernetInterfaceSetting.IpAddress = result.Ethernet.IpAddress;
    ethernetInterfaceSetting.SubnetMask = result.Ethernet.SubnetMask;
    ethernetInterfaceSetting.DefaultGateway = result.Ethernet.DefaultGateway;
    ethernetInterfaceSetting.PrimaryDns = result.Ethernet.PrimaryDns;
    ethernetInterfaceSetting.SecondaryDns = result.Ethernet.SecondaryDns;

    Swal.fire({
      title: "Salvando as configurações...",
      text: "Por favor, aguarde enquanto as configurações são salvas...",
      icon: 'warning',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false
    });

    this.httpClientService.SendToAPI('SystemSetting', 'Save', this.systemSetting).subscribe(result => {

      Swal.close();

      if (result.Success) {
        this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'Configurações salvas com sucesso!', life: 1500 });
        this.activeModal.close();
      }
      else {
        result.Messages.forEach(message => {
          this.messageService.add({ severity: 'error', summary: 'Erro!', detail: message, life: 5000 });
        });
      }
    }, error => {
      Swal.close();
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: error, life: 5000 });
    });
  }

  public onCancel() {
    this.activeModal.close(false);
  }

  public resetForm() {

  }

  public isValid(): boolean {
    return this.settingsForm.valid;
  }

  //#endregion

  //#region Members 'SystemSetting' :: loadSystemSetting()

  public loadSystemSetting() {
    this.httpClientService.RequestFromAPI('SystemSetting', '', '').subscribe(result => {
      this.systemSetting = result;

      this.updateForm();
    }, error => {
      console.error(error);
    });
  }

  //#endregion

  //#region Members 'WiFi' :: scanWireless()

  public scanWireless() {
    this.scanningWifi = true;

    let params = 'rescan=false';

    if (!this.wifiNetworks.length) {
      params = 'rescan=true';
    }

    this.httpClientService.RequestFromAPI('SystemSetting', 'ScanWiFi', params).subscribe(result => {
      if (result) {

        result.Stations.forEach(station => {

          let intensity = 100;
          for (let index = station.Bars.length - 1; index > 0; index--) {

            if (station.Bars[index] == '_') {
              intensity -= 25;
            }
          }

          station.Intensity = intensity;
        });

        this.wifiNetworks = result.Stations;

        this.messageService.add({ key: 'delete_success', severity: 'success', summary: 'Usuário excluído', detail: 'Usuário excluído com sucesso!', life: 1500 });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erro ao excluir', detail: 'Não foi possível excluir o usuário!' });
      }

      this.scanningWifi = false;

    }, error => {
      this.scanningWifi = false;
      this.messageService.add({ severity: 'error', summary: 'Erro ao buscar', detail: 'Não foi possível listar as redes disponíveis. Por favor, tente novamente!' });
    });
  }

  //#endregion

  //#region Members 'Users' :: loadUsers(), loadRoles(), userFormIsValid(), newUser(), editUser()

  public loadUsers(event) {
    let self = this;
    this.loading = true;

    let pageNumber = event != null ? event.first / event.rows : 1;
    let pageSize = event != null ? event.rows : 10;
    let orderByAscending = event != null ? event.sortOrder == 1 : true;
    let orderByColumn = event != null && event.sortField ? event.sortField : 'Id';
    let searchTerms = [];
    let searchColumns = [];

    let params = `pageNumber=${pageNumber}&pageSize=${pageSize}&orderByAscending=${orderByAscending}&orderByColumn=${orderByColumn}`;

    this.httpClientService.RequestFromAPI('User', 'List', params).subscribe(result => {

      let resultUsers: any[] = result.Entities;
      this.totalRecords = result.TotalCount;

      this.users = resultUsers;
      this.loading = false;
    }, error => {
      if (error.status === 401 || error.status === 403) {
        this.activeModal.close();
      }
    });
  }

  public loadRoles() {
    this.httpClientService.RequestFromAPI('User', 'ListRoles', '').subscribe(result => {

      console.debug(result);

      this.roles = result;
    }, error => {
      console.error(error);
    });
  }

  public userFormIsValid(): boolean {
    return this.systemUsersForm.valid;
  }

  public newUser() {
    this.resetUserForm();
    this.selectedUser = new User();
    this.selectedUser.Id = this.guidEmpty;
  }

  public editUser(user) {
    this.selectedUser = user;

    this.updateUserForm();
  }

  public deleteUser(user: User) {
    var self = this;

    Swal.fire({
      title: 'Confirma a exclusão do usuário?',
      text: 'Todas os dados serão perdidos e não poderão ser recuperados!',
      icon: 'error',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#00acac',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#ff5b57',
      focusCancel: true
    }).then(function (result: SweetAlertResult) {
      if (result.isConfirmed) {
        self.confirmDeleteUser(user);
      }
    });
  }

  private confirmDeleteUser(user) {
    let params = 'ids[]=' + user.Id;

    this.httpClientService.RequestFromAPI('User', 'Delete', params).subscribe(result => {
      if (result) {
        this.loadUsers(null);
        this.messageService.add({ key: 'delete_success', severity: 'success', summary: 'Usuário excluído', detail: 'Usuário excluído com sucesso!', life: 1500 });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erro ao excluir', detail: 'Não foi possível excluir o usuário!' });
      }

    }, error => console.error(error));
  }

  private createUserForm() {
    this.systemUsersForm = this.formBuilder.group({
      GivenName: ['', Validators.required],
      SurName: [''],
      Username: ['', Validators.required],
      Password: ['', Validators.required],
      Enabled: [true, Validators.required],
      Role: [null, Validators.required]
    });
  }

  private updateUserForm() {

    let role = this.selectedUser.UserRoles[0].Role;

    this.systemUsersForm.patchValue({
      GivenName: this.selectedUser.GivenName,
      SurName: this.selectedUser.SurName,
      Username: this.selectedUser.Username,
      Enabled: this.selectedUser.Enabled,
      Role: role,
    });

    let passwordField = this.systemUsersForm.get('Password');
    passwordField.disable();

    if (this.tokenData['unique_name'] == this.selectedUser.Username) {
      this.systemUsersForm.get('Role').disable();
      this.systemUsersForm.get('Enabled').disable();
    }
    else {
      this.systemUsersForm.get('Role').enable();
      this.systemUsersForm.get('Enabled').enable();
    }
  }

  private resetUserForm() {
    this.systemUsersForm.patchValue({
      GivenName: '',
      SurName: '',
      Username: '',
      Password: '',
      Enabled: true,
      Role: null,
    });

    this.systemUsersForm.get('Password').enable();
    this.systemUsersForm.get('Enabled').enable();
    this.systemUsersForm.get('Role').enable();
  }

  public onSubmitUserForm() {

    let value = this.systemUsersForm.value;

    value.Id = this.selectedUser.Id;

    if (!value.Role) {
      let role = this.selectedUser.UserRoles[0].Role;
      value.UserRoles = [{ Role: role, RoleId: role.Id }];
    }
    else {
      value.UserRoles = [{ Role: value.Role, RoleId: value.Role.Id }];
    }

    this.httpClientService.SendToAPI('User', 'Save', value).subscribe(result => {

      this.loadUsers(null);
      this.selectedUser = null;

      this.messageService.add({ severity: 'success', summary: 'Sucesso ao salvar', detail: 'Usuário salvo com sucesso!', life: 1500 });
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Erro ao salvar usuário', detail: error.error });
    });
  }

  public onCancelUserForm() {
    this.selectedUser = null;

    this.resetUserForm();
  }

  public changeStateUserPasswordEdition() {
    let passwordField = this.systemUsersForm.get('Password');

    if (passwordField.enabled) {
      passwordField.disable();
    }
    else {
      passwordField.enable();
    }
  }

  //#endregion
}