// package: SmartCom
// file: logger.proto

var logger_pb = require("./logger_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Logger = (function () {
  function Logger() {}
  Logger.serviceName = "SmartCom.Logger";
  return Logger;
}());

Logger.Subscribe = {
  methodName: "Subscribe",
  service: Logger,
  requestStream: false,
  responseStream: true,
  requestType: logger_pb.SubscribeRequest,
  responseType: logger_pb.SubscribeResponse
};

exports.Logger = Logger;

function LoggerClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

LoggerClient.prototype.subscribe = function subscribe(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(Logger.Subscribe, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.LoggerClient = LoggerClient;

