<div class="modal-header bg-blue-darker">
    <h4 class="modal-title text-white">Instalar novo recurso</h4>
    <button type="button" class="close" (click)="onCancel()">×</button>
</div>
<div class="modal-body">
    <p-fileUpload #createUploader name="myFile[]" url="{{uploadUrl}}" chooseLabel="Escolher" uploadLabel="Enviar" cancelLabel="Cancelar" (onUpload)="onUpload($event)" (onError)="onError($event)" accept=".zip" multiple="multiple" maxFileSize="50000000" [headers]="httpHeaders">
        <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles" class="upload-file">
                    <span class="upload-file-name">{{file.name}}</span>
                    <span class="upload-file-size">{{file.size}} bytes</span>
                </li>
            </ul>
        </ng-template>
        <ng-template pTemplate="toolbar">
            <div class="row" style="margin-top:15px; font-weight:bold;">
                <div class="col-md-8">Arquivo</div>
                <div class="col-md-3 text-center">Tamanho</div>
                <div class="col-md-1 text-center">Remover</div>
            </div>
        </ng-template>
        <ng-template let-file pTemplate="file">
            <div class="row upload-file">
                <div class="col-md-8">{{file.name}}</div>
                <div class="col-md-3 text-center">{{getFileSizeKbs(file) | number:'1.2-2'}} KB</div>
                <div class="col-md-1 text-center">
                    <button pButton type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-xs" (click)="removeFile(file, createUploader)"></button>
                </div>
            </div>
        </ng-template>
    </p-fileUpload>
</div>
<div class="modal-footer">
    <div class="row" style="width: 100%;">
        <div class="col-lg-5">
            <a href="#" (click)="onCancel()" class="text-dark text-left">
                <i class="fas fa-redo"></i>&nbsp; Cancelar e fechar
            </a>
        </div>
        <div class="col-lg-7"> &nbsp;</div>
    </div>
</div>