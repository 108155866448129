import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { Device, HttpClientService, IFormBase, settings } from '../../../../../projects/shared/src/public-api';

@Component({
  selector: 'app-tags-copy',
  templateUrl: './tags-copy.component.html',
  styleUrls: ['./tags-copy.component.css']
})
export class TagsCopyComponent implements OnInit, IFormBase {
  editEntityEventEmitter: EventEmitter<object>;
  newEntityEventEmitter: EventEmitter<any>;

  moduleIsReady: boolean = false;
  public selectedDevice: Device;
  public devices: Device[] = [];
  public deviceForm: FormGroup;

  sourceTags: any[] = [];
  targetTags: any[] = [];

  constructor(private httpClientService: HttpClientService, private formBuilder: FormBuilder, private activeModal: NgbActiveModal, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.createForm();
    this.searchTags();

    this.loadDevices().then(result => {
      this.moduleIsReady = true;
    }, error => {
      console.error(error);
    });
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  //#region Forms :: createForm()

  createForm() {
    this.deviceForm = this.formBuilder.group({ device: [null, Validators.required] });
  }

  //#endregion

  //#region Members :: searchTags()

  public searchTags() {

    let pageNumber = 1;
    let pageSize = 10;
    let orderByAscending = false;
    let orderByColumn = 'Order';
    let searchTerms = [this.selectedDevice.Id];
    let searchColumns = ['Device.Id'];

    let params = `pageNumber=${pageNumber}&pageSize=${pageSize}&orderByAscending=${orderByAscending}&orderByColumn=${orderByColumn}&searchTerms=${searchTerms}&searchColumns=${searchColumns}`;

    this.httpClientService.RequestFromAPI('Tag', 'List', params).subscribe(result => {

      let resultTags: any[] = result.Entities;
      this.sourceTags = resultTags;
    });
  }

  //#endregion

  //#region Members :: loadDevices(), changeSelectedDevice()

  loadDevices(): Promise<any> {

    let promise = new Promise((resolve, reject) => {
      this.httpClientService.RequestFromAPI('Device', 'List', '').subscribe(result => {

        let resultDevices = result.Entities;

        let sourceDeviceIndex = resultDevices.findIndex(d => d.Id == this.selectedDevice.Id);
        resultDevices.splice(sourceDeviceIndex, 1);

        this.devices = result.Entities;

        resolve(true);
      }, error => {
        console.error(error);
        reject(false);
      });
    });

    return promise;
  }

  //#endregion

  //#region IFormBase :: onSubmit(), onCancel(), resetForm(), isValid()

  public onSubmit() {


    let targetDevice = this.deviceForm.value.device;
    let newTags = [];

    this.targetTags.forEach(sourceTag => {

      let newTag = sourceTag;
      newTag.Id = settings.GUID_EMPTY;
      newTag.Device = null;
      newTag.DeviceId = targetDevice.Id;

      newTags.push(newTag);
    });

    this.httpClientService.SendToAPI('Tag', 'SaveCopies', newTags).subscribe(result => {
      if (result) {

        this.activeModal.close(result);

        // resolve({
        //   title: 'Sucesso!',
        //   type: 'success',
        //   message: 'Cópia executada com sucesso!',
        //   closeAfter: 3000
        // });
      }
    }, error => {
      // reject({
      //   title: 'Erro!',
      //   type: 'danger',
      //   message: 'Não foi possivel executar a cópia.',
      // });
    });
  }

  public onCancel() {
    let isPristine = this.deviceForm.pristine;

    let self = this;

    if (!isPristine) {
      Swal.fire({
        title: 'Confirma o cancelamento?',
        text: 'Todas as modificações não salvas serão perdidas!',
        icon: 'warning',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#00acac',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#ff5b57',
        focusCancel: true
      }).then(function (result: SweetAlertResult) {
        if (result.isConfirmed) {
          self.activeModal.dismiss('Cancel');
        }
      });

    } else {
      self.activeModal.dismiss('Cancel');
    }
  }

  public resetForm() {
    this.deviceForm.reset();
  }

  public isValid(): boolean {
    return this.deviceForm.valid;
  }

  //#endregion

}
