import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../public-api';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(private httpClient: HttpClient, private authService: AuthService, private router: Router) {
  }

  public RequestFromAPI(controller: string, action: string, params: string): Observable<any> {
    let url = environment.config.api_hostname + "/" + controller + "/" + action;

    if (params && params != "") {
      url += "?" + params;
    }

    let authHeader = this.authService.getAuthorizationHeader();

    //refresh token and set header.
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': authHeader
    });

    return this.httpClient.get(url, { headers: headers }).pipe(map(result => {
      return result;
    }),
      catchError(err => {
        if (err.status == 401 || err.status === 403) {
          this.authService.emitAuthenticationExpiredEvent();
        }

        return throwError(err);
      }));
  }

  public SendToAPI(controller: string, action: string, data: any): Observable<any> {
    let url = environment.config.api_hostname + "/" + controller + "/" + action;

    let authHeader = this.authService.getAuthorizationHeader();

    //refresh token and set header.
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;',
      'Authorization': authHeader
    });
    return this.httpClient.post(url, data, { headers: headers }).pipe(map(result => {
      return result;
    }),
      catchError(err => {
        if (err.status == 401 || err.status === 403) {
          this.authService.emitAuthenticationExpiredEvent();
        }

        return throwError(err);
      }));
  }


  public DeleteFromAPI(controller: string, action: string, params: string): Observable<any> {
    let url = environment.config.api_hostname + "/" + controller + "/" + action;

    if (params) {
      url += "/" + params;
    }

    let authHeader = this.authService.getAuthorizationHeader();

    //refresh token and set header.
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': authHeader
    });

    return this.httpClient.delete(url, { headers: headers }).pipe(map(result => {
      return result;
    }),
      catchError(err => {
        if (err.status == 401 || err.status === 403) {
          this.authService.emitAuthenticationExpiredEvent();
        }

        return throwError(err);
      }));
  }
}
