import { TagDataType, TagNodeType, TriggerCheckingType, TriggerComparisonOperator, TriggerType } from "./enums";

export class Trigger {
    TriggerTargetTag: number
    TriggerAckTag: number
    TargetValue: object;
    TimeInterval: number;
    TriggerChannelId: number;
    TriggerDeviceId: number;
    TriggerType: TriggerType;
    TriggerCheckingType: TriggerCheckingType;
    TriggerComparisonOperator: TriggerComparisonOperator;
}

export class TagNode {

    /// <summary>
    /// Tipo do nó
    /// </summary>
    NodeType: TagNodeType;

    /// <summary>
    /// Identificador
    /// </summary>
    Identifier: string;

    /// <summary>
    /// Nome
    /// </summary>
    Name: string

    /// <summary>
    /// Descrição
    /// </summary>
    Description: string;

    /// <summary>
    /// Tipo do dado
    /// </summary>
    DataType: TagDataType;

    /// <summary>
    /// Descrição do tipo de dado
    /// </summary>
    DataTypeDescription: string;

    /// <summary>
    /// Pode expandir?
    /// </summary>
    CanExpand: boolean;

    /// <summary>
    /// Ícone
    /// </summary>
    Icon: string;

    /// <summary>
    /// Nós
    /// </summary>
    Nodes: TagNode[];
}

export class TagBrowseRequest {
    /// <summary>
    /// Identificador do dispositivo
    /// </summary>
    DeviceId: string;

    /// <summary>
    /// Nó da tag
    /// </summary>
    Node: TagNode;
}

export class TagBatchSaveRequest {
    /// <summary>
    /// Identificador do dispositivo
    /// </summary>
    DeviceId: string;

    /// <summary>
    /// Nós para salvar
    /// </summary>
    Nodes: TagNode[];
}