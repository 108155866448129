import { AuthService } from '../../../projects/shared/src/lib/services/auth.service';
import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate() {

    if (this.authService.hasValidToken()) {
      return true;
    }
    this.router.navigate(["login"]);
    return false;
  }
}
