<!-- begin #header -->
<div id="header" class="header navbar-inverse">
    <!-- begin navbar-header -->
    <div class="navbar-header">
        <button type="button" class="navbar-toggle pull-left" *ngIf="this.pageSidebarTwo" (click)="mobileRightSidebarToggle()">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
        <a href="/" class="navbar-brand"><!--<span class="navbar-logo"></span>--><img src="./assets/img/logo/logo.png" height="30" style="margin-right: 8px;"/> <b>WEIPA</b>&nbsp; SmartCom</a>
        <button type="button" class="navbar-toggle pt-0 pb-0 mr-0" (click)="mobileTopMenuToggle()" *ngIf="this.pageSettings.pageTopMenu && !this.pageSettings.pageWithoutSidebar">
        <span class="fa-stack fa-lg text-inverse">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
        </span>
        </button>
        <button type="button" class="navbar-toggle pt-0 pb-0 mr-0" (click)="mobileMegaMenuToggle()" *ngIf="this.pageSettings.pageMegaMenu">
        <span class="fa-stack fa-lg text-inverse">
            <i class="far fa-square fa-stack-2x"></i>
            <i class="fa fa-cog fa-stack-1x"></i>
        </span>
        </button>
        <button type="button" class="navbar-toggle" (click)="mobileSidebarToggle()" *ngIf="!this.pageSettings.pageWithoutSidebar">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
        <button type="button" class="navbar-toggle" (click)="mobileTopMenuToggle()" *ngIf="this.pageSettings.pageTopMenu && this.pageSettings.pageWithoutSidebar">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
    </div>
    <!-- end navbar-header -->

    <!-- begin mega-menu -->
    <div class="collapse navbar-collapse" *ngIf="this.pageSettings.pageMegaMenu" [ngStyle]="{ 'display': (this.pageSettings.pageMegaMenu && this.pageSettings.pageMobileMegaMenuToggled) ? 'block' : '' }">
        <ul class="nav navbar-nav">
            <li>
                <a href="javascript:;" class="red active">
                    <i class="fa fa-cogs fa-fw mr-1 mr-md-0"></i> Gerenciamento
                </a>
            </li>
            <!-- <li>
                <a href="javascript:;" class="blue">
                    <i class="fa fa-desktop fa-fw mr-1 mr-md-0"></i> Visualizações
                </a>
            </li>
            <li>
                <a href="javascript:;" class="green">
                    <i class="fa fa-chart-bar fa-fw mr-1 mr-md-0"></i> Relatórios
                </a>
            </li> -->
        </ul>
    </div>
    <!-- end mega-menu -->

    <!-- begin header-nav -->
    <ul class="navbar-nav navbar-right">
        <li class="navbar-form">
            <button pButton pRipple label="Instalar novo recurso" icon="fa fa-play-circle" class="p-button-secondary p-button-xs-with-label" (click)="installResource()"></button>
        </li>
        <li class="navbar-form" style="color:#fff;">
            <div style="font-size: 18px; padding:2px 10px; background-color:#444;">
                <span>{{currentClock}}</span>
            </div>
        </li>

        <li class="navbar-form" [hidden]="true">
            <form name="search_form" action="" method="POST">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Enter keyword" />
                    <button type="submit" class="btn btn-search"><i class="fa fa-search"></i></button>
                </div>
            </form>
        </li>
        <li class="dropdown" ngbDropdown>
            <a href="javascript:;" ngbDropdownToggle class="dropdown-toggle f-s-14">
                <i class="fa fa-bell"></i>
                <span class="label" [hidden]="!notifications || !notifications.length">{{notifications.length}}</span>
            </a>
            <div class="dropdown-menu media-list dropdown-menu-right" ngbDropdownMenu [hidden]="!notifications || !notifications.length">
                <div class="dropdown-header">NOTIFICAÇÕES ({{notifications.length}})</div>
                <a href="javascript:;" class="dropdown-item media" *ngFor="let notification of notifications">
                    <div class="media-left">
                        <i class="fa {{notification.icon}} media-object bg-silver-darker"></i>
                    </div>
                    <div class="media-body">
                        <h6 class="media-heading">{{notification.text}}</h6>
                        <p>{{notification.details}}</p>
                        <div class="text-muted f-s-10">{{notification.time}}</div>
                    </div>
                </a>
                <!-- <div class="dropdown-footer text-center">
                    <a href="javascript:;">View more</a>
                </div> -->
            </div>
        </li>
        <li class="dropdown navbar-language" ngbDropdown *ngIf="this.pageSettings.pageLanguageBar">
            <a href="javascript:;" class="dropdown-toggle pr-1 pl-1 pr-sm-3 pl-sm-3" ngbDropdownToggle>
                <span class="flag-icon flag-icon-us" title="us"></span>
                <span class="name d-none d-sm-inline">EN</span> <b class="caret"></b>
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
                <a href="javascript:;" class="dropdown-item"><span class="flag-icon flag-icon-us" title="us"></span> English</a>
                <a href="javascript:;" class="dropdown-item"><span class="flag-icon flag-icon-cn" title="cn"></span> Chinese</a>
                <a href="javascript:;" class="dropdown-item"><span class="flag-icon flag-icon-jp" title="jp"></span> Japanese</a>
                <a href="javascript:;" class="dropdown-item"><span class="flag-icon flag-icon-be" title="be"></span> Belgium</a>
                <div class="dropdown-divider"></div>
                <a href="javascript:;" class="dropdown-item text-center">more options</a>
            </div>
        </li>
        <li class="dropdown navbar-user" ngbDropdown>
            <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
                <img src="./assets/img/logo/LogoRed.png" alt="" />
                <span class="d-none d-md-inline" *ngIf="tokenData">{{tokenData.given_name}} {{tokenData.family_name}}</span> <b class="caret"></b>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <!-- <a href="javascript:;" class="dropdown-item" (click)="profile();">Perfil</a> -->
                <!-- <a href="javascript:;" class="dropdown-item"><span class="badge badge-danger pull-right">2</span> Inbox</a>
                <a href="javascript:;" class="dropdown-item">Calendar</a> -->
                <a href="javascript:;" class="dropdown-item" (click)="settings();">Configurações</a>
                <div class="dropdown-divider"></div>
                <a href="javascript:;" class="dropdown-item" (click)="logout();">Sair</a>
            </div>
        </li>
        <li class="divider d-none d-md-block" *ngIf="this.pageSidebarTwo"></li>
        <li class="d-none d-md-block" *ngIf="this.pageSidebarTwo">
            <a href="javascript:;" (click)="toggleSidebarRight()" class="f-s-14">
                <i class="fa fa-th"></i>
            </a>
        </li>
    </ul>
    <!-- end header navigation right -->
</div>
<!-- end #header -->