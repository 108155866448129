<div>
    <div id="sidebar-right" class="sidebar sidebar-right">
        <div class="card">

            <div class="card-header p-2">
                <a (click)="close()" class="header-cancel-link">
                    <i class="fas fa-times"></i>&nbsp; Fechar
                </a>
            </div>

            <div class="card-body">
                <perfect-scrollbar>
                    <ng-content></ng-content>
                </perfect-scrollbar>
            </div>

            <div class="card-footer p-4" style="background-color:#fff;">
                <div class="row" *ngIf="isReady">
                    <div class="col-3">
                        <a (click)="close()" class="footer-cancel-link" style="margin-left: 15px;">
                            <i class="fas fa-times"></i>&nbsp; Cancelar e fechar
                        </a>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-5">
                        <button type="submit" (click)="submit()" class="btn btn-success btn-block" [disabled]="!isValid()">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="sidebar-bg sidebar-right" style="box-shadow: 5px 1px 20px 0px #4b4b4b;"></div>
</div>