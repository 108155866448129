import { EventEmitter, Injectable, Output } from "@angular/core";
import { FormButton } from "./form-button";

@Injectable() export abstract class FormBase implements IFormBase {

    //@Output() alert: EventEmitter<object> = new EventEmitter<object>();
    //public abstract getFormButtons(): FormButton[];
    @Output() editEntityEventEmitter: EventEmitter<object> = new EventEmitter<object>();
    @Output() newEntityEventEmitter: EventEmitter<any> = new EventEmitter<any>();

    moduleIsReady: boolean = false;

    public abstract onSubmit(): any;
    public abstract onCancel(): any;

    public resetForm(): any {
        return true;
    }

    public isValid(): boolean {
        return false;
    }


}

export interface IFormBase {
    editEntityEventEmitter: EventEmitter<object>;
    newEntityEventEmitter: EventEmitter<any>;

    moduleIsReady: boolean;

    onSubmit(): any;
    onCancel(): any;
    resetForm(): any;

    isValid(): boolean;
}