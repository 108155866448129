<p-contextMenu #cm [model]="items"></p-contextMenu>
<p-menu #channelMenu [model]="deviceItems" [popup]="true" appendTo="body"></p-menu>

<div class="card">
    <p-toolbar>
        <ng-template pTemplate="left">

            <!-- <button pButton pRipple label="Novo dispositivo" icon="pi pi-plus" class="p-button-primary p-mr-2" (click)="newDevice()" style="margin-right: 15px;"></button> -->

            <button pButton pRipple label="Nova variável" icon="pi pi-plus" class="p-button-success p-mr-2 p-button-xs-with-label" (click)="openNew()" style="margin-right: 15px;"></button>
            <button pButton pRipple label="Criação em massa" icon="pi pi-table" class="p-button-primary p-mr-2 p-button-xs-with-label" (click)="openTagBatchCreation()" style="margin-right: 15px;"></button>
            <button pButton pRipple [label]="Excluir" icon="pi pi-trash" class="p-button-danger p-button-xs" (click)="deleteSelected()" [disabled]="!selectedTags || !selectedTags.length"></button>
        </ng-template>
    </p-toolbar>

    <p-table #dt [value]="tags" [globalFilterFields]="['Description','State']" responsiveLayout="scroll" [(selection)]="selectedTags" dataKey="DeviceId" currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} registros" [showCurrentPageReport]="false"
        styleClass="p-datatable-sm" [lazy]="true" (onLazyLoad)="loadTags($event)" [lazyLoadOnInit]="true" [paginator]="true" [alwaysShowPaginator]="false" [resizableColumns]="true" [tableStyleClass]="'table-wrap'" [rows]="10" [rowHover]="true" [rowsPerPageOptions]="[20,50,100]"
        [rowExpandMode]="'multiple'" [totalRecords]="totalRecords" [loading]="loading" [filterDelay]="0" [(contextMenuSelection)]="selectedTag" [contextMenu]="cm" responsiveLayout="scroll" [expandedRowKeys]="expandedRows">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 2.2rem;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="width: 45%">Descrição</th>
                <th style="width: 15%">Tipo</th>
                <th class="text-center" colspan="2">Últimos valores</th>
                <th style="width: 2.7rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tag let-expanded="expanded">
            <tr class="list-table-row list-table-group-row" *ngIf="rowGroupMetadata[tag.DeviceId] && rowGroupMetadata[tag.DeviceId].index === tag.Index">
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="tag" [style]="{'font-weight':'bold'}" class="p-button-text p-button-rounded p-button-plain p-button-xxs" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td colspan="2">
                    <span style="font-weight:bold; color: #6c757d;">{{tag.Device.Description}}</span>
                </td>
                <td class="text-right"><i class="fa-solid fa-circle-arrow-down tag-input"></i> Leitura</td>
                <td class="text-right"><i class="fa-solid fa-circle-arrow-up tag-output"></i> Escrita</td>
                <td>
                    <button pButton pRipple icon="pi pi-bars" class="p-button-rounded p-button-secondary p-button-xs" (click)="selectedDevice = tag.Device; deviceMenu.toggle($event)"></button>
                    <p-menu #deviceMenu [model]="deviceItems" [popup]="true" appendTo="body"></p-menu>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-tag let-index="rowIndex">
            <tr class="list-table-row">
                <td style="padding-left: 18px;">
                    <p-checkbox name="tagsChecked_{{index}}" [value]="tag" [(ngModel)]="selectedTags"></p-checkbox>
                </td>
                <td>
                    {{tag.Description}}
                    <div style="display: block;font-size:10px;" class="text-warning" *ngIf="tag.ErrorMessage">
                        <i class="fa fa-exclamation-triangle text-warning"></i> {{tag.ErrorMessage}}
                    </div>
                </td>
                <td>{{tag.DataTypeDescription}}</td>
                <!-- <td>{{tag.Enabled ? 'Sim' : 'Não'}}</td> -->
                <!-- <td>
                    <i class="fa-solid" [class]="{'fa-circle-arrow-down tag-input': tag.AccessType == 0, 'fa-circle-arrow-up tag-output': tag.AccessType == 1, 'fa-right-left tag-inout': tag.AccessType == 2}"></i>
                </td> -->
                <td class="text-right">
                    <ng-container *ngIf="tag.AccessType == 0 || tag.AccessType == 2">
                        <ng-container *ngIf="tag.LastValueReadState == 0">
                            <div style="font-size: 14px;">{{tag.LastValueRead}}</div>
                            <span style="display: block; font-size: 10px;">{{tag.LastValueReadDateTime}}</span>
                        </ng-container>
                        <ng-container *ngIf="tag.LastValueReadState > 0">
                            <div style="font-size: 14px;color:brown;">{{tag.LastValueReadStateMessage}}</div>
                            <span style="display: block; font-size: 10px;">{{tag.LastValueReadDateTime}}</span>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="tag.AccessType == 1">
                        <div style="font-size: 14px; color:brown;">Write Only</div>
                    </ng-container>
                </td>
                <td class="text-right">
                    <ng-container *ngIf="tag.AccessType > 0">
                        <ng-container *ngIf="tag.LastValueWriteState == 0">
                            <div style="font-size: 14px;">{{tag.LastValueWrite}}</div>
                            <span style="display: block; font-size: 10px;">{{tag.LastValueWriteDateTime}}</span>
                        </ng-container>
                        <ng-container *ngIf="tag.LastValueWriteState > 0">
                            <div style="font-size: 14px;color:brown;">{{tag.LastValueWriteStateMessage}}</div>
                            <span style="display: block; font-size: 10px;">{{tag.LastValueWriteDateTime}}</span>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="tag.AccessType == 0">
                        <div style="font-size: 14px; color:brown;">Read Only</div>
                    </ng-container>
                </td>
                <!-- <td class="text-center" style="width: 1%;">{{tag.LastValueReadDateTime}}</td> -->
                <td>
                    <button pButton pRipple icon="pi pi-bars" class="p-button-rounded p-button-default p-button-xs" (click)="selectedTag = tag; tagMenu.toggle($event)"></button>
                    <p-menu #tagMenu [model]="items" [popup]="true" appendTo="body"></p-menu>
                </td>
                <!-- <td>
                    <button pButton pRipple icon="pi pi-copy" class="p-button-rounded p-button-success p-button-xs" (click)="copyTag(tag)"></button>
                </td>
                <td>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-xs" (click)="editTag(tag)"></button>
                </td>
                <td>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-xs" (click)="deleteTag(tag)"></button>
                </td> -->
            </tr>
        </ng-template>
    </p-table>
</div>