import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeNode } from 'primeng-lts/api';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { TagBrowseRequest, Device, HttpClientService, IFormBase, settings, TagNode, TagBatchSaveRequest } from '../../../../../projects/shared/src/public-api';

@Component({
  selector: 'app-tag-batch-creation',
  templateUrl: './tag-batch-creation.component.html',
  styleUrls: ['./tag-batch-creation.component.css']
})
export class TagBatchCreationComponent implements OnInit {

  editEntityEventEmitter: EventEmitter<object>;
  newEntityEventEmitter: EventEmitter<any>;
  moduleIsReady: boolean = false;

  public selectedDevice: Device;
  public deviceForm: FormGroup;

  public nodes: TreeNode[] = [];
  public selectedNodes: TreeNode[] = [];

  loading: boolean;
  devicesNodes: any[];


  constructor(private httpClientService: HttpClientService, private formBuilder: FormBuilder, private activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.createForm();

    this.loadCommunications().then(result => {
      if (this.selectedDevice) {
        this.updateForm();
      }
      else {

      }

      this.moduleIsReady = true;
    }, error => {
      this.moduleIsReady = true;
    });


  }

  //#region Forms :: createForm(), updateForm()

  createForm() {
    this.deviceForm = this.formBuilder.group({ device: [null, Validators.required] });
  }

  updateForm() {
    this.deviceForm.patchValue({ device: this.selectedDevice });
  }

  //#endregion

  //#region Members :: browseTags(), createNodes(), onNodeExpand()

  public browseTags(node) {

    let reference: TagNode = node?.data;
    this.loading = true;

    let browseRequest: TagBrowseRequest = new TagBrowseRequest();

    if (this.selectedDevice) {
      browseRequest.DeviceId = this.selectedDevice.Id;
    }

    browseRequest.Node = reference != null ? reference : null;

    this.httpClientService.SendToAPI("Device", 'Browse', browseRequest).subscribe(result => {
      this.createNodes(node, result);
      this.loading = false;
    }, error => {
      console.log(error);
      this.loading = false;
    });

  }

  private createNodes(node, nodesResult) {

    if (node) {
      node.children = [];
    }
    else {
      this.nodes = [];
    }

    if (nodesResult && nodesResult.length) {

      let newNodes = [];

      nodesResult.forEach(nodeResult => {
        let newNode = {
          data: nodeResult,
          parent: node,
          expanded: false,
          leaf: false,
          children: [],
        };
        newNodes.push(newNode);
      });

      if (node) {
        node.children = newNodes;
      }
      else {
        this.nodes = newNodes;
      }
    }
    else {
      node.leaf = true;
    }

    this.nodes = [...this.nodes];
  }

  public onNodeExpand(event) {
    this.browseTags(event.node);
  }

  //#endregion

  //#region Members :: loadCommunications(), changeSelectedDevice()

  loadCommunications(): Promise<any> {

    let promise = new Promise((resolve, reject) => {
      this.httpClientService.RequestFromAPI('Channel', 'ListCommunications', '').subscribe(result => {

        this.devicesNodes = [];
        let nodes = [];

        result.forEach(channel => {

          let node = { Description: channel.Description, items: [] };
          channel.Devices.forEach(device => {
            node.items.push({ Id: device.Id, Description: device.Description, entity: device })
          });

          nodes.push(node);
        });

        this.devicesNodes = nodes;

        resolve(true);
      }, error => {
        console.error(error);
        reject(false);
      });
    });

    return promise;
  }

  public changeSelectedDevice(selectedValue) {

    let self = this;
    let changing = this.selectedDevice && this.selectedDevice.Id != selectedValue.Id;

    this.selectedDevice = selectedValue;

    if (changing) {
      Swal.fire({
        title: 'Confirma a mudança de dispositivo?',
        text: 'Todas as modificações não salvas serão perdidas!',
        icon: 'warning',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#00acac',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#ff5b57',
        focusCancel: true
      }).then(function (result: SweetAlertResult) {
        if (result.isConfirmed) {
          // Resetar tela
          self.nodes = [];
        }
      });
    }
  }

  //#endregion

  //#region Members :: onSubmit(), isValid(), onCancel()

  public onSubmit() {
    let tagNodes = [];

    console.debug(this.selectedNodes);

    this.selectedNodes.forEach(node => {

      if (node.data) {
        let tagNode = node.data;
        tagNodes.push(tagNode);
      }
      else {
        let tagNode = this.getHierarchy(node);
        tagNodes.push(tagNode);
      }
    });

    let request = new TagBatchSaveRequest();

    if (this.selectedDevice) {
      request.DeviceId = this.selectedDevice.Id;
    }

    request.Nodes = tagNodes;

    this.httpClientService.SendToAPI('Tag', 'SaveBatch', request).subscribe(result => {
      if (result) {

        this.activeModal.close(result);
      }
    }, error => {

    });
  }

  public isValid() {
    return this.selectedNodes.length > 0;
  }

  public onCancel() {
    this.activeModal.dismiss();
  }

  //#endregion

  //#region

  private parentNodes: any[];

  private getHierarchy(node) {
    let god = this.getTopHierarchyParent(node);

    this.createHierarchy(node);

    return god;
  }

  private createHierarchy(node) {
    let localNode = this.createTagNode(node.data);

    if (node.parent) {
      let parentNode = this.createHierarchy(node.parent);
      parentNode.Nodes.push(localNode);
    }

    return localNode;
  }

  private getTopHierarchyParent(node) {
    if (node.parent) {
      return this.getTopHierarchyParent(node.parent);
    }
    else {
      // Pai de todo mundo
      return this.createTagNode(node.data);
    }
  }

  private createTagNode(node) {
    let tagNode = node;
    tagNode.Nodes = [];

    return tagNode;
  }

  //#endregion
}
