<div class="modal-header bg-gray-darker">
    <h4 class="modal-title text-white"><i class="fa fa-envelope text-primary"></i> Mensagem de diagnóstico</h4>
    <button type="button" class="close" (click)="onCancel()">×</button>
</div>
<div class="modal-body">
    <table class="table table-bordered table-striped">
        <tr>
            <th>Tipo</th>
            <th>Mensagem</th>
            <th>Data Criação</th>
            <th>Última Ocorrência</th>
            <th>Ocorrências</th>
        </tr>
        <tr>
            <td><i class="fa" [class]="getStateClass(selectedMessage)"></i> {{getStateDescription(selectedMessage)}}</td>
            <td>{{selectedMessage.Message}}</td>
            <td>{{selectedMessage.FirstOccurrenceDateTime | date:'dd/MM/yyyy HH:mm:ss'}}</td>
            <td>{{selectedMessage.LastOccurrenceDateTime | date:'dd/MM/yyyy HH:mm:ss'}}</td>
            <td>{{selectedMessage.Count}}</td>
        </tr>
        <tr>
            <td colspan="5">{{selectedMessage.Details}}</td>
        </tr>
    </table>
</div>
<div class="modal-footer">
    <div class="row" style="width: 100%;">
        <div class="col-lg-2">
            <a href="#" (click)="onCancel()" class="footer-cancel-link modal-footer-cancel-link">
                <i class="fas fa-redo"></i>&nbsp; Cancelar e fechar
            </a>
        </div>
        <div class="col-lg-10"></div>
    </div>
</div>