import { Component, OnInit, Input, Inject, ViewContainerRef, forwardRef, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as global from "../../../config/globals";
import { SystemJsLoaderService } from '../../../core/system-js-loader.service';
import { Channel, FormBase, FormButton, HttpClientService, IFormBase } from '../../../../../projects/shared/src/public-api';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-channel-edit',
  templateUrl: './channel-edit.component.html',
  providers: [{
    provide: FormBase,
    useExisting: forwardRef(() => ChannelEditComponent)
  }]
})

export class ChannelEditComponent extends FormBase implements OnInit, IFormBase {

  @Input() selectedId: string;
  @Input() isNew: boolean;
  @Output() alert: EventEmitter<object> = new EventEmitter<object>();

  @ViewChild('channelModuleView', { read: ViewContainerRef })
  private anchorViewContainer: ViewContainerRef;

  public formButtons: FormButton[];
  public selectedChannel: Channel = null;
  selectedFormBase: IFormBase;

  constructor(private readonly systemJsLoader: SystemJsLoaderService, private httpClientService: HttpClientService, private activeModal: NgbActiveModal, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (this.isNew) {
      this.callNew(this.selectedId);
    }
    else {
      this.callEdit(this.selectedId);
    }
  }

  //#region Members :: callEdit(), callNew()

  private callEdit(channelId: string) {
    let params = 'id=' + (channelId ? channelId : 0);
    this.httpClientService.RequestFromAPI('Channel', '', params).subscribe(channel => {
      this.openView(channel);
    }, error => {
      if (error.status === 401 || error.status === 403) {
        this.activeModal.close();
      }
    });
  }

  private callNew(deviceTypeId: string) {
    let params = 'id=' + deviceTypeId;
    this.httpClientService.RequestFromAPI('Channel', 'New', params).subscribe(channel => {
      this.openView(channel);
    }, error => {
      if (error.status === 401 || error.status === 403) {
        this.activeModal.close();
      }
    });
  }

  //#endregion

  //#region Members :: openView()

  private openView(channel: Channel) {

    this.selectedChannel = channel;

    var moduleName = channel.ModuleName;
    var moduleNamespace = channel.ModuleNamespace;
    var moduleEntryName = channel.ModuleEntryName;
    var componentName = channel.ComponentName;

    this.moduleIsReady = false;

    this.systemJsLoader.load(moduleName, moduleNamespace, moduleEntryName, componentName).then(componentFactory => {

      let componentInstance = this.anchorViewContainer.createComponent(componentFactory);

      this.selectedFormBase = componentInstance.instance as IFormBase;

      this.selectedFormBase['selectedChannel'] = channel;

      this.moduleIsReady = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  //#endregion

  //#region IFormBase :: onSubmit(), onCancel(), resetForm(), isValid()

  public onSubmit(): Promise<any> {

    let promise = new Promise((resolve, reject) => {

      let entity = this.selectedFormBase.onSubmit();

      if (entity) {

        entity.Id = this.selectedChannel.Id;
        entity.ChannelTypeId = this.selectedChannel.ChannelTypeId;

        entity.Settings = JSON.stringify(entity);

        this.httpClientService.SendToAPI('Channel', 'Save', entity).subscribe(result => {
          if (result) {
            this.selectedFormBase['resetForm']();
            this.activeModal.close(result);

            resolve({
              title: 'Sucesso!',
              type: 'success',
              message: 'Registro salvo com sucesso!',
              closeAfter: 3000
            });
          }
        }, error => {
          reject({
            title: 'Erro!',
            type: 'danger',
            message: 'Não foi possivel salvar o registro. (Code: ' + error.status + ')',
          });
        });
      }
    });

    return promise;
  }

  public onCancel() {
    let isPristine = this.selectedFormBase.onCancel();

    let self = this;

    if (!isPristine) {
      Swal.fire({
        title: 'Confirma o cancelamento?',
        text: 'Todas as modificações não salvas serão perdidas!',
        icon: 'warning',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#00acac',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#ff5b57',
        focusCancel: true
      }).then(function (result: SweetAlertResult) {
        if (result.isConfirmed) {
          self.activeModal.dismiss('Cancel');
        }
      });

    } else {
      self.activeModal.dismiss('Cancel');
    }
  }

  public resetForm() {
    this.selectedFormBase.resetForm();
  }

  public isValid(): boolean {
    return this.selectedFormBase?.isValid();
  }

  //#endregion
}
