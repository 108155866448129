export class FormButton{
    constructor(){}
    
    class: string;
    description: string;
    icon: string;
    size: number;
    type: string;
    isCloseButton: boolean;
    formValidator: any;
    callback(): any{};
}