import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from '../../public-api';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output()
  private authenticationExpired = new EventEmitter();
  @Output()
  private authenticationSuccess = new EventEmitter();

  baseUrl: string;
  tokenKey: string = 'access_token';
  loginExpiredEmitted: boolean;

  tokenIsValid: boolean = true;
  tokenCheckInterval: any = null;

  constructor(private httpClient: HttpClient, private jwtHelper: JwtHelperService) {
    this.baseUrl = environment.config.api_hostname + '/Auth';

    this.startTokenCheck();
  }

  //#region Members :: login(), logout()

  public login(username: string, password: string, remember: boolean): Promise<any> {

    this.loginExpiredEmitted = false;

    let promise = new Promise((resolve, reject) => {

      let loginModel = {
        Username: username,
        Password: password
      };

      const credentials = JSON.stringify(loginModel);

      this.httpClient.post(this.baseUrl + '/Login', credentials, {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }).subscribe(response => {
        localStorage.setItem(this.tokenKey, response['token']);

        this.tokenIsValid = true;
        this.startTokenCheck();

        this.authenticationSuccess.emit({ severity: 'success', summary: 'Sucesso', detail: 'Usuário logado com sucesso!', life: 1500 });
        resolve({ success: true });

      }, response => {
        reject({ success: false, error: response.error })
      });
    });

    return promise;
  }

  public logout() {
    this.loginExpiredEmitted = true;
    localStorage.removeItem(this.tokenKey);
  }

  //#endregion

  //#region Members :: emitAuthenticationChangeEvent(), getAuthenticationExpiredEvent(), getAuthenticationSuccessEvent()

  public emitAuthenticationExpiredEvent() {
    this.tokenIsValid = false;
    this.stopTokenCheck();

    if (!this.loginExpiredEmitted) {
      this.loginExpiredEmitted = true;
      this.authenticationExpired.emit({ severity: 'error', summary: 'Login expirado!', detail: 'É necessário realizar novamente o login para continuar acessando.', life: 3000 });
    }
  }

  public getAuthenticationExpiredEvent() {
    return this.authenticationExpired;
  }

  public getAuthenticationSuccessEvent() {
    return this.authenticationSuccess;
  }

  //#endregion

  //#region Members :: getAccessToken(), getAuthorizationHeader(), hasValidToken(), getUserData()

  public getAccessToken() {
    return localStorage.getItem(this.tokenKey);
  }

  public getAuthorizationHeader() {
    const token = this.getAccessToken();

    return 'Bearer ' + token;
  }

  public hasValidToken() {
    const tokenString = this.getAccessToken();

    if (tokenString && this.tokenIsValid) {
      return true;
    }

    return false;
  }

  public getUserData() {
    const token = this.getAccessToken();
    let decodedToken = this.jwtHelper.decodeToken(token);

    return decodedToken;
  }

  //#endregion

  //#region Members :: startTokenCheck(), tokenCheck(), stopTokenCheck()

  private startTokenCheck() {
    if (!this.tokenCheckInterval) {
      this.tokenCheckInterval = setInterval(() => { this.tokenCheck(); }, 30000);
    }
  }

  private tokenCheck() {

    let token = this.getAccessToken();

    if (token) {
      let authHeader = this.getAuthorizationHeader();

      //refresh token and set header.
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': authHeader
      });

      this.httpClient.get(this.baseUrl + '/Check', { headers: headers }).subscribe(result => {
        this.tokenIsValid = true;
      }, error => {
        console.log("Token Invalid!");
        this.emitAuthenticationExpiredEvent();
      });
    }
    else {
      this.tokenIsValid = false;
    }
  }

  private stopTokenCheck() {
    if (this.tokenCheckInterval) {
      clearInterval(this.tokenCheckInterval);
    }
  }

  //#endregion
}