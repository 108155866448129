export enum BaseFieldTypeEnum
{
    /// <summary>
    /// Desconhecido
    /// </summary>
    Unknown = 0,

    /// <summary>
    /// Boleano
    /// </summary>
    Boolean = 1,

    /// <summary>
    /// Data/hora
    /// </summary>
    DateTime = 2,

    /// <summary>
    /// Numérico
    /// </summary>
    Numeric = 3,

    /// <summary>
    /// String
    /// </summary>
    Text = 4,

    /// <summary>
    /// Recurso
    /// </summary>
    Resource = 5,

    /// <summary>
    /// Objeto
    /// </summary>
    Object = 6,

    /// <summary>
    /// Guid
    /// </summary>
    Guid = 7,

    /// <summary>
    /// Coleção
    /// </summary>
    Collection = 8
}