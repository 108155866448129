<!-- <p-toast [baseZIndex]="1000"></p-toast> -->
<sidebar-right *ngIf="!hideSidebar" (hideSidebarEvent)="sidebarClosed($event)" [ngSwitch]="selectedView">
    <app-service-edit [selectedId]="selectedId" [entityTypeId]="entityTypeId" [isNew]="isNew" *ngSwitchCase="'service'"></app-service-edit>
    <app-device-edit [selectedId]="selectedId" [entityTypeId]="entityTypeId" [isNew]="isNew" *ngSwitchCase="'device'"></app-device-edit>
    <app-tag-edit [selectedId]="selectedId" [entityTypeId]="entityTypeId" [isNew]="isNew" *ngSwitchCase="'tag'"></app-tag-edit>
</sidebar-right>

<p-splitter (onResizeEnd)="onResizeEnd($event)" (onResizeStart)="onResizeStart($event)" [minSizes]="[30,5]" layout="vertical" stateKey="loggerSplitter" stateStorage="local" [style]="{'background-color':'transparent', 'height': 'calc(100vh - 50px)'}">
    <ng-template pTemplate>
        <div style="overflow-y:auto;" #contentViewContainer>
            <div class="row" style="margin:15px 5px;" #contentViewChildContainer [hidden]="!showLogConsole">
                <div class="col-lg-4 clear-right-padding">
                    <panel title="Dispositivos" icon="fa fa-fw fa-network-wired">
                        <app-device-list (openSidebarEvent)="changeSelectedView($event)"></app-device-list>
                    </panel>
                </div>
                <div class="col-lg-4">
                    <panel title="Variáveis" icon="fa fa-fw fa-tags">
                        <app-tag-list (openSidebarEvent)="changeSelectedView($event)"></app-tag-list>
                    </panel>
                </div>
                <div class="col-lg-4 clear-left-padding">
                    <panel title="Serviços" icon="fa fa-fw fa-list">
                        <app-service-list (openSidebarEvent)="changeSelectedView($event)"></app-service-list>
                    </panel>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate>
        <div style="width: 100vw; background-color:#212121;" #logTabViewContainer>
            <p-tabView (onClose)="handleClose($event)" [(activeIndex)]="selectedTabIndex" (onChange)="handleChange($event)">
                <p-tabPanel [header]="subscription.Description" [closable]="i > 0" *ngFor="let subscription of subscriptions$ | async; let i = index;">
                    <div #logTabViewContainerChild>
                        <log-console [logStream]="subscription.LogStream$ | async" theme="dark" [animated]="false" [icons]="true" [key]="subscription.Id" [hidden]="!showLogConsole" #logConsole></log-console>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </ng-template>
</p-splitter>