import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeTableModule } from 'primeng-lts/treetable';
import { TreeNode } from 'primeng-lts/api';
import { HttpClientService } from '../../../../../projects/shared/src/public-api';

@Component({
  selector: 'app-device-new',
  templateUrl: './device-new.component.html',
  styleUrls: ['./device-new.component.css'],
})
export class DeviceNewComponent implements OnInit {
  public channelTypes: TreeNode[];
  public channelTypesColumns: any[];

  constructor(
    private activeModal: NgbActiveModal,
    private httpClientService: HttpClientService
  ) { }

  ngOnInit(): void {
    this.channelTypesColumns = [{ field: 'Description', header: 'Descrição' }];

    this.refreshDeviceTypeList();
  }

  public refreshDeviceTypeList() {
    this.httpClientService
      .RequestFromAPI('Device', 'ListChannelTypes', '')
      .subscribe((result: any[]) => {

        if (result && result.length) {
          this.channelTypes = [];
          result.forEach((channelType) => {
            let channelTypeNode = {
              data: { Description: channelType.Description },
              expanded: true,
              children: [],
            };

            channelType.DeviceTypes.forEach((deviceType) => {
              channelTypeNode.children.push({
                data: { Id: deviceType.Id, Description: deviceType.Description },
              });
            });

            this.channelTypes.push(channelTypeNode);
          });
        }
      }, error => {
        if (error.status === 401 || error.status === 403) {
          this.activeModal.close();
        }
      });
  }

  public selectDeviceType(id: string) {
    this.activeModal.close(id);
  }

  public onCancel() {
    this.activeModal.dismiss(null);
  }
}
