// source: systemManager.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.SmartCom.ChannelGrpc', null, global);
goog.exportSymbol('proto.SmartCom.DeviceGrpc', null, global);
goog.exportSymbol('proto.SmartCom.DiagnosticMessageGrpc', null, global);
goog.exportSymbol('proto.SmartCom.DiagnosticStateGrpc', null, global);
goog.exportSymbol('proto.SmartCom.RefreshChannelsRequest', null, global);
goog.exportSymbol('proto.SmartCom.RefreshChannelsResponse', null, global);
goog.exportSymbol('proto.SmartCom.RefreshDevicesRequest', null, global);
goog.exportSymbol('proto.SmartCom.RefreshDevicesResponse', null, global);
goog.exportSymbol('proto.SmartCom.RefreshServicesRequest', null, global);
goog.exportSymbol('proto.SmartCom.RefreshServicesResponse', null, global);
goog.exportSymbol('proto.SmartCom.RefreshTagsRequest', null, global);
goog.exportSymbol('proto.SmartCom.RefreshTagsResponse', null, global);
goog.exportSymbol('proto.SmartCom.ServiceActionRequest', null, global);
goog.exportSymbol('proto.SmartCom.ServiceActionResponse', null, global);
goog.exportSymbol('proto.SmartCom.ServiceGrpc', null, global);
goog.exportSymbol('proto.SmartCom.SystemManagerRequest', null, global);
goog.exportSymbol('proto.SmartCom.SystemManagerResponse', null, global);
goog.exportSymbol('proto.SmartCom.SystemNotification', null, global);
goog.exportSymbol('proto.SmartCom.SystemRefreshResponse', null, global);
goog.exportSymbol('proto.SmartCom.TagGrpc', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.SystemManagerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.SystemManagerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.SystemManagerRequest.displayName = 'proto.SmartCom.SystemManagerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.SystemManagerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.SystemManagerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.SystemManagerResponse.displayName = 'proto.SmartCom.SystemManagerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.ServiceActionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.ServiceActionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.ServiceActionRequest.displayName = 'proto.SmartCom.ServiceActionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.ServiceActionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.ServiceActionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.ServiceActionResponse.displayName = 'proto.SmartCom.ServiceActionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.SystemRefreshResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SmartCom.SystemRefreshResponse.repeatedFields_, null);
};
goog.inherits(proto.SmartCom.SystemRefreshResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.SystemRefreshResponse.displayName = 'proto.SmartCom.SystemRefreshResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.SystemNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.SystemNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.SystemNotification.displayName = 'proto.SmartCom.SystemNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.RefreshServicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.RefreshServicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.RefreshServicesRequest.displayName = 'proto.SmartCom.RefreshServicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.RefreshServicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SmartCom.RefreshServicesResponse.repeatedFields_, null);
};
goog.inherits(proto.SmartCom.RefreshServicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.RefreshServicesResponse.displayName = 'proto.SmartCom.RefreshServicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.RefreshTagsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.RefreshTagsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.RefreshTagsRequest.displayName = 'proto.SmartCom.RefreshTagsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.RefreshTagsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SmartCom.RefreshTagsResponse.repeatedFields_, null);
};
goog.inherits(proto.SmartCom.RefreshTagsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.RefreshTagsResponse.displayName = 'proto.SmartCom.RefreshTagsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.RefreshChannelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.RefreshChannelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.RefreshChannelsRequest.displayName = 'proto.SmartCom.RefreshChannelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.RefreshChannelsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SmartCom.RefreshChannelsResponse.repeatedFields_, null);
};
goog.inherits(proto.SmartCom.RefreshChannelsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.RefreshChannelsResponse.displayName = 'proto.SmartCom.RefreshChannelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.RefreshDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.RefreshDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.RefreshDevicesRequest.displayName = 'proto.SmartCom.RefreshDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.RefreshDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SmartCom.RefreshDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.SmartCom.RefreshDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.RefreshDevicesResponse.displayName = 'proto.SmartCom.RefreshDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.ServiceGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.ServiceGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.ServiceGrpc.displayName = 'proto.SmartCom.ServiceGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.ChannelGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.ChannelGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.ChannelGrpc.displayName = 'proto.SmartCom.ChannelGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.DeviceGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.DeviceGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.DeviceGrpc.displayName = 'proto.SmartCom.DeviceGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.TagGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.TagGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.TagGrpc.displayName = 'proto.SmartCom.TagGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SmartCom.DiagnosticMessageGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SmartCom.DiagnosticMessageGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SmartCom.DiagnosticMessageGrpc.displayName = 'proto.SmartCom.DiagnosticMessageGrpc';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.SystemManagerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.SystemManagerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.SystemManagerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.SystemManagerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.SystemManagerRequest}
 */
proto.SmartCom.SystemManagerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.SystemManagerRequest;
  return proto.SmartCom.SystemManagerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.SystemManagerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.SystemManagerRequest}
 */
proto.SmartCom.SystemManagerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.SystemManagerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.SystemManagerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.SystemManagerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.SystemManagerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.SystemManagerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.SystemManagerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.SystemManagerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.SystemManagerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.SystemManagerResponse}
 */
proto.SmartCom.SystemManagerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.SystemManagerResponse;
  return proto.SmartCom.SystemManagerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.SystemManagerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.SystemManagerResponse}
 */
proto.SmartCom.SystemManagerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.SystemManagerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.SystemManagerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.SystemManagerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.SystemManagerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.SmartCom.SystemManagerResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SmartCom.SystemManagerResponse} returns this
 */
proto.SmartCom.SystemManagerResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.ServiceActionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.ServiceActionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.ServiceActionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.ServiceActionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.ServiceActionRequest}
 */
proto.SmartCom.ServiceActionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.ServiceActionRequest;
  return proto.SmartCom.ServiceActionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.ServiceActionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.ServiceActionRequest}
 */
proto.SmartCom.ServiceActionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.ServiceActionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.ServiceActionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.ServiceActionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.ServiceActionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.SmartCom.ServiceActionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.ServiceActionRequest} returns this
 */
proto.SmartCom.ServiceActionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.ServiceActionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.ServiceActionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.ServiceActionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.ServiceActionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.ServiceActionResponse}
 */
proto.SmartCom.ServiceActionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.ServiceActionResponse;
  return proto.SmartCom.ServiceActionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.ServiceActionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.ServiceActionResponse}
 */
proto.SmartCom.ServiceActionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.ServiceActionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.ServiceActionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.ServiceActionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.ServiceActionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.SmartCom.ServiceActionResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SmartCom.ServiceActionResponse} returns this
 */
proto.SmartCom.ServiceActionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SmartCom.SystemRefreshResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.SystemRefreshResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.SystemRefreshResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.SystemRefreshResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.SystemRefreshResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clock: jspb.Message.getFieldWithDefault(msg, 1, ""),
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.SmartCom.SystemNotification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.SystemRefreshResponse}
 */
proto.SmartCom.SystemRefreshResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.SystemRefreshResponse;
  return proto.SmartCom.SystemRefreshResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.SystemRefreshResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.SystemRefreshResponse}
 */
proto.SmartCom.SystemRefreshResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClock(value);
      break;
    case 2:
      var value = new proto.SmartCom.SystemNotification;
      reader.readMessage(value,proto.SmartCom.SystemNotification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.SystemRefreshResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.SystemRefreshResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.SystemRefreshResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.SystemRefreshResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClock();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.SmartCom.SystemNotification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string clock = 1;
 * @return {string}
 */
proto.SmartCom.SystemRefreshResponse.prototype.getClock = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.SystemRefreshResponse} returns this
 */
proto.SmartCom.SystemRefreshResponse.prototype.setClock = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SystemNotification notifications = 2;
 * @return {!Array<!proto.SmartCom.SystemNotification>}
 */
proto.SmartCom.SystemRefreshResponse.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.SmartCom.SystemNotification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SmartCom.SystemNotification, 2));
};


/**
 * @param {!Array<!proto.SmartCom.SystemNotification>} value
 * @return {!proto.SmartCom.SystemRefreshResponse} returns this
*/
proto.SmartCom.SystemRefreshResponse.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.SmartCom.SystemNotification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SmartCom.SystemNotification}
 */
proto.SmartCom.SystemRefreshResponse.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.SmartCom.SystemNotification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SmartCom.SystemRefreshResponse} returns this
 */
proto.SmartCom.SystemRefreshResponse.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.SystemNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.SystemNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.SystemNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.SystemNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 2, ""),
    time: jspb.Message.getFieldWithDefault(msg, 3, ""),
    details: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.SystemNotification}
 */
proto.SmartCom.SystemNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.SystemNotification;
  return proto.SmartCom.SystemNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.SystemNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.SystemNotification}
 */
proto.SmartCom.SystemNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.SystemNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.SystemNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.SystemNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.SystemNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDetails();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.SmartCom.SystemNotification.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.SystemNotification} returns this
 */
proto.SmartCom.SystemNotification.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string icon = 2;
 * @return {string}
 */
proto.SmartCom.SystemNotification.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.SystemNotification} returns this
 */
proto.SmartCom.SystemNotification.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string time = 3;
 * @return {string}
 */
proto.SmartCom.SystemNotification.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.SystemNotification} returns this
 */
proto.SmartCom.SystemNotification.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string details = 4;
 * @return {string}
 */
proto.SmartCom.SystemNotification.prototype.getDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.SystemNotification} returns this
 */
proto.SmartCom.SystemNotification.prototype.setDetails = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.RefreshServicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.RefreshServicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.RefreshServicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshServicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.RefreshServicesRequest}
 */
proto.SmartCom.RefreshServicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.RefreshServicesRequest;
  return proto.SmartCom.RefreshServicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.RefreshServicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.RefreshServicesRequest}
 */
proto.SmartCom.RefreshServicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.RefreshServicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.RefreshServicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.RefreshServicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshServicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SmartCom.RefreshServicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.RefreshServicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.RefreshServicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.RefreshServicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshServicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.SmartCom.ServiceGrpc.toObject, includeInstance),
    running: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.RefreshServicesResponse}
 */
proto.SmartCom.RefreshServicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.RefreshServicesResponse;
  return proto.SmartCom.RefreshServicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.RefreshServicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.RefreshServicesResponse}
 */
proto.SmartCom.RefreshServicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SmartCom.ServiceGrpc;
      reader.readMessage(value,proto.SmartCom.ServiceGrpc.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunning(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.RefreshServicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.RefreshServicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.RefreshServicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshServicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SmartCom.ServiceGrpc.serializeBinaryToWriter
    );
  }
  f = message.getRunning();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated ServiceGrpc services = 1;
 * @return {!Array<!proto.SmartCom.ServiceGrpc>}
 */
proto.SmartCom.RefreshServicesResponse.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.SmartCom.ServiceGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SmartCom.ServiceGrpc, 1));
};


/**
 * @param {!Array<!proto.SmartCom.ServiceGrpc>} value
 * @return {!proto.SmartCom.RefreshServicesResponse} returns this
*/
proto.SmartCom.RefreshServicesResponse.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SmartCom.ServiceGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SmartCom.ServiceGrpc}
 */
proto.SmartCom.RefreshServicesResponse.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SmartCom.ServiceGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SmartCom.RefreshServicesResponse} returns this
 */
proto.SmartCom.RefreshServicesResponse.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * optional bool running = 2;
 * @return {boolean}
 */
proto.SmartCom.RefreshServicesResponse.prototype.getRunning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SmartCom.RefreshServicesResponse} returns this
 */
proto.SmartCom.RefreshServicesResponse.prototype.setRunning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.RefreshTagsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.RefreshTagsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.RefreshTagsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshTagsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.RefreshTagsRequest}
 */
proto.SmartCom.RefreshTagsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.RefreshTagsRequest;
  return proto.SmartCom.RefreshTagsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.RefreshTagsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.RefreshTagsRequest}
 */
proto.SmartCom.RefreshTagsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.RefreshTagsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.RefreshTagsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.RefreshTagsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshTagsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SmartCom.RefreshTagsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.RefreshTagsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.RefreshTagsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.RefreshTagsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshTagsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.SmartCom.TagGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.RefreshTagsResponse}
 */
proto.SmartCom.RefreshTagsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.RefreshTagsResponse;
  return proto.SmartCom.RefreshTagsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.RefreshTagsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.RefreshTagsResponse}
 */
proto.SmartCom.RefreshTagsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SmartCom.TagGrpc;
      reader.readMessage(value,proto.SmartCom.TagGrpc.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.RefreshTagsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.RefreshTagsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.RefreshTagsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshTagsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SmartCom.TagGrpc.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TagGrpc tags = 1;
 * @return {!Array<!proto.SmartCom.TagGrpc>}
 */
proto.SmartCom.RefreshTagsResponse.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.SmartCom.TagGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SmartCom.TagGrpc, 1));
};


/**
 * @param {!Array<!proto.SmartCom.TagGrpc>} value
 * @return {!proto.SmartCom.RefreshTagsResponse} returns this
*/
proto.SmartCom.RefreshTagsResponse.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SmartCom.TagGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SmartCom.TagGrpc}
 */
proto.SmartCom.RefreshTagsResponse.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SmartCom.TagGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SmartCom.RefreshTagsResponse} returns this
 */
proto.SmartCom.RefreshTagsResponse.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.RefreshChannelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.RefreshChannelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.RefreshChannelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshChannelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.RefreshChannelsRequest}
 */
proto.SmartCom.RefreshChannelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.RefreshChannelsRequest;
  return proto.SmartCom.RefreshChannelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.RefreshChannelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.RefreshChannelsRequest}
 */
proto.SmartCom.RefreshChannelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.RefreshChannelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.RefreshChannelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.RefreshChannelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshChannelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SmartCom.RefreshChannelsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.RefreshChannelsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.RefreshChannelsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.RefreshChannelsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshChannelsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    proto.SmartCom.ChannelGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.RefreshChannelsResponse}
 */
proto.SmartCom.RefreshChannelsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.RefreshChannelsResponse;
  return proto.SmartCom.RefreshChannelsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.RefreshChannelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.RefreshChannelsResponse}
 */
proto.SmartCom.RefreshChannelsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SmartCom.ChannelGrpc;
      reader.readMessage(value,proto.SmartCom.ChannelGrpc.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.RefreshChannelsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.RefreshChannelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.RefreshChannelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshChannelsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SmartCom.ChannelGrpc.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ChannelGrpc channels = 1;
 * @return {!Array<!proto.SmartCom.ChannelGrpc>}
 */
proto.SmartCom.RefreshChannelsResponse.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.SmartCom.ChannelGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SmartCom.ChannelGrpc, 1));
};


/**
 * @param {!Array<!proto.SmartCom.ChannelGrpc>} value
 * @return {!proto.SmartCom.RefreshChannelsResponse} returns this
*/
proto.SmartCom.RefreshChannelsResponse.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SmartCom.ChannelGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SmartCom.ChannelGrpc}
 */
proto.SmartCom.RefreshChannelsResponse.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SmartCom.ChannelGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SmartCom.RefreshChannelsResponse} returns this
 */
proto.SmartCom.RefreshChannelsResponse.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.RefreshDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.RefreshDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.RefreshDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.RefreshDevicesRequest}
 */
proto.SmartCom.RefreshDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.RefreshDevicesRequest;
  return proto.SmartCom.RefreshDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.RefreshDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.RefreshDevicesRequest}
 */
proto.SmartCom.RefreshDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.RefreshDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.RefreshDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.RefreshDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SmartCom.RefreshDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.RefreshDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.RefreshDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.RefreshDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.SmartCom.DeviceGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.RefreshDevicesResponse}
 */
proto.SmartCom.RefreshDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.RefreshDevicesResponse;
  return proto.SmartCom.RefreshDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.RefreshDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.RefreshDevicesResponse}
 */
proto.SmartCom.RefreshDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SmartCom.DeviceGrpc;
      reader.readMessage(value,proto.SmartCom.DeviceGrpc.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.RefreshDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.RefreshDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.RefreshDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.RefreshDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SmartCom.DeviceGrpc.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeviceGrpc devices = 1;
 * @return {!Array<!proto.SmartCom.DeviceGrpc>}
 */
proto.SmartCom.RefreshDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.SmartCom.DeviceGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SmartCom.DeviceGrpc, 1));
};


/**
 * @param {!Array<!proto.SmartCom.DeviceGrpc>} value
 * @return {!proto.SmartCom.RefreshDevicesResponse} returns this
*/
proto.SmartCom.RefreshDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SmartCom.DeviceGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SmartCom.DeviceGrpc}
 */
proto.SmartCom.RefreshDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SmartCom.DeviceGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SmartCom.RefreshDevicesResponse} returns this
 */
proto.SmartCom.RefreshDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.ServiceGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.ServiceGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.ServiceGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.ServiceGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    diagnosticmessage: (f = msg.getDiagnosticmessage()) && proto.SmartCom.DiagnosticMessageGrpc.toObject(includeInstance, f),
    realscanrate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    executiontime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    minexecutiontime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maxexecutiontime: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.ServiceGrpc}
 */
proto.SmartCom.ServiceGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.ServiceGrpc;
  return proto.SmartCom.ServiceGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.ServiceGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.ServiceGrpc}
 */
proto.SmartCom.ServiceGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 4:
      var value = new proto.SmartCom.DiagnosticMessageGrpc;
      reader.readMessage(value,proto.SmartCom.DiagnosticMessageGrpc.deserializeBinaryFromReader);
      msg.setDiagnosticmessage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRealscanrate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExecutiontime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinexecutiontime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxexecutiontime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.ServiceGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.ServiceGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.ServiceGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.ServiceGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDiagnosticmessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.SmartCom.DiagnosticMessageGrpc.serializeBinaryToWriter
    );
  }
  f = message.getRealscanrate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getExecutiontime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMinexecutiontime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMaxexecutiontime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.SmartCom.ServiceGrpc.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.ServiceGrpc} returns this
 */
proto.SmartCom.ServiceGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.SmartCom.ServiceGrpc.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.ServiceGrpc} returns this
 */
proto.SmartCom.ServiceGrpc.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool enabled = 3;
 * @return {boolean}
 */
proto.SmartCom.ServiceGrpc.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SmartCom.ServiceGrpc} returns this
 */
proto.SmartCom.ServiceGrpc.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional DiagnosticMessageGrpc diagnosticMessage = 4;
 * @return {?proto.SmartCom.DiagnosticMessageGrpc}
 */
proto.SmartCom.ServiceGrpc.prototype.getDiagnosticmessage = function() {
  return /** @type{?proto.SmartCom.DiagnosticMessageGrpc} */ (
    jspb.Message.getWrapperField(this, proto.SmartCom.DiagnosticMessageGrpc, 4));
};


/**
 * @param {?proto.SmartCom.DiagnosticMessageGrpc|undefined} value
 * @return {!proto.SmartCom.ServiceGrpc} returns this
*/
proto.SmartCom.ServiceGrpc.prototype.setDiagnosticmessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SmartCom.ServiceGrpc} returns this
 */
proto.SmartCom.ServiceGrpc.prototype.clearDiagnosticmessage = function() {
  return this.setDiagnosticmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SmartCom.ServiceGrpc.prototype.hasDiagnosticmessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 realScanRate = 5;
 * @return {number}
 */
proto.SmartCom.ServiceGrpc.prototype.getRealscanrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.ServiceGrpc} returns this
 */
proto.SmartCom.ServiceGrpc.prototype.setRealscanrate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 executionTime = 6;
 * @return {number}
 */
proto.SmartCom.ServiceGrpc.prototype.getExecutiontime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.ServiceGrpc} returns this
 */
proto.SmartCom.ServiceGrpc.prototype.setExecutiontime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 minExecutionTime = 7;
 * @return {number}
 */
proto.SmartCom.ServiceGrpc.prototype.getMinexecutiontime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.ServiceGrpc} returns this
 */
proto.SmartCom.ServiceGrpc.prototype.setMinexecutiontime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 maxExecutionTime = 8;
 * @return {number}
 */
proto.SmartCom.ServiceGrpc.prototype.getMaxexecutiontime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.ServiceGrpc} returns this
 */
proto.SmartCom.ServiceGrpc.prototype.setMaxexecutiontime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.ChannelGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.ChannelGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.ChannelGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.ChannelGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isconnected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    diagnosticmessage: (f = msg.getDiagnosticmessage()) && proto.SmartCom.DiagnosticMessageGrpc.toObject(includeInstance, f),
    realscanrate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    executiontime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    minexecutiontime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maxexecutiontime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.ChannelGrpc}
 */
proto.SmartCom.ChannelGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.ChannelGrpc;
  return proto.SmartCom.ChannelGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.ChannelGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.ChannelGrpc}
 */
proto.SmartCom.ChannelGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsconnected(value);
      break;
    case 4:
      var value = new proto.SmartCom.DiagnosticMessageGrpc;
      reader.readMessage(value,proto.SmartCom.DiagnosticMessageGrpc.deserializeBinaryFromReader);
      msg.setDiagnosticmessage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRealscanrate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExecutiontime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinexecutiontime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxexecutiontime(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.ChannelGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.ChannelGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.ChannelGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.ChannelGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsconnected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDiagnosticmessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.SmartCom.DiagnosticMessageGrpc.serializeBinaryToWriter
    );
  }
  f = message.getRealscanrate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getExecutiontime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMinexecutiontime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMaxexecutiontime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.SmartCom.ChannelGrpc.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.ChannelGrpc} returns this
 */
proto.SmartCom.ChannelGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.SmartCom.ChannelGrpc.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.ChannelGrpc} returns this
 */
proto.SmartCom.ChannelGrpc.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isConnected = 3;
 * @return {boolean}
 */
proto.SmartCom.ChannelGrpc.prototype.getIsconnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SmartCom.ChannelGrpc} returns this
 */
proto.SmartCom.ChannelGrpc.prototype.setIsconnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional DiagnosticMessageGrpc diagnosticMessage = 4;
 * @return {?proto.SmartCom.DiagnosticMessageGrpc}
 */
proto.SmartCom.ChannelGrpc.prototype.getDiagnosticmessage = function() {
  return /** @type{?proto.SmartCom.DiagnosticMessageGrpc} */ (
    jspb.Message.getWrapperField(this, proto.SmartCom.DiagnosticMessageGrpc, 4));
};


/**
 * @param {?proto.SmartCom.DiagnosticMessageGrpc|undefined} value
 * @return {!proto.SmartCom.ChannelGrpc} returns this
*/
proto.SmartCom.ChannelGrpc.prototype.setDiagnosticmessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SmartCom.ChannelGrpc} returns this
 */
proto.SmartCom.ChannelGrpc.prototype.clearDiagnosticmessage = function() {
  return this.setDiagnosticmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SmartCom.ChannelGrpc.prototype.hasDiagnosticmessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 realScanRate = 5;
 * @return {number}
 */
proto.SmartCom.ChannelGrpc.prototype.getRealscanrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.ChannelGrpc} returns this
 */
proto.SmartCom.ChannelGrpc.prototype.setRealscanrate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 executionTime = 6;
 * @return {number}
 */
proto.SmartCom.ChannelGrpc.prototype.getExecutiontime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.ChannelGrpc} returns this
 */
proto.SmartCom.ChannelGrpc.prototype.setExecutiontime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 minExecutionTime = 7;
 * @return {number}
 */
proto.SmartCom.ChannelGrpc.prototype.getMinexecutiontime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.ChannelGrpc} returns this
 */
proto.SmartCom.ChannelGrpc.prototype.setMinexecutiontime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 maxExecutionTime = 8;
 * @return {number}
 */
proto.SmartCom.ChannelGrpc.prototype.getMaxexecutiontime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.ChannelGrpc} returns this
 */
proto.SmartCom.ChannelGrpc.prototype.setMaxexecutiontime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool enabled = 9;
 * @return {boolean}
 */
proto.SmartCom.ChannelGrpc.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SmartCom.ChannelGrpc} returns this
 */
proto.SmartCom.ChannelGrpc.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.DeviceGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.DeviceGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.DeviceGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.DeviceGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isconnected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    diagnosticmessage: (f = msg.getDiagnosticmessage()) && proto.SmartCom.DiagnosticMessageGrpc.toObject(includeInstance, f),
    realscanrate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    executiontime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    minexecutiontime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    maxexecutiontime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.DeviceGrpc}
 */
proto.SmartCom.DeviceGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.DeviceGrpc;
  return proto.SmartCom.DeviceGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.DeviceGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.DeviceGrpc}
 */
proto.SmartCom.DeviceGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsconnected(value);
      break;
    case 4:
      var value = new proto.SmartCom.DiagnosticMessageGrpc;
      reader.readMessage(value,proto.SmartCom.DiagnosticMessageGrpc.deserializeBinaryFromReader);
      msg.setDiagnosticmessage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRealscanrate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExecutiontime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinexecutiontime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxexecutiontime(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.DeviceGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.DeviceGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.DeviceGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.DeviceGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsconnected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDiagnosticmessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.SmartCom.DiagnosticMessageGrpc.serializeBinaryToWriter
    );
  }
  f = message.getRealscanrate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getExecutiontime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMinexecutiontime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMaxexecutiontime();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.SmartCom.DeviceGrpc.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.DeviceGrpc} returns this
 */
proto.SmartCom.DeviceGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.SmartCom.DeviceGrpc.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.DeviceGrpc} returns this
 */
proto.SmartCom.DeviceGrpc.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool isConnected = 3;
 * @return {boolean}
 */
proto.SmartCom.DeviceGrpc.prototype.getIsconnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SmartCom.DeviceGrpc} returns this
 */
proto.SmartCom.DeviceGrpc.prototype.setIsconnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional DiagnosticMessageGrpc diagnosticMessage = 4;
 * @return {?proto.SmartCom.DiagnosticMessageGrpc}
 */
proto.SmartCom.DeviceGrpc.prototype.getDiagnosticmessage = function() {
  return /** @type{?proto.SmartCom.DiagnosticMessageGrpc} */ (
    jspb.Message.getWrapperField(this, proto.SmartCom.DiagnosticMessageGrpc, 4));
};


/**
 * @param {?proto.SmartCom.DiagnosticMessageGrpc|undefined} value
 * @return {!proto.SmartCom.DeviceGrpc} returns this
*/
proto.SmartCom.DeviceGrpc.prototype.setDiagnosticmessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SmartCom.DeviceGrpc} returns this
 */
proto.SmartCom.DeviceGrpc.prototype.clearDiagnosticmessage = function() {
  return this.setDiagnosticmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SmartCom.DeviceGrpc.prototype.hasDiagnosticmessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 realScanRate = 5;
 * @return {number}
 */
proto.SmartCom.DeviceGrpc.prototype.getRealscanrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.DeviceGrpc} returns this
 */
proto.SmartCom.DeviceGrpc.prototype.setRealscanrate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 executionTime = 6;
 * @return {number}
 */
proto.SmartCom.DeviceGrpc.prototype.getExecutiontime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.DeviceGrpc} returns this
 */
proto.SmartCom.DeviceGrpc.prototype.setExecutiontime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 minExecutionTime = 7;
 * @return {number}
 */
proto.SmartCom.DeviceGrpc.prototype.getMinexecutiontime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.DeviceGrpc} returns this
 */
proto.SmartCom.DeviceGrpc.prototype.setMinexecutiontime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 maxExecutionTime = 8;
 * @return {number}
 */
proto.SmartCom.DeviceGrpc.prototype.getMaxexecutiontime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.DeviceGrpc} returns this
 */
proto.SmartCom.DeviceGrpc.prototype.setMaxexecutiontime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool enabled = 9;
 * @return {boolean}
 */
proto.SmartCom.DeviceGrpc.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SmartCom.DeviceGrpc} returns this
 */
proto.SmartCom.DeviceGrpc.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.TagGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.TagGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.TagGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.TagGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parentid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastreadvalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastreadvaluedatetime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastreadvaluestate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastreadvaluestatemessage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lastwritevalue: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastwritevaluedatetime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lastwritevaluestate: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lastwritevaluestatemessage: jspb.Message.getFieldWithDefault(msg, 10, ""),
    writequeuecount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    errormessage: jspb.Message.getFieldWithDefault(msg, 13, ""),
    errordetails: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.TagGrpc}
 */
proto.SmartCom.TagGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.TagGrpc;
  return proto.SmartCom.TagGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.TagGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.TagGrpc}
 */
proto.SmartCom.TagGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastreadvalue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastreadvaluedatetime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastreadvaluestate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastreadvaluestatemessage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastwritevalue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastwritevaluedatetime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastwritevaluestate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastwritevaluestatemessage(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWritequeuecount(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrordetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.TagGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.TagGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.TagGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.TagGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParentid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastreadvalue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastreadvaluedatetime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastreadvaluestate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastreadvaluestatemessage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLastwritevalue();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastwritevaluedatetime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLastwritevaluestate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLastwritevaluestatemessage();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getWritequeuecount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getErrordetails();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string parentId = 2;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getParentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setParentid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastReadValue = 3;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getLastreadvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setLastreadvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastReadValueDateTime = 4;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getLastreadvaluedatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setLastreadvaluedatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lastReadValueState = 5;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getLastreadvaluestate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setLastreadvaluestate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lastReadValueStateMessage = 6;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getLastreadvaluestatemessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setLastreadvaluestatemessage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string lastWriteValue = 7;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getLastwritevalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setLastwritevalue = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string lastWriteValueDateTime = 8;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getLastwritevaluedatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setLastwritevaluedatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string lastWriteValueState = 9;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getLastwritevaluestate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setLastwritevaluestate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string lastWriteValueStateMessage = 10;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getLastwritevaluestatemessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setLastwritevaluestatemessage = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 writeQueueCount = 11;
 * @return {number}
 */
proto.SmartCom.TagGrpc.prototype.getWritequeuecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setWritequeuecount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool enabled = 12;
 * @return {boolean}
 */
proto.SmartCom.TagGrpc.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string errorMessage = 13;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string errorDetails = 14;
 * @return {string}
 */
proto.SmartCom.TagGrpc.prototype.getErrordetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.TagGrpc} returns this
 */
proto.SmartCom.TagGrpc.prototype.setErrordetails = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.SmartCom.DiagnosticMessageGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SmartCom.DiagnosticMessageGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.DiagnosticMessageGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    details: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstoccurrencedatetime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastoccurrencedatetime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    resetdatetime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    count: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SmartCom.DiagnosticMessageGrpc}
 */
proto.SmartCom.DiagnosticMessageGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SmartCom.DiagnosticMessageGrpc;
  return proto.SmartCom.DiagnosticMessageGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SmartCom.DiagnosticMessageGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SmartCom.DiagnosticMessageGrpc}
 */
proto.SmartCom.DiagnosticMessageGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.SmartCom.DiagnosticStateGrpc} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetails(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstoccurrencedatetime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastoccurrencedatetime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setResetdatetime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SmartCom.DiagnosticMessageGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SmartCom.DiagnosticMessageGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SmartCom.DiagnosticMessageGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDetails();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstoccurrencedatetime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastoccurrencedatetime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getResetdatetime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.DiagnosticMessageGrpc} returns this
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DiagnosticStateGrpc state = 2;
 * @return {!proto.SmartCom.DiagnosticStateGrpc}
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.getState = function() {
  return /** @type {!proto.SmartCom.DiagnosticStateGrpc} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.SmartCom.DiagnosticStateGrpc} value
 * @return {!proto.SmartCom.DiagnosticMessageGrpc} returns this
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.DiagnosticMessageGrpc} returns this
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string details = 4;
 * @return {string}
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.getDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.DiagnosticMessageGrpc} returns this
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.setDetails = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string firstOccurrenceDateTime = 5;
 * @return {string}
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.getFirstoccurrencedatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.DiagnosticMessageGrpc} returns this
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.setFirstoccurrencedatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lastOccurrenceDateTime = 6;
 * @return {string}
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.getLastoccurrencedatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.DiagnosticMessageGrpc} returns this
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.setLastoccurrencedatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string resetDateTime = 7;
 * @return {string}
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.getResetdatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.SmartCom.DiagnosticMessageGrpc} returns this
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.setResetdatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 count = 8;
 * @return {number}
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.SmartCom.DiagnosticMessageGrpc} returns this
 */
proto.SmartCom.DiagnosticMessageGrpc.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * @enum {number}
 */
proto.SmartCom.DiagnosticStateGrpc = {
  NORMAL: 0,
  WARNING: 1,
  ERROR: 2,
  CRITICAL: 4
};

goog.object.extend(exports, proto.SmartCom);
