import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';
import pageSettings from '../../../config/page-settings';
import { DeviceListComponent } from '../../device/device-list/device-list.component';
import { ServiceListComponent } from '../../service/service-list/service-list.component';
import { TagListComponent } from '../../tag/tag-list/tag-list.component';
import { LoggerService, LogSubscription } from '../../../services/logger.service';
import { LoggerClient } from '../../../grpc/logger_pb_service';
import { LogSourceType } from '../../../grpc/logger_pb';
import { LogConsoleComponent } from '../../log-console/log-console.component';
import { TabView, TabViewModule } from 'primeng-lts/tabview';
import { Observable } from 'rxjs/internal/Observable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemSettingsComponent } from '../system-settings/system-settings.component';

@Component({
  selector: 'app-system-manager',
  templateUrl: './system-manager.component.html',
  styleUrls: ['./system-manager.component.css']
})
export class SystemManagerComponent implements OnInit {

  public selectedId: string = null;
  public entityTypeId: string = null;
  public isNew: boolean = false;
  public hideSidebar: boolean = true;
  public sidebarHasClosed: boolean = false;

  @ViewChild(ServiceListComponent)
  public serviceListComponent: ServiceListComponent;

  @ViewChild(DeviceListComponent)
  public deviceListComponent: DeviceListComponent;

  @ViewChild(TagListComponent)
  public tagListComponent: TagListComponent;

  @ViewChild('contentViewContainer')
  public contentViewContainer: ElementRef;

  @ViewChild('contentViewChildContainer')
  public contentViewChildContainer: ElementRef;

  @ViewChild('logTabViewContainer')
  public logTabViewContainer: ElementRef;

  @ViewChildren('logTabViewContainerChild')
  public logTabViewContainerChild: QueryList<ElementRef>;

  @ViewChildren('logConsole')
  public logConsoleChild: QueryList<LogConsoleComponent>;

  public selectedView: string = 'service';
  public selectedObject: any;

  smartComLoggerGrpcClient: LoggerClient;
  subscriptions$: Observable<LogSubscription[]>;
  selectedTabIndex: number = 0;
  removing: boolean;
  storage: Storage;
  showLogConsole: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef, private loggerService: LoggerService, private modalService: NgbModal) {
    pageSettings.pageContentFullWidth = true;
    this.storage = window.localStorage;
  }

  //#region Members :: ngOnInit(), ngAfterViewInit()

  async ngOnInit() {
    this.subscriptions$ = this.loggerService.getSubscriptions$();
    this.selectedTabIndex = this.storage.getItem('loggerSelectedIndex') as unknown as number;
  }

  ngAfterViewInit() {

    this.logTabViewContainerChild.changes.subscribe((result: QueryList<ElementRef>) => {

      if (!this.removing) {
        this.updateLogHeight();
      }

      this.selectedTabIndex = this.storage.getItem('loggerSelectedIndex') as unknown as number;
      this.changeDetectorRef.detectChanges();

      this.removing = false;
    });

    this.loggerService.loggerIndexChangesObservable().subscribe(index => {

      this.selectedTabIndex = index;
      this.storeSelectedIndex();

      this.changeDetectorRef.detectChanges();
    });

    this.loggerService.subscribeToLog('', 'Geral', LogSourceType.SOURCETYPEGENERAL);

    this.updateLogHeight();
    this.changeDetectorRef.detectChanges();
  }

  //#endregion

  //#region Members 'System Manager' :: changeSelectedView(), changeView(), sidebarClosed()

  public changeSelectedView($event) {

    let self = this;

    if (!this.hideSidebar) {
      Swal.fire({
        title: 'Confirma a mudança de seleção?',
        text: 'Todas as modificações não salvas serão perdidas!',
        icon: 'warning',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#00acac',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#ff5b57',
        focusCancel: true
      }).then(function (result: SweetAlertResult) {
        if (result.isConfirmed) {
          self.changeView($event);
        }
      });
    }
    else {
      self.changeView($event);
    }
  }

  private changeView($event) {

    this.hideSidebar = true;
    this.changeDetectorRef.detectChanges();

    this.selectedId = $event.selectedId;
    this.entityTypeId = $event.entityTypeId;
    this.selectedView = $event.selectedView;
    this.selectedObject = $event.selectedObject;
    this.isNew = $event.isNew;

    this.hideSidebar = false;
  }

  public sidebarClosed(needRefresh) {

    this.hideSidebar = true;

    if (needRefresh) {
      // switch (this.selectedView) {
      //   case 'service':
      this.serviceListComponent.sidebarClosed();
      //   break;

      // case 'device':
      this.deviceListComponent.sidebarClosed();
      //   break;

      // case 'tag':
      this.tagListComponent.sidebarClosed();
      //     break;
      // }
    }
  }

  //#endregion

  //#region Members :: updateLogHeight(), hideLogConsole(), scrollSelected(), storeSelectedIndex()

  private updateLogHeight() {

    this.hideLogConsole();

    let logHeight = this.logTabViewContainer.nativeElement.offsetHeight;

    this.logTabViewContainerChild['_results'].forEach(element => {
      element.nativeElement.style.height = (logHeight - 50) + 'px';
    });

    let contentHeight = this.contentViewContainer.nativeElement.offsetHeight;

    this.contentViewChildContainer.nativeElement.style.height = (contentHeight - 50) + 'px';

    this.showLogConsole = true;
    this.changeDetectorRef.detectChanges();
  }

  private hideLogConsole() {
    this.logTabViewContainerChild['_results'].forEach(element => {
      element.nativeElement.style.height = '50px';
    });
    this.showLogConsole = false;

    this.changeDetectorRef.detectChanges();
  }

  private scrollSelected() {
    this.logConsoleChild['_results'][this.selectedTabIndex].scrollToBottom();
  }

  private storeSelectedIndex() {
    this.storage.setItem('loggerSelectedIndex', this.selectedTabIndex.toString())
  }

  //#endregion

  //#region Members :: onResizeStart(), onResizeEnd(), handleClose(), handleChange()

  public onResizeStart($event) {
    this.hideLogConsole();
  }

  public onResizeEnd($event) {
    this.updateLogHeight();
    this.scrollSelected();
  }

  public handleClose($event) {
    this.removing = true;

    this.loggerService.unsubscribeFromLog($event.index)
  }

  public handleChange($event) {
    this.selectedTabIndex = $event.index;

    this.scrollSelected();
    this.storeSelectedIndex();
  }

  //#endregion
}