import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Observer, Subject, timer } from 'rxjs';
import { AuthService, environment, settings } from '../../../projects/shared/src/public-api';
import { LogSourceTypeMap, SubscribeRequest, SubscribeResponse } from '../grpc/logger_pb';
import { ResponseStream, LoggerClient } from '../grpc/logger_pb_service';
import { map, take } from 'rxjs/operators';
import { LogMessage } from './models/log-message.model';
import { grpc } from '@improbable-eng/grpc-web';
import { Message } from 'primeng-lts/api';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private subscriptions$: BehaviorSubject<LogSubscription[]> = new BehaviorSubject<LogSubscription[]>([]);
  private loggerClient: LoggerClient;

  private loggerIndexChanges: Subject<any> = new Subject<any>();
  private storage: Storage;
  private grpcMetadata: grpc.Metadata;
  private suspendStreaming: boolean;

  constructor(private authService: AuthService) {
    this.loggerClient = new LoggerClient(environment.config.api_hostname);
    this.grpcMetadata = new grpc.Metadata();
    this.storage = window.localStorage;

    this.suspendStreaming = false;
    this.initFromStorage();

    this.authService.getAuthenticationExpiredEvent().subscribe((result: Message) => {
      this.suspendStreaming = true;
    });

    this.authService.getAuthenticationSuccessEvent().subscribe((result: Message) => {
      this.suspendStreaming = false;
      this.initFromStorage();
    });
  }

  //#region Members :: subscribeToLog(), unsubscribeFromLog()

  public subscribeToLog(id: string, description: string, logSourceType: LogSourceTypeMap[keyof LogSourceTypeMap], clientId: string = null): LogSubscription {

    let subscriptionIndex = this.subscriptions$.value.findIndex(s => s.Id == id);

    if (subscriptionIndex < 0) {

      let logSubscription = new LogSubscription();

      logSubscription.Id = id;
      logSubscription.Description = description;
      logSubscription.SourceType = logSourceType;
      logSubscription.ClientId = clientId;
      logSubscription.LogStream$ = null;

      this.makeSubscription(logSubscription, logSourceType);
      subscriptionIndex = this.subscriptions$.value.push(logSubscription);

      this.setToStorage();

      this.loggerIndexChanges.next((subscriptionIndex - 1));

      return logSubscription;
    }
    else {
      if (subscriptionIndex > 0) {
        this.loggerIndexChanges.next(subscriptionIndex);

        return this.subscriptions$.value[subscriptionIndex];
      }
    }
  }

  public unsubscribeFromLog(index: number): void {

    let subscription = this.subscriptions$.value[index];

    if (subscription) {

      let newSelectedIndex = 0;

      if (index > 0) {
        newSelectedIndex = index - 1;
      }

      subscription?.Subscription.cancel();
      this.subscriptions$.value.splice(index, 1);

      this.setToStorage();
      let key = subscription.Id as any;

      this.storage.setItem('loggerHistory_' + key.replaceAll('-', '_'), '[]');
      this.loggerIndexChanges.next(newSelectedIndex);
    }
  }

  //#endregion

  //#region Members 'Private' :: initFromStorage(), setToStorage(), makeSubscription()

  private initFromStorage() {
    let loggerValue = this.storage.getItem('loggerSubscriptions');

    if (loggerValue) {
      let logger = JSON.parse(loggerValue);
      let subscriptions = logger['subscriptions'];

      subscriptions.forEach(subscription => {
        this.subscribeToLog(subscription.Id, subscription.Description, subscription.SourceType, subscription.ClientId);
      });
    }
  }

  private setToStorage() {

    let logger = { subscriptions: [] };

    this.subscriptions$.value.forEach(subscription => {
      logger.subscriptions.push({ Id: subscription.Id, Description: subscription.Description, SourceType: subscription.SourceType, ClientId: subscription.ClientId });
    });

    this.storage.setItem('loggerSubscriptions', JSON.stringify(logger));
  }

  private makeSubscription(logSubscription: LogSubscription, logSourceType: LogSourceTypeMap[keyof LogSourceTypeMap]) {

    this.grpcMetadata.set('Authorization', this.authService.getAuthorizationHeader());

    let self = this;

    if (!logSubscription.ClientId) {
      logSubscription.ClientId = settings.GuidGenerator.newGuid();
    }

    let request = new SubscribeRequest();

    request.setClientid(logSubscription.ClientId);
    request.setSourceid(logSubscription.Id);
    request.setSourcetype(logSourceType)

    let subscription = this.loggerClient.subscribe(request, this.grpcMetadata);

    subscription.on('data', (response: SubscribeResponse) => {
      let obj = response.toObject();
      let ngxLogs = [];

      obj.logsList.forEach(log => {
        let ngxLog = { message: log.text, timestamp: log.datetime, type: this.getLogTypeFromLevel(log.level) };
        ngxLogs.push(ngxLog);
      });

      logSubscription.LogStream$ = timer(0, 100).pipe(
        take(ngxLogs.length),
        map(i => ngxLogs[i])
      );
    })
      .on('status', (error) => {
        logSubscription.Subscription = null;

        if (!this.suspendStreaming) {
          setTimeout(function () {
            self.makeSubscription(logSubscription, logSourceType);
          }, 2000);
        }
      })
      .on('end', () => {

      });

    logSubscription.Subscription = subscription;
  }

  //#endregion

  //#region Members :: getSubscriptions(), getNewSubscriptionsObservable(), getLogTypeFromLevel()

  public getSubscriptions$(): Observable<LogSubscription[]> {
    return this.subscriptions$;
  }

  public loggerIndexChangesObservable() {
    return this.loggerIndexChanges.asObservable();
  }

  private getLogTypeFromLevel(logLevel: number): 'LOG' | 'INFO' | 'WARN' | 'ERR' | 'SUCCESS' {
    switch (logLevel) {
      case 0:
        return 'LOG';
      case 1:
        return 'SUCCESS';
      case 2:
        return 'INFO';
      case 3:
        return 'WARN';
      case 4:
      case 5:
        return 'ERR';
    }

    return 'LOG';
  }

  //#endregion
}

export class LogSubscription {
  Id: string;
  Description: string;
  SourceType: number;
  Subscription: ResponseStream<SubscribeResponse>;
  LogStream$: Observable<LogMessage>;
  ClientId: string;
}
