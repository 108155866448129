import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClientService, FormBase, FormButton, IFormBase, Tag } from '../../../../../projects/shared/src/public-api';

let self;
@Component({
  selector: 'app-tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.css'],
  providers: [FormBuilder,
    {
      provide: FormBase,
      useExisting: forwardRef(() => TagFormComponent)
    }]
})
export class TagFormComponent extends FormBase implements OnInit, IFormBase{
  @Output() alert: EventEmitter<object> = new EventEmitter<object>();
  @Output() formButtons: EventEmitter<FormButton[]> = new EventEmitter<FormButton[]>();
  @Input() selectedTag: Tag;

  public tagForm: FormGroup;
  moduleIsReady: boolean = true;

  constructor(private formBuilder: FormBuilder, private httpClientService: HttpClientService){
    super();
    self = this;
  }

  isValid(): boolean {
    return !this.tagForm.invalid;
  }

  

  getFormButtons(): FormButton[] {
    return [{
      class: 'text-dark text-center link-button',
      description: 'Cancelar alterações',
      icon: 'fas fa-redo',
      size: 4,
      type: 'link',
      formValidator: this.tagForm,
      isCloseButton: true,
      callback: this.onCancel
    }, {
      class: 'btn btn-success btn-block',
      description: 'Salvar',
      icon: 'fas fa-times',
      size: 6,
      type: 'submit',
      formValidator: this.tagForm,
      isCloseButton: false,
      callback: this.onSubmit
    }];
  }

  ngOnInit(): void {
    this.createForm();

    this.formButtons.emit(this.getFormButtons());
  }

  public createForm() {
    this.tagForm = this.formBuilder.group({
      description: ['', Validators.required],
      address: ['', Validators.required],
      name: ['', Validators.required],
      enabled: [true, Validators.required],
      scanRate: [1000, Validators.required],
      type: [1, Validators.required],
      accessType: [1, Validators.required],
      dataType: [1, Validators.required],
      scalingType: [1, Validators.required],
    });
    
    this.updateForm();
  }

  public updateForm() {
    this.tagForm.patchValue({
      name: this.selectedTag.Name,
      description: this.selectedTag.Description,
      address: this.selectedTag.Address,
      enabled: this.selectedTag.Enabled,
      scanRate: this.selectedTag.ScanRate,
      type: this.selectedTag.Type,
      accessType: this.selectedTag.AccessType,
      dataType: this.selectedTag.DataType,
      scalingType: this.selectedTag.ScalingType,
    });
  }

  public onSubmit() {
    self.save();
  }

  public onCancel() {
    self.alert.emit({
      instance: 'cancel',
      isPristine: self.tagForm.pristine,
    });
  }
  
  public save(){
    let result = this.tagForm.value;
    result.Id = this.selectedTag.Id;

    let castTagGroup: any =  this.selectedTag;
    result.TagGroupId = castTagGroup.TagGroupId;

    this.httpClientService.SendToAPI('Tag', 'Save', result).subscribe(res => {
      if (res == true) {
        this.tagForm.markAsPristine();
        
        this.alert.emit({
          title: 'Sucesso!',
          type: 'success',
          message: 'Registro inserido com sucesso.',
        });

      }
    }, error => {
      this.alert.emit({
        title: 'Erro!',
        type: 'danger',
        message: 'Não foi possivel inserir o registro.',
      });

    });
  }
}