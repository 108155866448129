<form [formGroup]="tagForm">
    <ol class="nav nav-pills" ngbNav #pill="ngbNav">
        <li class="nav-item" ngbNavItem>
            <a href="javascript:;" class="nav-link" ngbNavLink>
                <div class="nav-title">Dados da variável</div>
            </a>
            <ng-template ngbNavContent>
                <fieldset>
                  <legend>Configurações gerais</legend>
                  <div class="row">
                      <div class="form-group col-md-6">
                          <label for="tagDescription">Descrição</label>
                          <input type="text" class="form-control" id="tagDescription" formControlName="description" />
                      </div>
                      <div class="form-group col-md-2">
                          <label>Está ativo?</label>
                          <div class="checkbox checkbox-css">
                              <input type="checkbox" id="tagEnabled" formControlName="enabled" />
                              <label for="tagEnabled">Sim</label>
                          </div>
                      </div>
                      <div class="form-group col-md-4">
                          <label for="tagScanRate">Tempo de varredura (ms)</label>
                          <input type="number" class="form-control" id="tagScanRate" formControlName="scanRate" />
                      </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Declaração da variável</legend>
                  <div class="row">
                      <div class="form-group col-md-12">
                          <label for="tagName">Nome</label>
                          <input type="text" class="form-control" id="tagName" formControlName="name" />
                      </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-3">
                      <label for="tagDataType">Tipo de dado</label>
                        <select class="form-control" id="tagDataType" formControlName="dataType">
                            <option value="0">Boolean</option>
                            <option value="1">SByte</option>
                            <option value="2">Byte</option>
                            <option value="3">Int16</option>
                            <option value="4">UInt16</option>
                            <option value="5">Int32</option>
                            <option value="6">UInt32</option>
                            <option value="7">Int64</option>
                            <option value="8">UInt64</option>
                            <option value="9">Float</option>
                            <option value="10">Double</option>
                            <option value="11">DateTime</option>
                            <option value="12">Id</option>
                            <option value="13">ByteString</option>
                        </select>
                      </div>
                    <div class="form-group col-md-3">
                        <label for="tagAccessType">Tipo de acesso</label>
                        <select class="form-control" id="tagAccessType" formControlName="accessType">
                            <option value="0">Somente leitura</option>
                            <option value="1">Leitura e Escrita</option>
                        </select>
                    </div>
                      <div class="form-group col-md-6">
                          <label for="tagAddress">Endereço</label>
                          <input type="text" class="form-control" id="tagAddress" formControlName="address" />
                      </div>
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Transformações</legend>
                  <div class="row">
                      <div class="form-group col-md-4">
                          <label for="tagType">Tipo da tag
                          </label>
                          <select class="form-control" id="tagType" formControlName="type">
                              <option value="0">Valor</option>
                              <option value="1">Média</option>
                              <option value="2">Soma</option>
                              <option value="3">Tipo complexo</option>
                          </select>
                      </div>

                      <div class="form-group col-md-4">
                          <label for="tagScalingType">Tipo de escala
                          </label>
                          <select class="form-control" id="tagScalingType" formControlName="scalingType">
                              <option value="0">Nenhuma</option>
                              <option value="1">Linear</option>
                              <option value="2">Raiz quadrada</option>
                          </select>
                      </div>
                  </div>
              </fieldset>
            </ng-template>
        </li>
    </ol>

    <div class="tab-content p-3" [ngbNavOutlet]="pill">
    </div>
</form>
