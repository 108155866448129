<div class="modal-header bg-blue-darker">
    <h4 class="modal-title text-white">Copiar variáveis de um dispositivo</h4>
    <button type="button" class="close" (click)="onCancel()">×</button>
</div>
<div class="modal-body" style="padding-top: 0;">


    <p-pickList [source]="sourceTags" [target]="targetTags" sourceHeader="Disponíveis" targetHeader="Selecionadas" [dragdrop]="true" [responsive]="true" [sourceStyle]="{'height':'30rem'}" [targetStyle]="{'height':'30rem'}" filterBy="Description" sourceFilterPlaceholder="Digite um filtro"
        targetFilterPlaceholder="Digite um filtro">

        <ng-template pTemplate="sourceHeader">
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><i class="pi pi-tags" style="line-height: 1.25;"></i></span>
                <span style="width:100%;padding:8px; background-color:#fff;border:1px solid #ced4da;border-radius:3px;">{{selectedDevice.Description}}</span>
            </div>
        </ng-template>

        <ng-template pTemplate="targetHeader">
            <form [formGroup]="deviceForm">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="pi pi-tags" style="line-height: 1.25;"></i></span>
                    <p-dropdown [options]="devices" formControlName="device" dataKey="Id" [autoDisplayFirst]="true" optionLabel="Description" [style]="{'width':'23.5rem'}"></p-dropdown>
                </div>
            </form>
        </ng-template>

        <ng-template let-tag pTemplate="item">
            <i class="pi pi-tag" style="line-height: 1.25;"></i> {{tag.Description}}
        </ng-template>
    </p-pickList>

</div>
<div class="modal-footer" *ngIf="moduleIsReady">
    <div class="row" style="width: 100%;">
        <div class="col-lg-2">
            <a href="#" (click)="onCancel()" class="footer-cancel-link modal-footer-cancel-link">
                <i class="fas fa-redo"></i>&nbsp; Cancelar e fechar
            </a>
        </div>
        <div class="col-lg-5"></div>
        <div class="col-lg-5">
            <button type="submit" (click)="onSubmit()" class="btn btn-success btn-block" [disabled]="!isValid()">Salvar</button>
        </div>
    </div>
</div>