import { ModuleViewBase } from "../module-view-base";
import { IIdentifiable } from "./IIdentifiable";

/** Channel */
export class Channel extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id channeltype */
    ChannelTypeId: string;

    /** Id channel */
    ParentChannelId: string;

    /** Ordem do channel */
    Order: number;

    /** Descrição do channel */
    Description: string;

    /** Nome do channel */
    Name: string;

    /** Habilitado do channel */
    Enabled: boolean;

    /** Taxa de varredura */
    ScanRate: number;

    /** Tempo esgotado */
    Timeout: number;

    /** Observações do channel */
    Note: string;

    /** Definições do channel */
    Settings: string;

    /** Nível de registro */
    LogLevel: number;

    /** Registro de salvamento ativado */
    LogSaveEnabled: boolean;

    /** Caminho para salvar o registro */
    LogSavePath: string;

    /** ChannelType */
    ChannelType: ChannelType;

    /** Canal pai */
    ParentChannel: Channel;

    /** Canais filhos */
    ChildChannels: Channel[];

    /** Devices */
    Devices: Device[];
}

/** ChannelType */
export class ChannelType extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Código do channeltype */
    Code: string;

    /** Nome da classe */
    ClassName: string;

    /** Descrição do channeltype */
    Description: string;

    /** Descrição do grupo */
    GroupDescription: string;

    /** Tecla de ícone */
    IconKey: string;

    /** Channels */
    Channels: Channel[];

    /** DeviceTypes */
    DeviceTypes: DeviceType[];
}

/** Device */
export class Device extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id channel */
    ChannelId: string;

    /** Id devicetype */
    DeviceTypeId: string;

    /** Id drivertype */
    DriverTypeId: string;

    /** Id device */
    ParentDeviceId: string;

    /** Pedido do device */
    Order: number;

    /** Descrição do device */
    Description: string;

    /** Nome do device */
    Name: string;

    /** Habilitado do device */
    Enabled: boolean;

    /** Taxa de varredura */
    ScanRate: number;

    /** Tempo esgotado */
    Timeout: number;

    /** Executar síncrono */
    RunSynchronous: boolean;

    /** Observações do device */
    Note: string;

    /** Definições do device */
    Settings: string;

    /** Nível de registro */
    LogLevel: number;

    /** Registro de salvamento ativado */
    LogSaveEnabled: boolean;

    /** Caminho para salvar o registro */
    LogSavePath: string;

    /** Channel */
    Channel: Channel;

    /** DeviceType */
    DeviceType: DeviceType;

    /** DriverType */
    DriverType: DriverType;

    /** Dispositivo pai */
    ParentDevice: Device;

    /** Dispositivos filhos */
    ChildDevices: Device[];

    /** TagGroups */
    TagGroups: TagGroup[];

    /** Tags */
    Tags: Tag[];
}

/** DeviceType */
export class DeviceType extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id channeltype */
    ChannelTypeId: string;

    /** Código do devicetype */
    Code: string;

    /** Nome da classe */
    ClassName: string;

    /** Descrição do devicetype */
    Description: string;

    /** Descrição do grupo */
    GroupDescription: string;

    /** Tecla de ícone */
    IconKey: string;

    /** ChannelType */
    ChannelType: ChannelType;

    /** Devices */
    Devices: Device[];

    /** DriverTypes */
    DriverTypes: DriverType[];
}

/** DiagnosticMessage */
export class DiagnosticMessage extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id da fonte */
    SourceId: string;

    /** Modelo do diagnosticmessage */
    Type: number;

    /** Estado do diagnosticmessage */
    State: number;

    /** Mensagem do diagnosticmessage */
    Message: string;

    /** Detalhes do diagnosticmessage */
    Details: string;

    /** Data da primeira ocorrência hora */
    FirstOccurrenceDateTime: Date;

    /** Data da última ocorrência hora */
    LastOccurrenceDateTime: Date;

    /** Reiniciar data e hora */
    ResetDateTime: Date;

    /** Contar do diagnosticmessage */
    Count: number;

    /** Cerquilha do diagnosticmessage */
    Hash: string;
}

/** DriverType */
export class DriverType extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id devicetype */
    DeviceTypeId: string;

    /** Código do drivertype */
    Code: string;

    /** Nome da classe */
    ClassName: string;

    /** Descrição do drivertype */
    Description: string;

    /** Descrição do grupo */
    GroupDescription: string;

    /** Tecla de ícone */
    IconKey: string;

    /** DeviceType */
    DeviceType: DeviceType;

    /** Devices */
    Devices: Device[];
}

/** Role */
export class Role extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Código do role */
    Code: string;

    /** Descrição do role */
    Description: string;

    /** Nível do role */
    Level: number;

    /** UserRoles */
    UserRoles: UserRole[];
}

/** Service */
export class Service extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id servicetype */
    ServiceTypeId: string;

    /** Id service */
    ParentServiceId: string;

    /** Pedido do service */
    Order: number;

    /** Descrição do service */
    Description: string;

    /** Habilitado do service */
    Enabled: boolean;

    /** Taxa de varredura */
    ScanRate: number;

    /** Tempo esgotado */
    Timeout: number;

    /** Comece imediatamente */
    StartImmediately: boolean;

    /** Tempo máximo de pausa */
    PauseMaxTime: number;

    /** Observações do service */
    Note: string;

    /** Definições do service */
    Settings: string;

    /** Nível de registro */
    LogLevel: number;

    /** Registro de salvamento ativado */
    LogSaveEnabled: boolean;

    /** Caminho para salvar o registro */
    LogSavePath: string;

    /** Serviço pai */
    ParentService: Service;

    /** ServiceType */
    ServiceType: ServiceType;

    /** Serviços filhos */
    ChildServices: Service[];
}

/** ServiceType */
export class ServiceType extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Código do servicetype */
    Code: string;

    /** Nome da classe */
    ClassName: string;

    /** Descrição do servicetype */
    Description: string;

    /** Descrição do grupo */
    GroupDescription: string;

    /** Descrição do trabalho */
    JobDescription: string;

    /** Tecla de ícone */
    IconKey: string;

    /** Services */
    Services: Service[];
}

/** SystemInterface */
export class SystemInterface extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id systemsetting */
    SystemSettingId: string;

    /** Tipo de interface */
    InterfaceType: number;

    /** Nome da interface */
    InterfaceName: string;

    /** Modo de operação */
    OperationMode: number;

    /** Tipo de conexão ipv4 */
    Ipv4ConnectionType: number;

    /** Tipo de conexão ipv6 */
    Ipv6ConnectionType: number;

    /** SystemSetting */
    SystemSetting: SystemSetting;

    /** SystemInterfaceNetworks */
    SystemInterfaceNetworks: SystemInterfaceNetwork[];

    /** SystemInterfaceSettings */
    SystemInterfaceSettings: SystemInterfaceSetting[];
}

/** SystemInterfaceNetwork */
export class SystemInterfaceNetwork extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id systeminterface */
    SystemInterfaceId: string;

    /** Nome da rede */
    NetworkName: string;

    /** Tipo de segurança */
    SecurityType: number;

    /** Versão de segurança */
    SecurityVersion: number;

    /** Criptografia de segurança */
    SecurityEncryption: number;

    /** Senha do systeminterfacenetwork */
    Password: string;

    /** Canal do systeminterfacenetwork */
    Channel: number;

    /** Banda do systeminterfacenetwork */
    Band: number;

    /** SystemInterface */
    SystemInterface: SystemInterface;
}

/** SystemInterfaceSetting */
export class SystemInterfaceSetting extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id systeminterface */
    SystemInterfaceId: string;

    /** Tipo de endereço */
    AddressType: number;

    /** Endereço de ip */
    IpAddress: string;

    /** Máscara de sub-rede */
    SubnetMask: string;

    /** Gateway padrão */
    DefaultGateway: string;

    /** Dns primário */
    PrimaryDns: string;

    /** Dns secundário */
    SecondaryDns: string;

    /** SystemInterface */
    SystemInterface: SystemInterface;
}

/** SystemSetting */
export class SystemSetting extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Nível de registro do service manager */
    ServiceManagerLogLevel: number;

    /** Salvamento de log do service manager ativado */
    ServiceManagerLogSaveEnabled: boolean;

    /** Caminho para salvar o log do service manager */
    ServiceManagerLogSavePath: string;

    /** Nível de registro do comm manager */
    CommManagerLogLevel: number;

    /** Salvamento de log do comm manager ativado */
    CommManagerLogSaveEnabled: boolean;

    /** Caminho para salvar o registro do comm manager */
    CommManagerLogSavePath: string;

    /** Observações do systemsetting */
    Note: string;

    /** Endereço da api externa */
    ExternalApiAddress: string;

    /** SystemInterfaces */
    SystemInterfaces: SystemInterface[];
}

/** Tag */
export class Tag extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id device */
    DeviceId: string;

    /** Id taggroup */
    TagGroupId: string;

    /** Pedido do tag */
    Order: number;

    /** Nome do tag */
    Name: string;

    /** Endereço do tag */
    Address: string;

    /** Descrição do tag */
    Description: string;

    /** Taxa de varredura */
    ScanRate: number;

    /** Habilitado do tag */
    Enabled: boolean;

    /** Definições do tag */
    Settings: string;

    /** Modelo do tag */
    Type: number;

    /** Tipo de dados */
    DataType: number;

    /** Tipo de acesso */
    AccessType: number;

    /** Tipo de escala */
    ScalingType: number;

    /** Device */
    Device: Device;

    /** TagGroup */
    TagGroup: TagGroup;
}

/** TagGroup */
export class TagGroup extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id device */
    DeviceId: string;

    /** Id taggroup */
    ParentTagGroupId: string;

    /** Nome do taggroup */
    Name: string;

    /** Descrição do taggroup */
    Description: string;

    /** Pedido do taggroup */
    Order: number;

    /** Habilitado do taggroup */
    Enabled: boolean;

    /** Taxa de varredura */
    ScanRate: number;

    /** Device */
    Device: Device;

    /** TagGroup */
    ParentTagGroup: TagGroup;

    /** TagGroups */
    ChildTagGroups: TagGroup[];

    /** Tags */
    Tags: Tag[];
}

/** User */
export class User extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Nome dado */
    GivenName: string;

    /** Sobrenome do user */
    SurName: string;

    /** Nome do usuário */
    Username: string;

    /** Senha do user */
    Password: string;

    /** Habilitado do user */
    Enabled: boolean;

    /** UserRoles */
    UserRoles: UserRole[];
}

/** UserRole */
export class UserRole extends ModuleViewBase implements IIdentifiable<string>
{
    /** Id registro */
    Id: string;

    /** Id user */
    UserId: string;

    /** Id role */
    RoleId: string;

    /** Role */
    Role: Role;

    /** User */
    User: User;
}

