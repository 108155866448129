var pageMenus = [{
  'icon': 'fa fa-list',
  'title': 'Serviços configurados',
  'url': 'service/list',
  // 'caret': 'true',
  // 'submenu': [{
  //   'url': 'dashboard/v1',
  //   'title': 'Dashboard v1'
  // },{
  //   'url': 'dashboard/v2',
  //   'title': 'Dashboard v2'
  // },{
  //   'url': 'dashboard/v3',
  //   'title': 'Dashboard v3'
  // }]
}, {
  'icon': 'fa fa-plug',
  'title': 'Canais de comunicação',
  'url': 'channel/list',
  'caret': 'true',
  'addButton': {'title': 'Adicionar novo canal'},
  'type': 'treeview',
  // 'badge': '10',
  'submenu': [{
    'url': 'device/list',
    'title': 'OPC UA Channel',
    'caret': 'true',
    'addButton': {'title': 'Adicionar novo dispositivo'},
    'submenu': [{
      'url': 'devices/inbox',
      'title': 'Dispositivo 1',
      'caret': 'true',
      'addButton': {'title': 'Adicionar novo agrupamento'},
      'submenu': [{
        'url': 'grouptags/inbox',
        'title': 'Agrupamento 1',
        'caret': 'true',
        'submenu': [{
          'url': 'tags/inbox',
          'title': 'Variável 1'
        }, {
          'url': 'tags/inbox',
          'title': 'Variável 2'
        }],
        'addButton': {'title': 'Adicionar nova variável'}
      }, {
        'url': 'grouptags/inbox',
        'title': 'Agrupamento 2',
        'caret': 'true',
        'submenu': [{
          'url': 'tags/inbox',
          'title': 'Variável 1'
        }, {
          'url': 'tags/inbox',
          'title': 'Variável 2'
        }],
        'addButton': {'title': 'Adicionar novo variável'}
      }]
    }, {
      'url': 'devices/inbox',
      'title': 'Dispositivo 2',
      'caret': 'true',
      'submenu': [{
        'url': 'grouptags/inbox',
        'title': 'Agrupamento 1'
      }, {
        'url': 'grouptags/inbox',
        'title': 'Agrupamento 2'
      }]
    }]
  }, {
    'url': 'channels/inbox',
    'title': 'PostgreSQL',
    'caret': 'true',
    'submenu': [{
      'url': 'devices/inbox',
      'title': 'Dispositivo 1',
      'caret': 'true',
      'submenu': [{
        'url': 'grouptags/inbox',
        'title': 'Agrupamento 1'
      }, {
        'url': 'grouptags/inbox',
        'title': 'Agrupamento 2'
      }]
    }, {
      'url': 'devices/inbox',
      'title': 'Dispositivo 2',
      'caret': 'true',
      'submenu': [{
        'url': 'grouptags/inbox',
        'title': 'Agrupamento 1'
      }, {
        'url': 'grouptags/inbox',
        'title': 'Agrupamento 2'
      }]
    }]
  }]
},
// {
//   'icon': 'fab fa-simplybuilt',
//   'title': 'Widgets',
//   // 'label': 'NEW',
//   'url': 'widget'
// },
{
  'icon': 'fa fa-comment',
  'title': 'Log de execução',
  'url': '',
  // 'label': 'NEW',
}];

export default pageMenus;