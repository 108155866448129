import { Component, OnInit, Input, ViewContainerRef, forwardRef, ViewChild } from '@angular/core';
import { SystemJsLoaderService } from '../../../core/system-js-loader.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChannelEditComponent } from '../../channel/channel-edit/channel-edit.component';
import { Device, Channel, FormBase, FormButton, IFormBase, HttpClientService, DeviceType, ChannelType, settings } from '../../../../../projects/shared/src/public-api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { MessageService, SelectItemGroup } from 'primeng-lts/api';

@Component({
  selector: 'app-device-edit',
  templateUrl: './device-edit.component.html',
  styleUrls: ['./device-edit.component.css'],
  providers: [{ provide: FormBase, useExisting: DeviceEditComponent }]
})
export class DeviceEditComponent extends FormBase implements OnInit, IFormBase {

  @Input() selectedId: string;
  @Input() entityTypeId: string;
  @Input() isNew: boolean;

  @ViewChild('deviceModuleView', { read: ViewContainerRef })
  private anchorViewContainer: ViewContainerRef;

  public selectedDevice: Device;

  public deviceTypes: DeviceType[] = [];
  public channelTypes: SelectItemGroup[] = [];

  public channels: Channel[] = [];
  public selectedChannel: Channel;

  public selectedFormBase: FormBase;
  public channelForm: FormGroup;
  selectedDeviceType: any;
  selectedDeviceDescription: string;


  constructor(private readonly systemJsLoader: SystemJsLoaderService, private httpClientService: HttpClientService, private modalService: NgbModal, private formBuilder: FormBuilder, private messageService: MessageService) {
    super();

    this.createForm();
  }

  //#region OnInit 

  ngOnInit() {

    if (this.entityTypeId) {
      this.selectedDeviceDescription = "Novo dispositivo";
      this.callNew(this.entityTypeId);
    }
    else if (this.selectedId) {
      this.callEdit(this.selectedId);
    }
  }

  //#endregion

  //#region Members :: callEdit(), callNew(), openView()

  private callEdit(deviceId: string) {
    let params = 'id=' + deviceId;

    this.httpClientService.RequestFromAPI('Device', '', params).subscribe((device: Device) => {

      if (this.isNew) {
        this.selectedId = null;
        device.Id = settings.GUID_EMPTY;
        device.Description = '(Copy of) ' + device.Description;
      }

      this.selectedDeviceDescription = device.Description;

      this.openView(device);
    }, error => console.error(error));
  }

  private callNew(deviceTypeId: string) {
    let params = 'deviceTypeId=' + deviceTypeId;

    this.httpClientService.RequestFromAPI('Device', 'New', params).subscribe(device => {
      this.openView(device);
    }, error => console.error(error));
  }

  private openView(device: Device) {
    this.selectedDevice = device;
    this.loadChannels().then(response => {
      if (response) {
        this.selectedChannel = this.channels.find(channel => channel.Id == this.selectedDevice.ChannelId);
        this.updateForm();

        var moduleName = device.ModuleName;
        var moduleNamespace = device.ModuleNamespace;
        var moduleEntryName = device.ModuleEntryName;
        var componentName = device.ComponentName;

        this.moduleIsReady = false;

        this.systemJsLoader.load(moduleName, moduleNamespace, moduleEntryName, componentName).then(componentFactory => {

          let componentInstance = this.anchorViewContainer.createComponent(componentFactory);
          this.selectedFormBase = componentInstance.instance as IFormBase;

          device.Channel = this.selectedChannel;
          this.selectedFormBase['selectedDevice'] = device;
          // this.selectedFormBase['editEntityEventEmitter'].subscribe(channel => {
          //   this.editChannel(channel);
          // });
          // this.selectedFormBase['newEntityEventEmitter'].subscribe(deviceTypeId => {
          //   this.newChannel();
          // });

          this.moduleIsReady = true;
        });
      }
    });
  }

  //#endregion

  //#region Forms :: createForm(), updateForm()

  createForm() {
    this.channelForm = this.formBuilder.group({ channel: [null, Validators.required] });
  }

  updateForm() {
    this.channelForm.patchValue({ channel: this.selectedChannel });
  }

  //#endregion

  //#region Members :: loadChannels(), changeSelectedChannel()

  loadChannels(): Promise<any> {

    let params = 'deviceTypeId=' + this.selectedDevice.DeviceTypeId;

    let promise = new Promise((resolve, reject) => {
      this.httpClientService.RequestFromAPI('Channel', 'Filter', params).subscribe(result => {
        this.channels = result;
        resolve(true);
      }, error => {
        console.error(error);
        reject(false);
      });
    });

    return promise;
  }

  public changeSelectedChannel(selectedValue) {
    this.selectedChannel = selectedValue;
    this.selectedFormBase['selectedDevice'].Channel = this.selectedChannel;
  }

  //#endregion

  //#region IFormBase :: onSubmit(), onCancel(), isValid()

  public onSubmit(): Promise<any> {

    let promise = new Promise((resolve, reject) => {

      let entity = this.selectedFormBase.onSubmit();

      if (entity) {

        entity.Id = this.selectedDevice.Id;
        entity.DeviceTypeId = this.selectedDevice.DeviceTypeId;
        entity.ChannelId = this.selectedChannel.Id;

        let tags = entity.Tags;
        entity.Tags = [];
        entity.Devices = [];

        entity.Channel = undefined;
        entity.DeviceType = undefined;

        entity.Settings = JSON.stringify(entity);
        entity.Tags = tags;

        console.debug(entity);

        this.httpClientService.SendToAPI('Device', 'Save', entity).subscribe(result => {
          if (result) {
            this.selectedFormBase.resetForm();

            resolve({
              title: 'Sucesso!',
              type: 'success',
              message: 'Registro salvo com sucesso!',
              closeAfter: 100
            });
          }
        }, error => {
          reject({
            title: 'Erro!',
            type: 'danger',
            message: 'Não foi possivel salvar o registro.',
          });
        });
      }
    });

    return promise;
  }

  public onCancel(): any {
    if (this.selectedFormBase) {
      let isPristine = this.selectedFormBase.onCancel();

      return isPristine && this.channelForm.pristine;
    }
    return true;
  }

  public isValid(): boolean {
    return this.selectedFormBase?.isValid() && !this.channelForm.invalid;
  }

  //#endregion

  //#region Add/Edit Events :: newChannel(), editChannel()

  public newChannel() {
    const activeModal = this.modalService.open(ChannelEditComponent, { size: 'xl' });
    activeModal.componentInstance.selectedId = this.selectedDevice?.DeviceTypeId;
    activeModal.componentInstance.isNew = true;

    activeModal.result.then((channel: Channel) => {
      this.loadChannels().then(res => {
        this.selectedChannel = channel;
        this.updateForm();
      });
    },
      (reason) => {
        console.log(`Dismissed ${reason}`);
      });
  }

  public editChannel() {

    let channel = this.channelForm.get('channel').value;

    const activeModal = this.modalService.open(ChannelEditComponent, { size: 'xl' });
    activeModal.componentInstance.selectedId = channel.Id;
    activeModal.componentInstance.isNew = false;

    activeModal.result.then((channel: Channel) => {

      this.loadChannels().then(res => {
        this.selectedChannel = channel;
        this.updateForm();
      });
    },
      (reason) => {
        console.log(`Dismissed ${reason}`);
      });
  }

  public deleteChannel() {
    let channel = this.channelForm.get('channel').value;
    let self = this;

    Swal.fire({
      title: 'Confirma a exclusão do canal?',
      text: 'Todas as configurações serão perdidas e não poderão ser recuperadas!',
      icon: 'error',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#00acac',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#ff5b57',
      focusCancel: true
    }).then(function (result: SweetAlertResult) {
      if (result.isConfirmed) {
        self.confirmDelete(channel);
      }
    });
  }

  private confirmDelete(channel: Channel) {
    let params = 'ids[]=' + channel.Id;

    this.httpClientService.RequestFromAPI('Channel', 'Delete', params).subscribe(result => {
      if (result) {
        this.loadChannels();
        this.messageService.add({ key: 'delete_success', severity: 'success', summary: 'Registro excluído', detail: 'Registro excluído com sucesso!', life: 1500 });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erro ao excluir', detail: 'Não foi possível excluir o registro!' });
      }

    }, error => console.error(error));
  }

  //#endregion
}
