import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { grpc } from '@improbable-eng/grpc-web';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadEvent, MenuItem, MessageService } from 'primeng-lts/api';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { LogSourceType, LogSourceTypeMap } from '../../../grpc/logger_pb';
import { RefreshTagsRequest, RefreshTagsResponse } from '../../../grpc/systemManager_pb';
import { SystemManagerClient, ResponseStream } from '../../../grpc/systemManager_pb_service';
import { LoggerService } from '../../../services/logger.service';
import { TagsCopyComponent } from '../tags-copy/tags-copy.component';
import { TagBatchCreationComponent } from '../tag-batch-creation/tag-batch-creation.component';
import { AuthService, Device, environment, HttpClientService, Tag, TagDataTypeLabel } from '../../../../../projects/shared/src/public-api';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.css']
})

export class TagListComponent implements OnInit, OnDestroy {
  @Output() openSidebarEvent = new EventEmitter<object>();

  private smartComGrpcClient: SystemManagerClient;
  public tags: Tag[] = [];
  public selectedTags: Tag[] = [];
  unsubscribeRefresh: ResponseStream<RefreshTagsResponse>;
  rowGroupMetadata: any[] = [];
  totalRecords: number = 0;
  loading: boolean = false;
  selectedTag: Tag;
  selectedDevice: Device;
  items: MenuItem[] = [];
  expandedRows: any[] = [];
  deviceItems: MenuItem[] = [];
  grpcMetadata: grpc.Metadata;
  canRetry: boolean;

  constructor(private modalService: NgbModal, public viewContainerRef: ViewContainerRef, private httpClientService: HttpClientService, private messageService: MessageService, private changeDetectorRef: ChangeDetectorRef, private loggerService: LoggerService, private authService: AuthService) {
    this.smartComGrpcClient = new SystemManagerClient(environment.config.api_hostname);

    this.grpcMetadata = new grpc.Metadata();
  }

  async ngOnInit() {
    this.loading = true;
    this.canRetry = true;

    this.items = [
      { label: 'Copiar', icon: 'pi pi-fw pi pi-copy', command: () => this.copyTag(this.selectedTag) },
      { label: 'Editar', icon: 'pi pi-fw pi pi-pencil', command: () => this.editTag(this.selectedTag) },
      { label: 'Excluir', icon: 'pi pi-fw pi-trash', command: () => this.deleteTag(this.selectedTag) }
    ];

    this.deviceItems = [
      { label: 'Ver log', icon: 'pi pi-fw pi pi-list', styleClass: '', command: () => this.showLog(this.selectedDevice, LogSourceType.SOURCETYPEDEVICE) },
      { label: 'Copiar variáveis', icon: 'pi pi-fw pi pi-copy', command: ($event) => this.copyDeviceTags(this.selectedDevice) },
      { label: 'Editar', icon: 'pi pi-fw pi pi-pencil', command: ($event) => this.editDevice(this.selectedDevice) },
    ];
  }

  ngOnDestroy(): void {
    this.canRetry = false;
    if (this.unsubscribeRefresh) {
      this.unsubscribeRefresh.cancel();
    }
  }

  public subscribeToUpdates() {
    let request = new RefreshTagsRequest();
    let self = this;

    if (!this.unsubscribeRefresh) {
      this.grpcMetadata.set('Authorization', this.authService.getAuthorizationHeader());

      this.unsubscribeRefresh = this.smartComGrpcClient.refreshTags(request, this.grpcMetadata).on('data', (response: RefreshTagsResponse) => {

        let responseObject = response.toObject();

        responseObject.tagsList.forEach(grpcTag => {

          let tag = this.tags.find(d => d.Id == grpcTag.id);

          if (tag) {
            tag['LastValueRead'] = grpcTag.lastreadvalue;
            tag['LastValueReadDateTime'] = grpcTag.lastreadvaluedatetime;
            tag['LastValueReadState'] = Number.parseInt(grpcTag.lastreadvaluestate);
            tag['LastValueReadStateMessage'] = grpcTag.lastreadvaluestatemessage;

            tag['LastValueWrite'] = grpcTag.lastwritevalue;
            tag['LastValueWriteDateTime'] = grpcTag.lastwritevaluedatetime;
            tag['LastValueWriteState'] = Number.parseInt(grpcTag.lastwritevaluestate);
            tag['LastValueWriteStateMessage'] = grpcTag.lastwritevaluestatemessage;

            tag['ErrorMessage'] = grpcTag.errormessage;
            tag['ErrorDetails'] = grpcTag.errordetails;
          }
        });
      }).on('status', (error) => {
        this.unsubscribeRefresh = null;

        if (this.canRetry) {
          setTimeout(function () {
            self.subscribeToUpdates();
          }, 5000);
        }
      });
    }
  }

  public loadTags(event: LazyLoadEvent) {
    this.loading = true;
    let self = this;

    let pageNumber = event != null ? event.first / event.rows : 1;
    let pageSize = event != null ? event.rows : 10;
    let orderByAscending = event != null ? event.sortOrder == 1 : false;
    let orderByColumn = event != null && event.sortField ? event.sortField : 'Device.Id';
    let searchTerms = [];
    let searchColumns = [];

    if (event && event.filters && event.filters.length > 0) {
      console.debug(event.filters);
    }

    let params = `pageNumber=${pageNumber}&pageSize=${pageSize}&orderByAscending=${orderByAscending}&orderByColumn=${orderByColumn}`;

    this.httpClientService.RequestFromAPI('Tag', 'List', params).subscribe(result => {

      let resultTags: any[] = result.Entities;
      this.totalRecords = result.TotalCount;

      let index = 0;

      resultTags.forEach(tag => {
        tag.DataTypeDescription = TagDataTypeLabel.get(tag.DataType);
        tag.LastValueRead = '';
        tag['Index'] = index++;
      });

      for (let i = 0; i < resultTags.length; i++) {
        let rowData = resultTags[i];
        let deviceId = rowData.DeviceId;

        if (this.expandedRows.indexOf(deviceId) < 0) {
          this.expandedRows[deviceId] = true;
        }

        if (i == 0) {
          this.rowGroupMetadata[deviceId] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = resultTags[i - 1];
          let previousRowGroup = previousRowData.Device.Id;
          if (deviceId === previousRowGroup)
            this.rowGroupMetadata[deviceId].size++;
          else
            this.rowGroupMetadata[deviceId] = { index: i, size: 1 };
        }
      }

      this.tags = resultTags;
      this.subscribeToUpdates();
      this.loading = false;

      this.changeDetectorRef.detectChanges();
    }, error => {

      if (this.authService.hasValidToken()) {
        setTimeout(function () {
          self.loadTags(null);
        }, 5000);
      }

      this.loading = false;
    });

  }

  public sidebarClosed() {
    this.loadTags(null);
  }

  public openNew() {
    this.openSidebarEvent.emit({ selectedView: 'tag', selectedId: null, isNew: true });
  }

  public editTag(tag: Tag, isClone: boolean = false) {
    this.openSidebarEvent.emit({ selectedView: 'tag', selectedId: tag.Id, isNew: isClone });
  }

  public copyTag(tag: Tag) {
    this.editTag(tag, true);
  }

  public deleteSelected(tagsToDelete: Tag[]) {
    var self = this;

    if (!tagsToDelete || !tagsToDelete.length) {
      tagsToDelete = this.selectedTags;
    }

    let message = 'Confirma a exclusão da variável?';
    if (tagsToDelete.length > 1) {
      message = 'Confirma a exclusão das variáveis?';
    }

    Swal.fire({
      title: message,
      text: 'Todas as configurações serão perdidas e não poderão ser recuperadas!',
      icon: 'error',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#00acac',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#ff5b57',
      focusCancel: true
    }).then(function (result: SweetAlertResult) {
      if (result.isConfirmed) {
        self.confirmDelete(tagsToDelete);
      }
    });
  }

  public deleteTag(tag: Tag) {
    let tagsToDelete = [tag];
    this.deleteSelected(tagsToDelete);
  }

  private confirmDelete(tagsToDelete: Tag[]) {
    let params = '';
    tagsToDelete.forEach((entity) => {
      if (params != '') { params += '&' };
      params += 'ids[]=' + entity.Id;
    });

    this.httpClientService.RequestFromAPI('Tag', 'Delete', params).subscribe(result => {
      if (result) {
        this.loadTags(null);
        this.messageService.add({ key: 'delete_success', severity: 'success', summary: 'Registro excluído', detail: 'Registro excluído com sucesso!', life: 1500 });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erro ao excluir', detail: 'Não foi possível excluir o registro!' });
      }

    }, error => console.error(error));
  }

  public showLog(selectedEntity: any, logSourceType: LogSourceTypeMap[keyof LogSourceTypeMap]): void {
    this.loggerService.subscribeToLog(selectedEntity.Id, selectedEntity.Description, logSourceType);
  }

  //#region Members 'Device' :: openNew(), editDevice(), copyDevice(), deleteDevice(), confirmDelete()

  public newDevice() {
    this.openSidebarEvent.emit({ selectedView: 'device', selectedId: null, isNew: true });
  }

  public editDevice(device: Device, isClone: boolean = false) {
    this.openSidebarEvent.emit({ selectedView: 'device', selectedId: device.Id, isNew: isClone });
  }

  public copyDeviceTags(device: Device) {
    const activeModal = this.modalService.open(TagsCopyComponent, { size: 'xl' });

    activeModal.componentInstance.selectedDevice = device;

    activeModal.result.then((result: boolean) => {

      if (result) {
        this.loadTags(null);
      }
    },
      (reason) => {
        console.log(`Dismissed ${reason}`);
      });
  }

  //#endregion

  //#region Members :: openTagBatchCreation()

  public openTagBatchCreation() {
    const activeModal = this.modalService.open(TagBatchCreationComponent, { size: 'xxl' });

    activeModal.result.then((result: boolean) => {
      if (result) {
        this.loadTags(null);
      }
    },
      (reason) => {
        console.log(`Dismissed ${reason}`);
      });
  }

  //#endregion
}
