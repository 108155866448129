import { ResponseStream, Status } from './../../../grpc/systemManager_pb_service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import * as global from "../../../config/globals";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemManagerClient } from '../../../grpc/systemManager_pb_service';
import { RefreshChannelsRequest, RefreshChannelsResponse, RefreshDevicesRequest, RefreshDevicesResponse } from '../../../grpc/systemManager_pb';
import { DeviceNewComponent } from '../device-new/device-new.component';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { LazyLoadEvent, MenuItem, MessageService } from 'primeng-lts/api';
import { ChannelEditComponent } from '../../channel/channel-edit/channel-edit.component';
import { LoggerService } from '../../../services/logger.service';
import { LogSourceType, LogSourceTypeMap } from '../../../grpc/logger_pb';
import { DiagnosticMessageDetailComponent } from '../../diagnostic-message/diagnostic-message-detail/diagnostic-message-detail.component';
import { AuthService } from '../../../../../projects/shared/src/lib/services/auth.service';
import { grpc } from '@improbable-eng/grpc-web';
import { Channel, Device, environment, HttpClientService } from '../../../../../projects/shared/src/public-api';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css']
})

export class DeviceListComponent implements OnInit, OnDestroy {
  @Output() openSidebarEvent = new EventEmitter<object>();

  private smartComGrpcClient: SystemManagerClient;
  unsubscribeDeviceRefresh: ResponseStream<RefreshDevicesResponse>;
  unsubscribeChannelRefresh: ResponseStream<RefreshChannelsResponse>;

  items: MenuItem[];

  channelItems: MenuItem[];
  deviceItems: MenuItem[];

  devices: Device[] = [];
  channels: Channel[] = [];

  elements: any[] = [];

  selectedDevices: Device[] = [];
  selectedDevice: Device;
  selectedChannel: Channel;

  rowGroupMetadata: any[] = [];
  totalRecords: number = 0;
  loading: boolean = false;
  expandedRows: any[] = [];
  grpcMetadata: any;
  canRetry: boolean = true;
  changeDeviceEnableLoading: boolean;
  changeChannelEnableLoading: boolean;

  constructor(private modalService: NgbModal, public viewContainerRef: ViewContainerRef, private httpClientService: HttpClientService, private messageService: MessageService, private loggerService: LoggerService, private authService: AuthService) {
    this.smartComGrpcClient = new SystemManagerClient(environment.config.api_hostname);

    this.grpcMetadata = new grpc.Metadata();
  }

  async ngOnInit() {
    this.loading = true;
    this.canRetry = true;

    this.loadChannels(null);
    this.loadDevices(null);

    this.deviceItems = [
      { label: 'Ver log', icon: 'pi pi-fw pi pi-list', styleClass: '', command: () => this.showLog(this.selectedDevice, LogSourceType.SOURCETYPEDEVICE) },
      { label: 'Copiar', icon: 'pi pi-fw pi pi-copy', command: ($event) => this.copyDevice(this.selectedDevice) },
      { label: 'Editar', icon: 'pi pi-fw pi pi-pencil', command: ($event) => this.editDevice(this.selectedDevice) },
      { label: 'Excluir', icon: 'pi pi-fw pi-trash', command: ($event) => this.deleteDevice(this.selectedDevice) }
    ];

    this.channelItems = [
      { label: 'Ver log', icon: 'pi pi-fw pi pi-list', styleClass: '', command: () => this.showLog(this.selectedChannel, LogSourceType.SOURCETYPECHANNEL) },
      { label: 'Editar', icon: 'pi pi-fw pi pi-pencil', command: () => this.editChannel(this.selectedChannel) },
      { label: 'Excluir', icon: 'pi pi-fw pi-trash', command: () => this.deleteChannel(this.selectedChannel) }
    ];
  }

  ngOnDestroy(): void {
    this.canRetry = false;
    if (this.unsubscribeDeviceRefresh) {
      this.unsubscribeDeviceRefresh.cancel();
    }

    if (this.unsubscribeChannelRefresh) {
      this.unsubscribeChannelRefresh.cancel();
    }
  }

  public sidebarClosed() {
    this.loadDevices(null);
  }

  public onChangeDeviceEnabled(id: string) {

  }



  public loadChannels(event: LazyLoadEvent) {
    let self = this;
    this.loading = true;

    let pageNumber = event != null ? event.first / event.rows : 1;
    let pageSize = event != null ? event.rows : 10;
    let orderByAscending = event != null ? event.sortOrder == 1 : false;
    let orderByColumn = event != null && event.sortField ? event.sortField : 'Id';
    let searchTerms = [];
    let searchColumns = [];

    let params = `pageNumber=${pageNumber}&pageSize=${pageSize}&orderByAscending=${orderByAscending}&orderByColumn=${orderByColumn}`;

    this.httpClientService.RequestFromAPI('Channel', 'List', params).subscribe(result => {

      this.totalRecords = result.TotalCount;
      this.channels = result.Entities;
      //this.subscribeToDeviceUpdates();
      //this.subscribeToChannelUpdates();
      this.loading = false;
    }, error => {
      this.loading = false;

      if (this.canRetry) {
        setTimeout(function () {
          self.loadChannels(null);
        }, 5000);
      }
    });
  }

  public loadDevices(event: LazyLoadEvent) {
    let self = this;
    this.loading = true;

    let pageNumber = event != null ? event.first / event.rows : 1;
    let pageSize = event != null ? event.rows : 10;
    let orderByAscending = event != null ? event.sortOrder == 1 : false;
    let orderByColumn = event != null && event.sortField ? event.sortField : 'Channel.Id';
    let searchTerms = [];
    let searchColumns = [];

    let params = `pageNumber=${pageNumber}&pageSize=${pageSize}&orderByAscending=${orderByAscending}&orderByColumn=${orderByColumn}`;

    this.httpClientService.RequestFromAPI('Device', 'List', params).subscribe(result => {

      let resultDevices: any[] = result.Entities;
      this.totalRecords = result.TotalCount;

      let index = 0;

      resultDevices.forEach(device => {
        device['Index'] = index++;
      });

      for (let i = 0; i < resultDevices.length; i++) {
        let rowData = resultDevices[i];
        let channelId = rowData.Channel.Id;

        if (this.expandedRows.indexOf(channelId) < 0) {
          this.expandedRows[channelId] = true;
        }

        if (i == 0) {
          this.rowGroupMetadata[channelId] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = resultDevices[i - 1];
          let previousRowGroup = previousRowData.Channel.Id;
          if (channelId === previousRowGroup)
            this.rowGroupMetadata[channelId].size++;
          else
            this.rowGroupMetadata[channelId] = { index: i, size: 1 };
        }
      }

      this.devices = resultDevices;

      this.populateElements();

      this.subscribeToDeviceUpdates();
      this.subscribeToChannelUpdates();
      this.loading = false;
    }, error => {
      this.loading = false;

      if (this.canRetry) {
        setTimeout(function () {
          self.loadDevices(null);
        }, 5000);
      }
    });
  }

  private populateElements() {

    this.elements = [];
    let index = 0;

    this.channels.forEach(channel => {
      this.elements.push(channel);

      this.devices.forEach(device => {
        if (device.ChannelId == channel.Id) {
          this.elements.push(device);
          device.Channel = channel;
          device['elementIndex'] = index;
        }
      });

      channel['elementIndex'] = index;
      index++;
    });
  }

  public getElementColorClass(element) {
    return 'element-group-color-' + element['elementIndex'];
  }


  public subscribeToDeviceUpdates() {

    if (!this.unsubscribeDeviceRefresh) {
      let request = new RefreshDevicesRequest();

      this.grpcMetadata.set('Authorization', this.authService.getAuthorizationHeader());

      var self = this;
      this.unsubscribeDeviceRefresh = this.smartComGrpcClient.refreshDevices(request, this.grpcMetadata).on('data', (refreshDevicesResponse: RefreshDevicesResponse) => {

        let responseObject = refreshDevicesResponse.toObject();

        responseObject.devicesList.forEach(grpcDevice => {

          let dbDevice = this.devices.find(d => d.Id == grpcDevice.id);

          if (dbDevice && grpcDevice) {
            dbDevice['State'] = grpcDevice.state;
            dbDevice['DiagnosticMessage'] = grpcDevice.diagnosticmessage;
            dbDevice['IsConnected'] = grpcDevice.isconnected;
            dbDevice['RealScanRate'] = grpcDevice.realscanrate;
            dbDevice['ExecutionTime'] = grpcDevice.executiontime;
            dbDevice['MinExecutionTime'] = grpcDevice.minexecutiontime;
            dbDevice['MaxExecutionTime'] = grpcDevice.maxexecutiontime;
            dbDevice.Enabled = grpcDevice.enabled;
          }
        });
      }).on('status', (error) => {

        this.unsubscribeDeviceRefresh = null;

        if (this.canRetry) {
          setTimeout(function () {
            self.subscribeToDeviceUpdates();
          }, 5000);
        }
      });
    }
  }

  public subscribeToChannelUpdates() {

    if (!this.unsubscribeChannelRefresh) {
      let request = new RefreshChannelsRequest();

      this.grpcMetadata.set('Authorization', this.authService.getAuthorizationHeader());

      var self = this;
      this.unsubscribeChannelRefresh = this.smartComGrpcClient.refreshChannels(request, this.grpcMetadata).on('data', (response: RefreshChannelsResponse) => {

        let responseObject = response.toObject();

        this.devices.forEach(device => {

          let grpcChannel = responseObject.channelsList.find(c => c.id == device.Channel.Id);

          if (device.Channel && grpcChannel) {
            device.Channel['State'] = grpcChannel.state;
            device.Channel['DiagnosticMessage'] = grpcChannel.diagnosticmessage;
            device.Channel['IsConnected'] = grpcChannel.isconnected;
            device.Channel['RealScanRate'] = grpcChannel.realscanrate;
            device.Channel['ExecutionTime'] = grpcChannel.executiontime;
            device.Channel['MinExecutionTime'] = grpcChannel.minexecutiontime;
            device.Channel['MaxExecutionTime'] = grpcChannel.maxexecutiontime;
            device.Channel.Enabled = grpcChannel.enabled;
          }
        });
      }).on('status', (error) => {

        this.unsubscribeChannelRefresh = null;

        if (this.canRetry) {
          setTimeout(function () {
            self.subscribeToChannelUpdates();
          }, 5000);
        }
      });
    }
  }

  public getDeviceStatusDescription(device) {
    if (device.Enabled) {
      if (device.IsConnected) {
        return 'Conectado';
      }
      return 'Desconectado';
    }
    else {
      return '-';
    }
  }

  public getDeviceStatusSeverity(device) {
    if (device.Enabled) {
      if (device.IsConnected) {
        return 'success';
      }
      return 'danger';
    }
    else {
      return 'warning';
    }
  }

  public showLog(selectedEntity: any, logSourceType: LogSourceTypeMap[keyof LogSourceTypeMap]): void {
    this.loggerService.subscribeToLog(selectedEntity.Id, selectedEntity.Description, logSourceType);
  }

  //#region Members 'StateDetails' :: showStateDetails()

  public showStateDetails(entity: any) {

    let params = 'id=' + entity['DiagnosticMessage'].id;
    this.httpClientService.RequestFromAPI('DiagnosticMessage', '', params).subscribe((response) => {

      const activeModal = this.modalService.open(DiagnosticMessageDetailComponent, { size: 'xl' });
      activeModal.componentInstance['selectedMessage'] = response;
    });
  }

  //#endregion

  //#region Members 'Device' :: openNew(), editDevice(), copyDevice(), deleteDevice(), confirmDelete(), changeDeviceEnable()

  public openNew() {
    const activeModal = this.modalService.open(DeviceNewComponent, { size: 'xl' });
    activeModal.result.then((id) => {
      if (id) {
        this.openSidebarEvent.emit({ selectedView: 'device', entityTypeId: id, isNew: true });
      }
    },
      (reason) => {
        console.log(`Dismissed ${reason}`);
        activeModal.close();
      });
  }

  public editDevice(device: Device, isClone: boolean = false) {
    this.openSidebarEvent.emit({ selectedView: 'device', selectedId: device.Id, isNew: isClone });
  }

  public copyDevice(device: Device) {
    this.editDevice(device, true);
  }

  public deleteDevice(device: Device) {
    let self = this;

    Swal.fire({
      title: 'Confirma a exclusão do dispositivo?',
      text: 'Todas as configurações serão perdidas e não poderão ser recuperadas!',
      icon: 'error',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#00acac',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#ff5b57',
      focusCancel: true
    }).then(function (result: SweetAlertResult) {
      if (result.isConfirmed) {
        self.confirmDelete(device);
      }
    });
  }

  private confirmDelete(device: Device) {
    let params = 'ids[]=' + device.Id;

    this.httpClientService.RequestFromAPI('Device', 'Delete', params).subscribe(result => {
      if (result) {
        this.loadDevices(null);
        this.messageService.add({ key: 'delete_success', severity: 'success', summary: 'Registro excluído', detail: 'Registro excluído com sucesso!', life: 1500 });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erro ao excluir', detail: 'Não foi possível excluir o registro!' });
      }

    }, error => console.error(error));
  }

  public changeDeviceEnable(device: Device) {

    this.changeDeviceEnableLoading = true;

    let params = 'id=' + device.Id;
    this.httpClientService.RequestFromAPI('Device', 'ChangeEnable', params).subscribe((response) => {
      this.changeDeviceEnableLoading = false;
    }, error => {
      this.changeDeviceEnableLoading = false;
    });
  }

  //#endregion

  //#region Members 'Channel' :: editChannel(), deleteChannel(), confirmChannelDelete()

  public editChannel(channel: Channel) {

    const activeModal = this.modalService.open(ChannelEditComponent, { size: 'xl' });

    activeModal.componentInstance.selectedId = channel.Id;
    activeModal.componentInstance.isNew = false;

    activeModal.result.then((channel: Channel) => {
      this.loadDevices(null);
    },
      (reason) => {
        console.log(`Dismissed ${reason}`);
      });
  }

  public deleteChannel(channel: Channel) {
    let self = this;

    Swal.fire({
      title: 'Confirma a exclusão do canal?',
      text: 'Todas as configurações serão perdidas e não poderão ser recuperadas!',
      icon: 'error',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#00acac',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#ff5b57',
      focusCancel: true
    }).then(function (result: SweetAlertResult) {
      if (result.isConfirmed) {
        self.confirmChannelDelete(channel);
      }
    });
  }

  private confirmChannelDelete(channel: Channel) {
    let params = 'ids[]=' + channel.Id;

    this.httpClientService.RequestFromAPI('Channel', 'Delete', params).subscribe(result => {
      if (result) {
        this.loadDevices(null);
        this.messageService.add({ key: 'delete_success', severity: 'success', summary: 'Registro excluído', detail: 'Registro excluído com sucesso!', life: 1500 });
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Erro ao excluir', detail: 'Não foi possível excluir o registro!' });
      }

    }, error => console.error(error));
  }

  public changeChannelEnable(channel: Channel) {

    this.changeChannelEnableLoading = true;

    let params = 'id=' + channel.Id;
    this.httpClientService.RequestFromAPI('Channel', 'ChangeEnable', params).subscribe((response) => {
      this.changeChannelEnableLoading = false;
    }, error => {
      this.changeChannelEnableLoading = false;
    });
  }

  //#endregion

  //#region Members 'Utils' :: getScanRateDescription(), getRealScanRateClass()

  public getScanRateDescription(scanRate: number) {
    if (scanRate < 1000) {
      return scanRate + ' ms';
    }
    else if (scanRate < 60000) {
      return Math.round(scanRate / 1000) + ' s';
    }
    else if (scanRate < 3600000) {
      return Math.round((scanRate / 1000) / 60) + ' m';
    }
  }

  public getRealScanRateClass(scanRate: number, realScanRate: number) {
    if (realScanRate <= (scanRate * 1.2)) {
      return 'real-scan-rate-good';
    }
    else {
      return 'real-scan-rate-bad';
    }
  }

  //#endregion
}
