<p-contextMenu #cm [model]="items"></p-contextMenu>
<p-menu #channelMenu [model]="channelItems" [popup]="true" appendTo="body"></p-menu>

<!-- <div class="row">
    <div class="col-lg-2">
        teste
    </div>
    <div class="col-lg-10">
        testando
    </div>
</div> -->


<div class="card">
    <p-toolbar>
        <ng-template pTemplate="left">
            <button pButton pRipple label="Novo dispositivo" icon="pi pi-plus" class="p-button-success p-mr-2 p-button-xs-with-label" (click)="openNew()" style="margin-right: 15px;"></button>
            <button pButton pRipple [label]="Excluir" icon="pi pi-trash" class="p-button-danger p-button-xs" (click)="deleteSelected()" [disabled]="!selectedDevices || !selectedDevices.length"></button>
        </ng-template>
        <!-- <ng-template pTemplate="right">
            <button pButton pRipple label="Iniciar todos" icon="fa fa-play-circle" class="p-button-info p-mr-2 p-button-xs-with-label" (click)="start()" [hidden]="deviceManagerIsRunning"></button>
            <button pButton pRipple label="Parar todos" icon="fa fa-stop-circle" class="p-button-warning p-mr-2 p-button-xs-with-label" (click)="stop()" [hidden]="!deviceManagerIsRunning"></button>
        </ng-template> -->
    </p-toolbar>
    <p-table #dt [value]="devices" [globalFilterFields]="['Description']" responsiveLayout="scroll" [(selection)]="selectedDevices" dataKey="ChannelId" currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} registros" [showCurrentPageReport]="false"
        styleClass="p-datatable-sm" [lazy]="true" (onLazyLoad)="loadDevices($event)" [lazyLoadOnInit]="true" [paginator]="true" [alwaysShowPaginator]="false" [resizableColumns]="true" [tableStyleClass]="'table-wrap'" [rows]="10" [rowHover]="true" [rowsPerPageOptions]="[20,50,100]"
        [rowExpandMode]="'multiple'" [totalRecords]="totalRecords" [loading]="loading" [filterDelay]="0" [(contextMenuSelection)]="selectedDevice" [contextMenu]="cm" responsiveLayout="scroll" [expandedRowKeys]="expandedRows">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 2.2rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>Descrição</th>
                <th class="text-center" style="width: 6.7rem;">
                    <div style="font-size: 14px;">Planejado</div>
                    <div style="font-size: 12px;">Atual</div>
                </th>
                <th class="text-center" style="width: 6.7rem;">
                    <div style="font-size: 14px;">Execução</div>
                    <div style="font-size: 12px;">Mín. / Máx.</div>
                </th>
                <th class="text-center" style="width: 3.5rem">Ativo?</th>
                <th style="width: 2.7rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-device let-expanded="expanded">
            <tr class="list-table-row list-table-group-row channel-row" *ngIf="rowGroupMetadata[device.ChannelId] && rowGroupMetadata[device.ChannelId].index === device.Index">
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="device" [style]="{'font-weight':'bold'}" class="p-button-text p-button-rounded p-button-plain p-button-xxs" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td>
                    <p-badge [value]="getDeviceStatusDescription(device.Channel)" [severity]="getDeviceStatusSeverity(device.Channel)" [style]="{'font-size': '10px'}"></p-badge>

                    <span class="p-ml-2 p-text-bold" style="font-weight:bold; color: #6c757d; font-size: 12px;">{{device.Channel.Description}}</span>
                    <div style="display: block;font-size:10px;" class="text-warning" *ngIf="device.Channel.DiagnosticMessage">
                        <i class="fa fa-exclamation-triangle text-warning"></i> {{device.Channel.DiagnosticMessage.message}}
                        <span class="badge bg-danger rounded-pill">{{device.Channel.DiagnosticMessage.count}}</span>
                        <a href="#" (click)="showStateDetails(device.Channel);" style="margin-left: 10px;">Ver detalhes</a>
                    </div>
                </td>
                <td class="text-center">
                    {{getScanRateDescription(device.Channel.ScanRate)}}
                    <div style="font-size: 10px;">{{getScanRateDescription(device.Channel.RealScanRate)}}</div>
                </td>
                <td class="text-center" [class]="getRealScanRateClass(device.Channel.ScanRate, device.Channel.ExecutionTime)">
                    {{getScanRateDescription(device.Channel.ExecutionTime)}}
                    <div style="font-size: 10px;">{{getScanRateDescription(device.Channel.MinExecutionTime)}} / {{getScanRateDescription(device.Channel.MaxExecutionTime)}}</div>
                </td>
                <td class="text-center">
                    <button pButton pRipple [icon]="device.Channel.Enabled ? 'fa fa-check-circle' : 'fa fa-circle'" class="p-button-rounded p-button-xs p-button-secondary" [loading]="changeChannelEnableLoading" (click)="changeChannelEnable(device.Channel)"></button>
                </td>
                <td>
                    <button pButton pRipple icon="pi pi-bars" class="p-button-rounded p-button-secondary p-button-xs" (click)="selectedChannel = device.Channel; channelMenu.toggle($event)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-device let-index="rowIndex">
            <tr class="list-table-row device-row">
                <td>
                    <p-checkbox name="devicesChecked_{{index}}" [value]="device" [(ngModel)]="selectedDevices"></p-checkbox>
                </td>
                <td>
                    <p-badge [value]="getDeviceStatusDescription(device)" [severity]="getDeviceStatusSeverity(device)" [style]="{'font-size': '12px'}"></p-badge>
                    {{device.Description}}
                    <span style="display: block;font-size:10px;" class="text-warning" *ngIf="device.DiagnosticMessage">
                        <i class="fa fa-exclamation-triangle text-warning"></i> {{device.DiagnosticMessage.message}} <span class="badge bg-danger rounded-pill">{{device.DiagnosticMessage.count}}</span>
                    <a href="#" (click)="showStateDetails(device);" style="margin-left: 10px;">Ver detalhes</a>
                    </span>
                </td>
                <td class="text-center">
                    {{getScanRateDescription(device.ScanRate)}}
                    <span style="display: block; font-size: 10px;">{{getScanRateDescription(device.RealScanRate)}}</span>
                </td>
                <td class="text-center" [class]="getRealScanRateClass(device.ScanRate, device.RealScanRate)">
                    {{getScanRateDescription(device.ExecutionTime)}}
                    <span style="display: block; font-size: 10px;">{{getScanRateDescription(device.MinExecutionTime)}} / {{getScanRateDescription(device.MaxExecutionTime)}}</span>
                </td>
                <td class="text-center">
                    <button pButton pRipple [icon]="device.Enabled ? 'fa fa-check-circle' : 'fa fa-circle'" class="p-button-rounded p-button-xs p-button-secondary" [loading]="changeDeviceEnableLoading" (click)="changeDeviceEnable(device)"></button>
                </td>
                <td>
                    <button pButton pRipple icon="pi pi-bars" class="p-button-rounded p-button-default p-button-xs" (click)="selectedDevice = device; deviceMenu.toggle($event)"></button>
                    <p-menu #deviceMenu [model]="deviceItems" [popup]="true" appendTo="body"></p-menu>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>