<div class="modal-header bg-blue-darker">
    <h4 *ngIf="selectedChannel" class="modal-title text-white">{{selectedChannel.Description}}</h4>
    <button type="button" class="close" (click)="onCancel()">×</button>
</div>
<div class="modal-body" style="padding-top: 0;">
    <fieldset class="entity-form rounded">
        <legend>Configuração do canal</legend>
        <div #channelModuleView></div>
    </fieldset>
</div>
<div class="modal-footer" *ngIf="moduleIsReady">
    <div class="row" style="width: 100%;">
        <div class="col-lg-2">
            <a href="#" (click)="onCancel()" class="footer-cancel-link modal-footer-cancel-link">
                <i class="fas fa-redo"></i>&nbsp; Cancelar e fechar
            </a>
        </div>
        <div class="col-lg-5"></div>
        <div class="col-lg-5">
            <button type="submit" (click)="onSubmit()" class="btn btn-success btn-block" [disabled]="!isValid()">Salvar</button>
        </div>
    </div>
</div>