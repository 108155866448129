<div class="modal-header bg-blue-darker">
    <h4 class="modal-title text-white"><i class="fa fa-user"></i> Perfil do usuário</h4>
    <button type="button" class="close" (click)="onCancel()">×</button>
</div>
<div class="modal-body">

</div>
<div class="modal-footer">
    <div class="row" style="width: 100%;">
        <div class="col-lg-2">
            <a href="#" (click)="onCancel()" class="footer-cancel-link modal-footer-cancel-link">
                <i class="fas fa-redo"></i>&nbsp; Cancelar e fechar
            </a>
        </div>
        <div class="col-lg-5"></div>
        <div class="col-lg-5">
            <button type="submit" (click)="onSubmit()" class="btn btn-success btn-block" [disabled]="!isValid()">Salvar</button>
        </div>
    </div>
</div>