<p-contextMenu #cm [model]="items"></p-contextMenu>

<div class="card">
    <p-toolbar>
        <ng-template pTemplate="left">
            <button pButton pRipple label="Novo serviço" icon="pi pi-plus" class="p-button-success p-mr-2 p-button-xs-with-label" (click)="addService()"></button>
            <button pButton pRipple [label]="Excluir" icon="pi pi-trash" class="p-button-danger p-button-xs" (click)="deleteSelected()" [disabled]="!selectedServices || !selectedServices.length"></button>
        </ng-template>
        <ng-template pTemplate="right">
            <button pButton pRipple label="Iniciar todos" icon="fa fa-play-circle" class="p-button-info p-mr-2 p-button-xs-with-label" (click)="start()" [hidden]="serviceManagerIsRunning"></button>
            <button pButton pRipple label="Parar todos" icon="fa fa-stop-circle" class="p-button-warning p-mr-2 p-button-xs-with-label" (click)="stop()" [hidden]="!serviceManagerIsRunning"></button>
        </ng-template>
    </p-toolbar>

    <p-table #dt [value]="services" [globalFilterFields]="['Description']" responsiveLayout="scroll" [(selection)]="selectedServices" dataKey="Id" currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} registros" [showCurrentPageReport]="false"
        styleClass="p-datatable-sm" [lazy]="true" (onLazyLoad)="loadServices($event)" [lazyLoadOnInit]="true" [paginator]="true" [alwaysShowPaginator]="false" [resizableColumns]="true" [tableStyleClass]="'table-wrap'" [rows]="10" [rowHover]="true" [rowsPerPageOptions]="[20,50,100]"
        [rowExpandMode]="'multiple'" [totalRecords]="totalRecords" [loading]="loading" [filterDelay]="0" [(contextMenuSelection)]="selectedService" [contextMenu]="cm" responsiveLayout="scroll" [expandedRowKeys]="expandedRows">
        <ng-template pTemplate="header">
            <tr class="list-table-row">
                <th style="width: 2rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="Description">Descrição
                    <p-sortIcon field="Description"></p-sortIcon>
                </th>
                <th class="text-center" style="width: 6.7rem;">
                    <div style="font-size: 14px;">Planejado</div>
                    <div style="font-size: 12px;">Atual</div>
                </th>
                <th class="text-center" style="width: 6.7rem;">

                    <div style="font-size: 14px;">Execução</div>
                    <div style="font-size: 12px;">Mín. / Máx.</div>
                </th>
                <th class="text-center" style="width: 4rem">Ativo?</th>
                <th style="width: 2.7rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-service>
            <tr class="list-table-row">
                <td>
                    <p-tableCheckbox [value]="service"></p-tableCheckbox>
                </td>
                <td>
                    <i class="fa" [ngClass]="getStateClass(service)"></i> {{service.Description}}
                    <div style="font-size:10px;" class="text-warning" *ngIf="service.DiagnosticMessage">
                        <a href="#" (click)="showStateDetails(service);">
                            <span class="p-text-nowrap p-text-truncate" style="display: inline-block; width: 95%;"><i class="fa fa-exclamation-triangle text-warning"></i> {{service.DiagnosticMessage.message}}</span>
                            <span class="badge bg-danger rounded-pill" style="display: inline-block; margin-top:-10px;">{{service.DiagnosticMessage.count}}</span>
                        </a>
                    </div>
                </td>
                <td class="text-center">
                    {{getScanRateDescription(service.ScanRate)}}
                    <span style="display: block; font-size: 10px;">{{getScanRateDescription(service.RealScanRate)}}</span>
                </td>
                <td class="text-center" [class]="getRealScanRateClass(service.ScanRate, service.RealScanRate)">
                    {{getScanRateDescription(service.ExecutionTime)}}
                    <span style="display: block; font-size: 10px;">{{getScanRateDescription(service.MinExecutionTime)}} / {{getScanRateDescription(service.MaxExecutionTime)}}</span>
                </td>
                <td class="text-center">
                    <button pButton pRipple [icon]="service.Enabled ? 'fa fa-check-circle' : 'fa fa-circle'" class="p-button-rounded p-button-xs p-button-secondary" [loading]="changeServiceEnableLoading" (click)="changeServiceEnable(service)"></button>
                </td>
                <td>
                    <button pButton pRipple icon="pi pi-bars" class="p-button-rounded p-button-default p-button-xs" (click)="selectedService = service; serviceMenu.toggle($event)"></button>
                    <p-menu #serviceMenu [model]="items" [popup]="true" appendTo="body"></p-menu>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>