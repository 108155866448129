import { Compiler, ComponentFactory, ComponentFactoryResolver, Injectable, Injector, NgModuleFactory, NgModuleRef } from '@angular/core';
import * as global from "../config/globals";

// Preload all angular libraries
import * as angularCompiler from '@angular/compiler';
import * as angularAnimations from '@angular/animations';
import * as angularCommon from '@angular/common';
import * as angularCommonHttp from '@angular/common/http';
import * as angularCore from '@angular/core';
import * as angularForms from '@angular/forms';
import * as angularPb from '@angular/platform-browser';
import * as angularPbAnimations from '@angular/platform-browser/animations';

import * as matSnackBar from '@angular/material/snack-bar';
import * as bootstrap from 'bootstrap';
import * as ngBootstrap from '@ng-bootstrap/ng-bootstrap';

import * as tslib from 'tslib';
import * as rxjs from 'rxjs';
import * as rxjsOperators from 'rxjs/operators';

//import * as ngxBuildPlus from 'ngx-build-plus';
import * as ngxChips from 'ngx-chips';
import * as ngxCountdown from 'ngx-countdown';
import * as ngxDaterangepickerMaterial from 'ngx-daterangepicker-material';
import * as ngxHighlightJs from 'ngx-highlight-js';
import * as ngxLogger from 'ngx-logger';
import * as ngxPerfectScrollbar from 'ngx-perfect-scrollbar';
import * as ngxTrend from 'ngx-trend';
import * as ngxColorPicker from 'ngx-color-picker';

import * as primengApi from 'primeng-lts/api';
import * as primengButton from 'primeng-lts/button';
import * as primengDropdownModule from 'primeng-lts/dropdown';
import * as primengInputNumberModule from 'primeng-lts/inputnumber';
import * as primengInputTextModule from 'primeng-lts/inputtext';
import * as primengTabViewModule from 'primeng-lts/tabview';
import * as primengTableModule from 'primeng-lts/table';
import * as primengTreeTableModule from 'primeng-lts/treetable';
import * as primengTreeModule from 'primeng-lts/tree';

import * as smartcomShared from '../../../projects/shared/src/public-api';
import { environment } from '../../../projects/shared/src/public-api';

declare const window: any;

// const systemJsDefine = window.define;
// window.define = null;

@Injectable({
  providedIn: 'root'
})
export class SystemJsLoaderService {

  devMode: boolean = false;
  loadedModules: any[] = [];

  constructor(private readonly compiler: Compiler, private readonly injector: Injector, private readonly componentFactoryResolver: ComponentFactoryResolver) {

    console.log('[SystemJsLoaderService] Constructor');

    // Create a custom resolver
    // const originalResolve = window.System.resolve;
    // window.System.resolve = function(name: string) {

    //   const currentLocation = `${location.protocol}//${location.hostname}${(location.port ? ':' + location.port: '')}`;

    //   // Load lazy libraries from http
    //   if (name.startsWith('lazy-')) {

    //     return `${currentLocation}/deps/${name}.umd.js`;

    //   // @angular, rxjs and tslib libraries are preloaded
    //   } else if (name.startsWith('@angular/') || name.startsWith('ngx-') || name.startsWith('@smartcom/') || name.startsWith('@ng') || name.startsWith('rxjs') || name === 'tslib' || name.startsWith('http://')) {
    //     return originalResolve.apply(this, arguments);

    //   // Load everything else from http
    //   } else {
    //     return `${currentLocation}/deps/${name}.js`;
    //   }
    // };

    // Preload all angular libraries to the main bundle
    window.System.set('app:@angular/compiler', angularCompiler);
    window.System.set('app:@angular/animations', angularAnimations);
    window.System.set('app:@angular/common', angularCommon);
    window.System.set('app:@angular/common/http', angularCommonHttp);
    window.System.set('app:@angular/core', angularCore);
    window.System.set('app:@angular/forms', angularForms);
    window.System.set('app:@angular/platform-browser', angularPb);
    window.System.set('app:@angular/platform-browser/animations', angularPbAnimations);

    window.System.set('app:@ng-bootstrap/ng-bootstrap', ngBootstrap);

    window.System.set('app:primeng-lts/api', primengApi);
    window.System.set('app:primeng-lts/button', primengButton);
    window.System.set('app:primeng-lts/dropdown', primengDropdownModule);
    window.System.set('app:primeng-lts/inputnumber', primengInputNumberModule);
    window.System.set('app:primeng-lts/inputtext', primengInputTextModule);
    window.System.set('app:primeng-lts/tabview', primengTabViewModule);
    window.System.set('app:primeng-lts/tree', primengTreeModule);
    window.System.set('app:primeng-lts/table', primengTableModule);
    window.System.set('app:primeng-lts/treetable', primengTreeTableModule);

    //window.System.set('app:ngx-build-plus', ngxBuildPlus);
    window.System.set('app:ngx-chips', ngxChips);
    window.System.set('app:ngx-countdown', ngxCountdown);
    window.System.set('app:ngx-daterangepicker-material', ngxDaterangepickerMaterial);
    window.System.set('app:ngx-highlight-js', ngxHighlightJs);
    window.System.set('app:ngx-logger', ngxLogger);
    window.System.set('app:ngx-perfect-scrollbar', ngxPerfectScrollbar);
    window.System.set('app:ngx-trend', ngxTrend);
    window.System.set('app:ngx-color-picker', ngxColorPicker);
    window.System.set('app:@materia-ui/ngx-monaco-editor', rxjsOperators);

    window.System.set('app:@angular/material/snack-bar', matSnackBar);
    window.System.set('app:tslib', tslib);
    window.System.set('app:rxjs', rxjs);
    window.System.set('app:rxjs/operators', rxjsOperators);

    // Angular Core
    window.System.import('@angular/compiler');
    window.System.import('@angular/animations');
    window.System.import('@angular/common');
    window.System.import('@angular/common/http');
    window.System.import('@angular/core');
    window.System.import('@angular/forms');
    window.System.import('@angular/platform-browser');
    window.System.import('@angular/platform-browser/animations');

    // Ng-Bootstrap
    window.System.import('@ng-bootstrap/ng-bootstrap');

    // PrimeNG
    window.System.import('primeng-lts/api');
    window.System.import('primeng-lts/button');
    window.System.import('primeng-lts/dropdown');
    window.System.import('primeng-lts/inputnumber');
    window.System.import('primeng-lts/inputtext');
    window.System.import('primeng-lts/tabview');
    window.System.import('primeng-lts/tree');
    window.System.import('primeng-lts/table');
    window.System.import('primeng-lts/treetable');

    window.System.import('@angular/material/snack-bar');
    window.System.import('tslib');
    window.System.import('rxjs');
    window.System.import('rxjs/operators');

    window.System.import('@materia-ui/ngx-monaco-editor');

    // SmartCom Shared Libraries
    window.System.set('app:@smartcom/shared', smartcomShared);
    window.System.import('@smartcom/shared');
  }

  public load(moduleName: string, moduleNamespace: string, moduleEntryName: string, componentName: string) {
    let namespace = '';

    if (this.devMode) {
      moduleEntryName = moduleEntryName.replace('smartcom-', '');
      return import('../../../projects/' + moduleEntryName + '/src/public-api').then(
        module => {
          const moduleFactory = this.compiler.compileModuleSync(module[`${moduleName}Module`]);
          const moduleRef = moduleFactory.create(this.injector);
          const resolver: ComponentFactoryResolver = moduleRef.componentFactoryResolver;
          const applicationComponentFactory: ComponentFactory<any> = resolver.resolveComponentFactory(module[`${componentName}Component`]);

          return applicationComponentFactory;
        }
      );
    }
    else {
      let entryPoint = `${environment.config.api_hostname}/resources/${moduleNamespace}/js/${moduleEntryName}.umd.js`;

      if (this.loadedModules.findIndex(m => m == entryPoint) >= 0) {
        console.log('Já existe!');
      }
      else {
        console.log('Não existe!');
        this.loadedModules.push(entryPoint);
      }


      return window.System.import(entryPoint).then((module) => {

        const moduleFactory = this.compiler.compileModuleSync(module[`${moduleName}Module`]);

        const moduleRef = moduleFactory.create(this.injector);

        const resolver: ComponentFactoryResolver = moduleRef.componentFactoryResolver;

        const applicationComponentFactory: ComponentFactory<any> = resolver.resolveComponentFactory(module[`${componentName}Component`]);
        //return applicationComponentFactory.create(this.injector, [], null, moduleRef);

        return applicationComponentFactory;
      });
    }


  }








  // return window.System.import(`${currentLocation}/deps/${entryName}.umd.js`).then((moduleToCompile: any) => {

  //   // Hardcoded module and component names
  //   const staticModuleName: string = componentName + 'Module';
  //   const statupAppComponentName: string = componentName + 'Component';

  //   let moduleFactory: NgModuleFactory<any>;

  //   if (moduleToCompile[staticModuleName] instanceof NgModuleFactory) {
  //     moduleFactory = moduleToCompile[staticModuleName];
  //   } else {
  //     moduleFactory = this.compiler.compileModuleSync(moduleToCompile[staticModuleName]);
  //   }

  //   const moduleRef: NgModuleRef<any> = moduleFactory.create(this.injector);
  //   const resolver: ComponentFactoryResolver = moduleRef.componentFactoryResolver;

  //   const applicationComponentFactory: ComponentFactory<any> = resolver.resolveComponentFactory(moduleToCompile[statupAppComponentName]);

  //   console.debug(applicationComponentFactory);

  //   return applicationComponentFactory;
  // });


  // public load(moduleName: string, moduleNamespace: string, moduleEntryName: string, componentName: string) {

  //   let entryPoint = `${environment.config.api_hostname}/resources/${moduleNamespace}/js/${moduleEntryName}.js`;

  //   return fetch(entryPoint)
  //     .then(response => response.text())
  //     .then(source => {

  //       try {
  //         const exports = {};
  //         const modules = {
  //           '@angular/core': angularCore,
  //           '@angular/common': angularCommon,
  //           '@angular/animations': angularAnimations,
  //           '@angular/common/http': angularCommonHttp,
  //           '@angular/forms': angularForms,
  //           '@angular/platform-browser': angularPb,
  //           '@angular/platform-browser/animations': angularPbAnimations,
  //           '@smartcom/shared': smartcomShared
  //           //'@angular/router': angularRouter,
  //           // etc...
  //         };


  //         const require: any = (module) => modules[module];
  //         // tslint:disable-next-line:no-eval
  //         eval(source);

  //         console.debug(exports);

  //         const moduleFactory: NgModuleFactory<any> = exports[`${moduleName}Module`];



  //         const modRef = moduleFactory.create(this.injector);
  //         const componentFactory = modRef.componentFactoryResolver.resolveComponentFactory(this.getEntryComponent(moduleFactory));

  //         return componentFactory;
  //         // const component = componentFactory.create(modRef.injector);
  //         // const cmpRef = this.viewContainer.createComponent<any>(componentFactory);
  //         // cmpRef.instance.title = 'LOADED:)';
  //       } catch (e) {
  //         console.error(e);
  //       }
  //     });
  // }

  // getEntryComponent(moduleFactory: NgModuleFactory<any>) {
  //   // search (<any>moduleFactory.moduleType).decorators[0].type.prototype.ngMetadataName === NgModule
  //   return (<any>moduleFactory.moduleType).decorators[0].args[0].entryComponents[0];
  // }

}
