export enum TagType {
    Value,
    Average,
    Sum,
    Complex
}

export enum TagDataType {
    Null = 0,
    Boolean, // A two-state logical value (true or false).
    SByte, // An integer value between −128 and 127 inclusive.
    Byte, // An integer value between 0 and 255 inclusive.
    Int16, // An integer value between −32 768 and 32 767 inclusive.
    UInt16, // An integer value between 0 and 65 535 inclusive.
    Int32, // An integer value between −2 147 483 648 and 2 147 483 647 inclusive.
    UInt32, // An integer value between 0 and 4 294 967 295 inclusive.
    Int64, // An integer value between −9 223 372 036 854 775 808 and 9 223 372 036 854 775 807 inclusive.
    UInt64, // An integer value between 0 and 18 446 744 073 709 551 615 inclusive.
    Float, // An IEEE single precision (32 bit) floating point value.
    Double, // An IEEE double precision (64 bit) floating point value.
    String, // A sequence of Unicode characters.
    DateTime, // An instance in time.
    Guid, // A 16-byte value that can be used as a globally unique identifier.
    ByteString, // A sequence of octets.
}

export enum TagNodeType {
    Folder = 0,
    Variable = 1,
    Method = 2
}

export const TagDataTypeLabel: Map<number, string> = new Map<number, string>([
    [TagDataType.Null, 'Unknown'],
    [TagDataType.Boolean, 'Boolean'],
    [TagDataType.SByte, 'SByte'],
    [TagDataType.Byte, 'Byte'],
    [TagDataType.Int16, 'Int16'],
    [TagDataType.UInt16, 'UInt16'],
    [TagDataType.Int32, 'Int32'],
    [TagDataType.UInt32, 'UInt32'],
    [TagDataType.Int64, 'Int64'],
    [TagDataType.UInt64, 'UInt64'],
    [TagDataType.Float, 'Float'],
    [TagDataType.Double, 'Double'],
    [TagDataType.String, 'String'],
    [TagDataType.DateTime, 'DateTime'],
    [TagDataType.Guid, 'Guid'],
    [TagDataType.ByteString, 'ByteString'],
]);

export enum TagAccessType {
    ReadOnly,
    WriteOnly,
    ReadWrite
}

export enum TagScalingType {
    None,
    Linear,
    SquareRoot
}

export enum Enconding {
    Default = 0,
    Unicode = 1200,
    BigEndianUnicode = 1201,
    UTF32 = 12000,
    BigEndianUTF32 = 12001,
    UTF7 = 65000,
    UTF8 = 65001,
    ASCII = 20127,
    Latin1 = 28591
}

export enum EntityState {
    /// <summary>
    /// Não modificado
    /// </summary>
    Unmodified = 0,

    /// <summary>
    /// Inserindo
    /// </summary>
    Inserting = 1,

    /// <summary>
    /// Editando
    /// </summary>
    Editing = 2,

    /// <summary>
    /// Excluindo
    /// </summary>
    Deleting = 4
}

export enum InterfaceType {
    Ethernet = 0,
    Wireless = 1
}

export enum WirelessOperationMode {
    AccessPoint = 0,
    WirelessClient = 1
}

export enum IpAddressMode {
    Dynamic = 0,
    Static = 1
}

export enum IpAddressType {
    IPv4 = 0,
    IPv6 = 1
}

export enum WirelessBand {
    Gz24 = 0,
    Gz50 = 1
}

export enum TriggerType {
    TimeInterval,
    TagValue,
    TargetAndAckValues,
}

export enum TriggerCheckingType {
    Change,
    Comparison
}

export enum TriggerComparisonOperator {
    //[Description("Igual")]
    Equals,
    //[Description("Diferente")]
    NotEqual,
    //[Description("Maior que")]
    GreaterThan,
    //[Description("Menor que")]
    LessThan,
    //[Description("Maior ou igual que")]
    GreaterThanOrEqual,
    //[Description("Menor ou igual que")]
    LessThanOrEqual
}

export class EnumUtils {

    //#region General

    static GetLogLevels(): any[] {
        let logLevels = [{ Id: 0, Description: 'Tudo' }, { Id: 1, Description: 'Depuração' }, { Id: 2, Description: 'Informações' }, { Id: 3, Description: 'Avisos' }, { Id: 4, Description: 'Erros' }, { Id: 5, Description: 'Erros críticos' }, { Id: 6, Description: 'Nenhum' }];

        return logLevels;
    }

    static GetEncodings(): any[] {
        let encodings = [{ Id: 0, Description: 'Default' }, { Id: 1200, Description: 'Unicode' }, { Id: 1201, Description: 'BigEndianUnicode' }, { Id: 12000, Description: 'UTF32' },
        { Id: 12001, Description: 'BigEndianUTF32' }, { Id: 65000, Description: 'UTF7' }, { Id: 65001, Description: 'UTF8' }, { Id: 20127, Description: 'ASCII' }, { Id: 28591, Description: 'Latin1' }];

        return encodings;
    }

    //#endregion

    //#region Tags

    static GetTagAccessTypes(): any[] {
        let tagAccessTypes = [{ Id: 0, Description: 'Somente leitura' }, { Id: 1, Description: 'Somente escrita' }, { Id: 2, Description: 'Leitura e Escrita' }];

        return tagAccessTypes;
    }

    static GetTagDataTypes(): any[] {
        let tagDataTypes = [{ Id: 0, Description: 'Unknown' }, { Id: 1, Description: 'Boolean' }, { Id: 2, Description: 'SByte' }, { Id: 3, Description: 'Byte' }, { Id: 4, Description: 'Int16' },
        { Id: 5, Description: 'UInt16' }, { Id: 6, Description: 'Int32' }, { Id: 7, Description: 'UInt32' }, { Id: 8, Description: 'Int64' },
        { Id: 9, Description: 'UInt64' }, { Id: 10, Description: 'Float' }, { Id: 11, Description: 'Double' }, { Id: 12, Description: 'String' },
        { Id: 13, Description: 'DateTime' }, { Id: 14, Description: 'Guid' }, { Id: 15, Description: 'ByteString' }];

        return tagDataTypes;
    }

    static GetTagTypes(): any[] {
        let tagTypes = [{ Id: 0, Description: 'Valor' }, { Id: 1, Description: 'Média' }, { Id: 2, Description: 'Soma' }, { Id: 3, Description: 'Tipo complexo' }];

        return tagTypes;
    }

    static GetTagScalingTypes(): any[] {
        let tagScalingTypes = [{ Id: 0, Description: 'Nenhuma' }, { Id: 1, Description: 'Linear' }, { Id: 2, Description: 'Raiz quadrada' }];

        return tagScalingTypes;
    }

    //#endregion

    //#region Triggers

    static GetTriggerTypes(): any[] {
        let triggerTypes = [{ Id: 0, Description: 'Intervalo de tempo' }, { Id: 1, Description: 'Valor' }, { Id: 2, Description: 'Valor e confirmação' }];

        return triggerTypes;
    }

    static GetTriggerCheckingTypes(): any[] {

        let triggerCheckingTypes = [{ Id: 0, Description: 'por Mudança' }, { Id: 1, Description: 'por Comparação' }];

        return triggerCheckingTypes;
    }

    static GetTriggerComparisonOperators(): any[] {
        let triggerComparisonOperators = [{ Id: 0, Description: 'Igual a' }, { Id: 1, Description: 'Diferente de' }, { Id: 2, Description: 'Maior que' }, { Id: 3, Description: 'Menor que' }, { Id: 4, Description: 'Maior ou igual a' }, { Id: 5, Description: 'Menor ou igual a' }];

        return triggerComparisonOperators;
    }

    //#endregion

    //#region System Managers :: GetInterfaceTypes()

    static GetInterfaceTypes(): any[] {
        let types = [{ Id: 0, Description: 'Ethernet' }, { Id: 1, Description: 'Wireless' }];

        return types;
    }

    static GetWirelessOperationModes(): any[] {
        let types = [{ Id: 0, Description: 'Ponto de acesso (AP)' }, { Id: 1, Description: 'Cliente sem fio (Client)' }];

        return types;
    }

    static GetIpAddressModes(): any[] {
        let types = [{ Id: 0, Description: 'IP Dinâmico' }, { Id: 1, Description: 'IP Estático' }];

        return types;
    }

    static GetWirelessBands(): any[] {
        let types = [{ Id: 0, Description: '2.4GHz' }, { Id: 1, Description: '5GHz' }];

        return types;
    }

    //#endregion
}