<div class="form-header bg-blue-darker rounded-top">
    <h4 class="text-white">
        {{selectedDeviceDescription}}
    </h4>
</div>
<fieldset class="parent-select-box rounded">
    <legend>Canal de comunicação</legend>
    <form [formGroup]="channelForm">
        <div class="row">
            <div class="col-lg-12">
                <label for="channel">Escolha ou crie um canal de comunicação</label>

                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="pi pi-tags" style="line-height: 1.25;"></i></span>
                    <div class="col-5" style="padding-left:0; padding-right:0;">
                        <p-dropdown [options]="channels" inputId="channel" formControlName="channel" dataKey="Id" [disabled]="!isNew" placeholder="Escolha ou crie um canal de comunicação" [autoDisplayFirst]="false" (onChange)="changeSelectedChannel($event.value);" optionLabel="Description"
                            [style]="{'minWidth':'100%'}"></p-dropdown>
                    </div>
                    <button type="button" pButton pRipple icon="pi pi-plus" (click)="newChannel();" [disabled]="!isNew" class="p-button-success"></button>
                    <button type="button" pButton pRipple icon="pi pi-pencil" (click)="editChannel();" [disabled]="!selectedChannel" class="p-button-warning"></button>
                    <button type="button" pButton pRipple icon="pi pi-times" (click)="deleteChannel();" [disabled]="!selectedChannel || !isNew" class="p-button-danger"></button>
                </div>
            </div>
        </div>
    </form>
</fieldset>

<fieldset class="entity-form rounded" [hidden]="!selectedDevice">
    <legend>Configuração do dispositivo</legend>

    <div #deviceModuleView></div>
</fieldset>