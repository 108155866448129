import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-diagnostic-message-detail',
  templateUrl: './diagnostic-message-detail.component.html',
  styleUrls: ['./diagnostic-message-detail.component.css']
})
export class DiagnosticMessageDetailComponent implements OnInit {

  selectedMessage: any = {};

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public onCancel() {
    this.activeModal.dismiss('Cancel');
  }

  public getStateDescription(message) {
    if (message) {
      switch (message.State) {
        case 0:
          return 'Info';
        case 1:
          return 'Aviso';
        case 2:
          return 'Erro';
        case 3:
          return 'Crítico';
      }
    }
  }

  public getStateClass(message) {
    if (message) {
      switch (message.State) {
        case 0:
          return 'fa-info-circle text-info';
        case 1:
          return 'fa-exclamation-circle text-warning';
        case 2:
          return 'fa-exclamation-triangle text-danger';
        case 3:
          return 'fa-ban text-danger';
      }
    }
  }
}
