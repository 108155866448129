<div class="form-header bg-blue-darker rounded-top">
    <h4 class="text-white">
        {{selectedServiceDescription}}
    </h4>
</div>
<fieldset class="parent-select-box rounded">
    <legend>Tipo de serviço</legend>
    <form [formGroup]="serviceTypeForm">
        <div class="row">
            <div class="col-lg-12">
                <label for="selectedServiceType">Escolha um tipo de serviço</label>

                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon"><i class="pi pi-tags" style="line-height: 1.25;"></i></span>
                    <div class="col-5" style="padding-left:0; padding-right:0;">
                        <p-dropdown [options]="serviceTypes" inputId="selectedServiceType" formControlName="ServiceType" dataKey="Id" [autoDisplayFirst]="false" [disabled]="!isNew" (onChange)="changeSelectedServiceType($event.value);" optionLabel="Description" [style]="{'minWidth':'100%'}"></p-dropdown>
                    </div>

                    <button type="button" pButton pRipple icon="pi pi-refresh" (click)="loadServiceTypes();" [disabled]="!isNew" class="p-button-info" label="Atualizar"></button>
                </div>
            </div>
        </div>
    </form>
</fieldset>

<fieldset class="entity-form rounded" [hidden]="!selectedService">
    <legend>Configuração do serviço</legend>
    <div #serviceModuleView></div>
</fieldset>