import { Component, OnInit, Input, Inject, ViewContainerRef, forwardRef, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SystemJsLoaderService } from '../../../core/system-js-loader.service';
import { HttpClientService, Service, FormButton, IFormBase, FormBase, ServiceType, settings } from '../../../../../projects/shared/src/public-api';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-service-edit',
  templateUrl: './service-edit.component.html',
  providers: [{ provide: FormBase, useExisting: ServiceEditComponent }]
})

export class ServiceEditComponent extends FormBase implements OnInit, IFormBase {

  // IFormBase
  editEntityEventEmitter: EventEmitter<object>;
  newEntityEventEmitter: EventEmitter<any>;
  public moduleIsReady: boolean;

  @ViewChild('serviceModuleView', { read: ViewContainerRef })
  private anchorViewContainer: ViewContainerRef;

  @Input() selectedId: string;
  @Input() isNew: boolean;
  public selectedFormBase: IFormBase;
  public selectedService: Service;

  public serviceTypes: ServiceType[] = [];
  public selectedServiceType: ServiceType;
  public serviceTypeForm: FormGroup;
  selectedServiceDescription: string;
  componentInstance: any;

  constructor(private readonly systemJsLoader: SystemJsLoaderService, private httpClientService: HttpClientService, private changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder, private modalService: NgbModal) {
    super();

    this.createForm();
  }

  ngOnInit() {
    if (!this.selectedId && this.isNew) {
      this.selectedServiceDescription = "Novo serviço";
      this.loadServiceTypes();
    }
    else if (this.selectedId) {
      this.callEdit();
    }
  }

  private callEdit() {
    let params = 'id=' + this.selectedId;
    this.httpClientService.RequestFromAPI('Service', '', params).subscribe(service => {

      if (this.isNew) {
        this.selectedId = null;
        service.Id = settings.GUID_EMPTY;
        service.Description = '(Copy of) ' + service.Description;
      }

      this.selectedServiceDescription = service.Description;
      this.selectedService = service;

      this.loadServiceTypes().then(response => {
        this.selectedServiceType = this.serviceTypes.find(ser => ser.Id === service.ServiceTypeId);
        this.updateForm();
      });

      this.openView(service);

    }, error => console.error(error));
  }

  private callNew(id) {
    let params = 'id=' + id;
    this.httpClientService.RequestFromAPI('Service', 'New', params).subscribe(service => {
      this.openView(service);

    }, error => console.error(error));
  }

  private openView(service: Service) {

    this.selectedService = service;

    var moduleName = service.ModuleName;
    var moduleNamespace = service.ModuleNamespace;
    var moduleEntryName = service.ModuleEntryName;
    var componentName = service.ComponentName;

    this.moduleIsReady = false;

    if (this.componentInstance) {
      this.componentInstance.destroy();
      this.componentInstance = null;
    }

    this.systemJsLoader.load(moduleName, moduleNamespace, moduleEntryName, componentName).then(componentFactory => {

      this.componentInstance = this.anchorViewContainer.createComponent(componentFactory);

      this.selectedFormBase = this.componentInstance.instance as IFormBase;

      this.selectedFormBase['selectedService'] = service;
      // this.selectedFormBase['editEntityEventEmitter'].subscribe(channel => {
      //   //this.editChannel(channel);
      // });
      // this.selectedFormBase['newEntityEventEmitter'].subscribe(deviceTypeId => {
      //   //this.newChannel(deviceTypeId);
      // });

      this.moduleIsReady = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  //#region IFormBase :: onSubmit(), onCancel(), resetForm(), isValid()

  public onSubmit(): Promise<any> {

    if (this.componentInstance) {
      this.componentInstance.destroy();
      this.componentInstance = null;
    }

    let promise = new Promise((resolve, reject) => {

      let entity = this.selectedFormBase.onSubmit();

      if (entity) {

        entity.Id = this.selectedService.Id;
        entity.ServiceTypeId = this.selectedService.ServiceTypeId;

        entity.Settings = JSON.stringify(entity);

        this.httpClientService.SendToAPI('Service', 'Save', entity).subscribe(result => {
          if (result) {
            this.selectedFormBase.resetForm();

            resolve({
              title: 'Sucesso!',
              type: 'success',
              message: 'Registro salvo com sucesso!',
              closeAfter: 100
            });
          }
        }, error => {
          reject({
            title: 'Erro!',
            type: 'danger',
            message: 'Não foi possivel salvar o registro.',
          });
        });
      }
    });

    return promise;
  }

  public onCancel() {

    if (this.componentInstance) {
      this.componentInstance.destroy();
      this.componentInstance = null;
    }

    if (this.selectedFormBase) {
      let isPristine = this.selectedFormBase.onCancel();

      return isPristine && this.serviceTypeForm.pristine;
    }
    return true;
  }

  public resetForm() {
    this.selectedFormBase.resetForm();
    this.serviceTypeForm.markAsPristine();
  }

  public isValid(): boolean {
    return this.selectedFormBase?.isValid() && !this.serviceTypeForm.invalid;
  }

  //#endregion

  //#region ServiceType :: createForm(), updateForm()

  createForm() {
    this.serviceTypeForm = this.formBuilder.group({ ServiceType: [null, Validators.required] });
  }

  updateForm() {
    this.serviceTypeForm.patchValue({ ServiceType: this.selectedServiceType });
  }

  //#endregion

  //#region Members :: loadDevices(), changeSelectedDevice()

  loadServiceTypes(): Promise<any> {

    let promise = new Promise((resolve, reject) => {
      this.httpClientService.RequestFromAPI('Service', 'ListServiceTypes', '').subscribe(result => {
        this.serviceTypes = result;
        resolve(true);
      }, error => {
        console.error(error);
        reject(false);
      });
    });

    return promise;
  }

  public changeSelectedServiceType(selectedValue) {
    this.selectedServiceType = selectedValue;

    let self = this;
    let isPristine = !this.selectedFormBase || this.selectedFormBase.onCancel();

    if (!isPristine) {
      Swal.fire({
        title: 'Confirma a mudança de dispositivo?',
        text: 'Todas as modificações não salvas serão perdidas!',
        icon: 'warning',
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#00acac',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#ff5b57',
        focusCancel: true
      }).then(function (result: SweetAlertResult) {
        if (result.isConfirmed) {
          self.callNew(selectedValue.Id);
        }
      });
    }
    else {
      this.callNew(selectedValue.Id);
    }
  }

  //#endregion
}
