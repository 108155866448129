import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { DashboardV1Page } from './pages/dashboard/v1/dashboard-v1';

// Custom
import { SystemManagerComponent } from './pages/system/system-manager/system-manager.component';
import { LoginScreenComponent } from './pages/login-screen/login-screen';
import { AuthGuard } from './guards/authGuard';

const routes: Routes = [
  { path: '', redirectTo: 'system-manager', pathMatch: 'full' },
  // Dashboard
  { path: 'dashboard/v1', component: DashboardV1Page, data: { title: 'Dashboard V1' } },
  { path: 'login', component: LoginScreenComponent, data: { title: 'Faça o login para acessar' } },
  { path: 'system-manager', component: SystemManagerComponent, data: { title: 'Gerenciamento de execução' }, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  declarations: []
})


export class AppRoutingModule { }
