<div class="modal-header bg-blue-darker">
    <h4 class="modal-title text-white"><i class="fa fa-cogs"></i> Configurações do sistema</h4>
    <button type="button" class="close" (click)="onCancel()">×</button>
</div>
<div class="modal-body" style="padding-top: 0;">
    <fieldset class="entity-form rounded" style="padding-top:15px">

        <form [formGroup]="settingsForm">
            <ol class="nav nav-pills" ngbNav #pill="ngbNav">
                <li class="nav-item" ngbNavItem>
                    <a href="javascript:;" class="nav-link" ngbNavLink>
                        <div class="nav-title">Geral</div>
                    </a>
                    <ng-template ngbNavContent>

                        <fieldset>
                            <legend>Logs do gerenciador de serviços</legend>

                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label for="ServiceManagerLogLevel">Nível de log</label>
                                    <p-dropdown [options]="logLevels" controlId="ServiceManagerLogLevel" formControlName="ServiceManagerLogLevel" optionLabel="Description" optionValue="Id" [style]="{'width':'100%'}"></p-dropdown>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="ServiceManagerLogSaveEnabled">Salvar logs em arquivo?</label>
                                    <div class="checkbox checkbox-css" style="margin-top:5px;">
                                        <input type="checkbox" [value]="true" id="ServiceManagerLogSaveEnabled" formControlName="ServiceManagerLogSaveEnabled" />
                                        <label for="ServiceManagerLogSaveEnabled">Sim</label>
                                    </div>
                                </div>
                                <div class="form-group col-md-6" [hidden]="!settingsForm.get('ServiceManagerLogSaveEnabled').value">
                                    <label for="ServiceManagerLogSavePath">Diretório para salvamento de logs</label>
                                    <input type="text" pInputText class="form-control" id="ServiceManagerLogSavePath" formControlName="ServiceManagerLogSavePath" />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>Logs do gerenciador de comunicação</legend>

                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label for="CommManagerLogLevel">Nível de log</label>
                                    <p-dropdown [options]="logLevels" controlId="CommManagerLogLevel" formControlName="CommManagerLogLevel" optionLabel="Description" optionValue="Id" [style]="{'width':'100%'}"></p-dropdown>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="CommManagerLogSaveEnabled">Salvar logs em arquivo?</label>
                                    <div class="checkbox checkbox-css" style="margin-top:5px;">
                                        <input type="checkbox" [value]="true" id="CommManagerLogSaveEnabled" formControlName="CommManagerLogSaveEnabled" />
                                        <label for="CommManagerLogSaveEnabled">Sim</label>
                                    </div>
                                </div>
                                <div class="form-group col-md-6" [hidden]="!settingsForm.get('CommManagerLogSaveEnabled').value">
                                    <label for="CommManagerLogSavePath">Diretório para salvamento de logs</label>
                                    <input type="text" pInputText class="form-control" id="CommManagerLogSavePath" formControlName="CommManagerLogSavePath" />
                                </div>
                            </div>
                        </fieldset>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem>
                    <a href="javascript:;" class="nav-link" ngbNavLink>
                        <div class="nav-title">Usuários</div>
                    </a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="systemUsersForm" [hidden]="!selectedUser">
                            <fieldset style="background-color:#e6edf0; margin-bottom:15px; padding:10px">
                                <legend style="background-color:#b8cbd4;">{{selectedUser && selectedUser.Id == guidEmpty ? 'Novo usuário' : 'Editar usuário'}}</legend>

                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-3">
                                        <label for="userGivenName">Nome</label>
                                        <input type="text" pInputText id="userGivenName" formControlName="GivenName" />
                                    </div>
                                    <div class="p-field p-col-3">
                                        <label for="userSurName">Sobrenome</label>
                                        <input type="text" pInputText id="userSurName" formControlName="SurName" />
                                    </div>
                                    <div class="p-field p-col-3">
                                        <label for="userUsername">Usuário</label>
                                        <input type="text" pInputText id="userUsername" formControlName="Username" />
                                    </div>
                                    <div class="p-field p-col-3">
                                        <label for="userPassword">Senha</label>
                                        <div class="p-inputgroup">
                                            <input type="password" pInputText id="userPassword" formControlName="Password" />
                                            <button type="button" pButton pRipple icon="pi pi-pencil" (click)="changeStateUserPasswordEdition();" styleClass="p-button-info" [hidden]="selectedUser && selectedUser.Id == guidEmpty"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-field p-col-3">
                                        <label for="userRoles">Perfil</label>
                                        <div>
                                            <!-- <p-multiSelect [options]="roles" formControlName="Roles" inputId="Roles" defaultLabel="Selecione a permissão" optionLabel="Description" display="chip"  [style]="{'minWidth':'100%'}"></p-multiSelect> -->
                                            <p-dropdown [options]="roles" inputId="userRoles" formControlName="Role" optionLabel="Description" placeholder="Selecione um perfil" [autoDisplayFirst]="false" [style]="{'minWidth':'100%'}"></p-dropdown>
                                        </div>
                                    </div>
                                    <div class="p-field p-col-3">
                                        <label for="userEnabled">Está ativo?</label>
                                        <div class="checkbox checkbox-css">
                                            <input type="checkbox" id="userEnabled" formControlName="Enabled" />
                                            <label for="userEnabled">Sim</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-fluid p-formgrid p-grid" style="width: 100%;">
                                    <div class="p-col-3"></div>
                                    <div class="p-col-3">
                                        <button type="button" (click)="onCancelUserForm()" class="btn btn-warning btn-block">Cancelar</button>
                                    </div>
                                    <div class="p-col-3">
                                        <button type="submit" (click)="onSubmitUserForm()" class="btn btn-primary btn-block" [disabled]="!userFormIsValid()">Confirmar</button>
                                    </div>
                                    <div class="p-col-3"></div>
                                </div>

                            </fieldset>
                        </form>

                        <fieldset>
                            <legend>Usuários cadastrados</legend>

                            <p-toolbar>
                                <ng-template pTemplate="left">
                                    <button pButton pRipple label="Novo usuário" icon="pi pi-plus" class="p-button-success p-mr-2 p-button-xs-with-label" (click)="newUser()"></button>
                                </ng-template>
                            </p-toolbar>

                            <p-table #dt [value]="users" [globalFilterFields]="['Username']" responsiveLayout="scroll" dataKey="Id" currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} registros" [showCurrentPageReport]="false" styleClass="p-datatable-sm" [lazy]="true"
                                (onLazyLoad)="loadUsers($event)" [lazyLoadOnInit]="true" [paginator]="false" [alwaysShowPaginator]="false" [resizableColumns]="true" [tableStyleClass]="'table-wrap'" [rows]="10" [rowHover]="true" [rowsPerPageOptions]="[5,10,20]"
                                [totalRecords]="totalRecords" [loading]="loading" [filterDelay]="0" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Nome</th>
                                        <th>Usuário</th>
                                        <th>Nível de acesso</th>
                                        <th style="width: 2.7rem"></th>
                                        <th style="width: 2.7rem"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-user let-index="rowIndex">
                                    <tr class="list-table-row">
                                        <td>
                                            {{user.GivenName}} {{user.SurName}}
                                        </td>
                                        <td>
                                            {{user.Username}}
                                        </td>
                                        <td>
                                            <div *ngFor="let userRole of user.UserRoles">{{userRole.Role.Description}}</div>
                                        </td>
                                        <td>
                                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-xs" (click)="editUser(user)"></button>
                                        </td>
                                        <td>
                                            <button pButton pRipple icon="pi pi-times" class="p-button-rounded p-button-danger p-button-xs" [disabled]="tokenData && tokenData['unique_name'] == user.Username" (click)="deleteUser(user)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </fieldset>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem>
                    <a href="javascript:;" class="nav-link" ngbNavLink>Wireless</a>
                    <ng-template ngbNavContent>
                        <div formGroupName="Wireless">
                            <fieldset>
                                <legend>Modo de funcionamento</legend>

                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <label for="wirelessOperationMode">Modo de operação</label>
                                        <p-dropdown [options]="wirelessOperationModes" inputId="wirelessOperationMode" formControlName="OperationMode" optionLabel="Description" optionValue="Id" [style]="{'width':'100%'}"></p-dropdown>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <label for="WirelessNetworkName">Nome da rede (SSID)</label>
                                        <div class="p-inputgroup">
                                            <input type="text" pInputText id="WirelessNetworkName" formControlName="NetworkName" placeholder="SmartCom" [hidden]="settingsForm.get('Wireless').get('OperationMode').value == 1" />

                                            <p-dropdown [options]="wifiNetworks" [editable]="true" inputId="WirelessNetworkName" formControlName="NetworkName" optionLabel="Ssid" optionValue="Ssid" [style]="{'width':'13rem'}" [hidden]="settingsForm.get('Wireless').get('OperationMode').value == 0">
                                                <ng-template let-network pTemplate="item">
                                                    <div>
                                                        <img src="assets/img/wifi/signal_{{network.Intensity}}.png" height="20" />
                                                        <span style="margin-left:8px;">{{network.Ssid}}</span>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                            <button type="button" pButton pRipple icon="pi pi-search" (click)="scanWireless();" [loading]="scanningWifi" styleClass="p-button-success" [hidden]="settingsForm.get('Wireless').get('OperationMode').value == 0"></button>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-3">
                                        <label for="WirelessPassword">Senha</label>
                                        <input type="text" pInputText class="form-control" id="WirelessPassword" formControlName="Password" />
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend>Configuração IPv4</legend>

                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <label for="wirelessIpAddressMode">Tipo de configuração</label>
                                        <p-dropdown [options]="ipAddressModes" inputId="wirelessIpAddressMode" formControlName="IpAddressMode" optionLabel="Description" optionValue="Id" [style]="{'width':'100%'}"></p-dropdown>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <label>Endereço de IP</label>
                                        <ngx-ip [disabledBlocks]="[0,0,0,0]" [theme]="'ngx-ip-theme-material'" [mode]="'ipv4'" formControlName="IpAddress" [required]="settingsForm.get('Wireless').get('IpAddressMode').value == 1" [disabled]="settingsForm.get('Wireless').get('IpAddressMode').value == 0"></ngx-ip>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>Máscara de sub-rede</label>
                                        <ngx-ip [disabledBlocks]="[0,0,0,0]" [theme]="'ngx-ip-theme-material'" [mode]="'ipv4'" formControlName="SubnetMask" [required]="settingsForm.get('Wireless').get('IpAddressMode').value == 1" [disabled]="settingsForm.get('Wireless').get('IpAddressMode').value == 0"></ngx-ip>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>Gateway padrão</label>
                                        <ngx-ip [disabledBlocks]="[0,0,0,0]" [theme]="'ngx-ip-theme-material'" [mode]="'ipv4'" formControlName="DefaultGateway" [disabled]="settingsForm.get('Wireless').get('IpAddressMode').value == 0"></ngx-ip>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>Servidor DNS</label>
                                        <ngx-ip [disabledBlocks]="[0,0,0,0]" [theme]="'ngx-ip-theme-material'" [mode]="'ipv4'" formControlName="PrimaryDns" [disabled]="settingsForm.get('Wireless').get('IpAddressMode').value == 0"></ngx-ip>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem>
                    <a href="javascript:;" class="nav-link" ngbNavLink>Ethernet</a>
                    <ng-template ngbNavContent>
                        <fieldset formGroupName="Ethernet">
                            <legend>Configuração IPv4</legend>

                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label for="ethernetIpAddressMode">Tipo de configuração</label>
                                    <p-dropdown [options]="ipAddressModes" inputId="ethernetIpAddressMode" formControlName="IpAddressMode" optionLabel="Description" optionValue="Id" [style]="{'width':'100%'}"></p-dropdown>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-3">
                                    <label>Endereço de IP</label>
                                    <ngx-ip [disabledBlocks]="[0,0,0,0]" [theme]="'ngx-ip-theme-material'" [mode]="'ipv4'" formControlName="IpAddress" [required]="settingsForm.get('Ethernet').get('IpAddressMode').value == 1" [disabled]="settingsForm.get('Ethernet').get('IpAddressMode').value == 0"></ngx-ip>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Máscara de sub-rede</label>
                                    <ngx-ip [disabledBlocks]="[0,0,0,0]" [theme]="'ngx-ip-theme-material'" [mode]="'ipv4'" formControlName="SubnetMask" [required]="settingsForm.get('Ethernet').get('IpAddressMode').value == 1" [disabled]="settingsForm.get('Ethernet').get('IpAddressMode').value == 0"></ngx-ip>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Gateway padrão</label>
                                    <ngx-ip [disabledBlocks]="[0,0,0,0]" [theme]="'ngx-ip-theme-material'" [mode]="'ipv4'" formControlName="DefaultGateway" [disabled]="settingsForm.get('Ethernet').get('IpAddressMode').value == 0"></ngx-ip>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Servidor DNS</label>
                                    <ngx-ip [disabledBlocks]="[0,0,0,0]" [theme]="'ngx-ip-theme-material'" [mode]="'ipv4'" formControlName="PrimaryDns" [disabled]="settingsForm.get('Ethernet').get('IpAddressMode').value == 0"></ngx-ip>
                                </div>
                            </div>
                        </fieldset>
                    </ng-template>
                </li>

                <li class="nav-item" ngbNavItem>
                    <a href="javascript:;" class="nav-link" ngbNavLink>API Externa</a>
                    <ng-template ngbNavContent>
                        <fieldset>
                            <legend>Endereço para registro de informações em API Externa</legend>

                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="ExternalApiAddress">Endereço</label>
                                    <input type="text" pInputText class="form-control" id="ExternalApiAddress" formControlName="ExternalApiAddress" />
                                </div>
                            </div>
                        </fieldset>
                    </ng-template>
                </li>

                <li class="nav-item" ngbNavItem>
                    <a href="javascript:;" class="nav-link" ngbNavLink>Anotações</a>
                    <ng-template ngbNavContent>
                        <fieldset>
                            <legend>Anotações</legend>

                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="Note">Observações</label>
                                    <textarea class="form-control" id="Note" formControlName="Note" rows="10"></textarea>
                                </div>
                            </div>
                        </fieldset>
                    </ng-template>
                </li>
            </ol>

            <div class="tab-content p-3" [ngbNavOutlet]="pill">
            </div>
        </form>
    </fieldset>
</div>
<div class="modal-footer">
    <div class="row" style="width: 100%;">
        <div class="col-lg-2">
            <a href="#" (click)="onCancel()" class="footer-cancel-link modal-footer-cancel-link">
                <i class="fas fa-redo"></i>&nbsp; Cancelar e fechar
            </a>
        </div>
        <div class="col-lg-5"></div>
        <div class="col-lg-5">
            <button type="submit" (click)="onSubmit()" class="btn btn-success btn-block" [disabled]="!isValid()">Salvar</button>
        </div>
    </div>
</div>